import _get from 'lodash/get'
import { debug } from 'Shared/utils/log'
import store from 'App/store'

import stateToStore from './state-to-store'

let localStorageLoaded = false
let localStorageSubscriber = null

const init = async () => {
  if (!localStorageLoaded) {
    // merge values from localStorage into store
    await store.commit('loadStoreFromLocalStorage')
    localStorageLoaded = true
  }

  if (!localStorageSubscriber) {
    // watch store for changes and write them to localStorage
    debug('store.subscribe for localStorage')
    localStorageSubscriber = store.subscribe((mutation, state) => {
      const localStore = {
        version: state.version,
      }
      stateToStore.forEach((path) => {
        const value = _get(state, path)
        if (value) {
          localStore[path] = value
        }
      })

      localStorage.setItem('store', JSON.stringify(localStore))
    })
  }
}

const stopSubscriber = () => {
  if (localStorageSubscriber !== null) {
    localStorageSubscriber()
    localStorageSubscriber = null
    return true
  }
  return false
}

export default init
export { init, stopSubscriber }
