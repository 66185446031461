import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'

import ArtworkRoutes from 'Modules/artwork/artwork-routes'
import AuthenticationRoutes from 'Modules/authentication/authentication-routes'
import CollectionsRoutes from 'Modules/collections/collections-routes'
import ContentsRoutes from 'Modules/contents/contents-routes'
import ContentTypesRoutes from 'Modules/content-types/content-types-routes'
import DeploymentsRoutes from 'Modules/deployments/deployments-routes'
import NotificationsRoutes from 'Modules/notifications/notifications-routes'
import OrdersRoutes from 'Modules/orders/orders-routes'
import OrganizationsRoutes from 'Modules/organizations/organizations-routes'
import PicturesRoutes from 'Modules/pictures/pictures-routes'
import PropertiesRoutes from 'Modules/properties/properties-routes'
import PublicationsRoutes from 'Modules/publications/publications-routes'
import SearchRoutes from 'Modules/search/search-routes'
import UsersRoutes from 'Modules/users/users-routes'
import { getAuth } from 'firebase/auth'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: 'MediaFlex',
})

const isUserLoggedIn = () => {
  const auth = getAuth()
  return !!auth.currentUser
}

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */
export const constantRoutes = [
  {
    path: '/404',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout2" */ '@/app/layout/horizontal.vue'
      ),
    children: [
      {
        path: '/404',
        name: '404',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-errors" */ '@/app/shared/views/error-page/404.vue'
          ),
        hidden: true,
      },
    ],
  },
  ...AuthenticationRoutes,
  {
    path: '/',
    name: 'home',
    redirect: () => {
      if (isUserLoggedIn()) {
        return { name: 'dashboard' }
      }
      return { name: 'login' }
    },
  },
  {
    path: '*',
    redirect: (to) => {
      if (isUserLoggedIn()) {
        return { name: '404' }
      }
      return { name: 'login', query: { redirect: to.path } }
    },
    hidden: true,
  },
  ...DeploymentsRoutes.constant,
]

export const asyncRoutes = [
  ...CollectionsRoutes,
  ...PublicationsRoutes,
  ...ContentsRoutes,
  ...PicturesRoutes,
  ...OrdersRoutes,
  ...DeploymentsRoutes.dynamic,
  ...ArtworkRoutes,
  {
    path: '/settings',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    meta: {
      title: 'title.config',
      icon: 'las la-cog',
      roles: ['supplier', 'owner', 'manager'],
    },
    children: [
      ...OrganizationsRoutes,
      ...PropertiesRoutes,
      ...ContentTypesRoutes,
    ],
  },
  ...UsersRoutes,
  ...NotificationsRoutes,
  ...SearchRoutes,
]

const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) =>
      savedPosition || { x: 0, y: 0 },
    routes: constantRoutes,
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
