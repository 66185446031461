import { collectionName } from '../../organizations-storage'

export default {
  get: (state) => (id) => {
    return (id && state.all[id]) || null
  },
  getName: (state, getters) => (id) => {
    const org = getters.get(id)
    return (org && org.name) || id
  },
  list: (state, getters) => state.list.map(getters.get),

  filterSuppliers: (state, getters) => {
    return getters.list.filter((org) => org.type === 'supplier')
  },

  memberCount: (state, getters) => (id) => {
    const org = getters.get(id)
    return org && org.members ? Object.keys(org.members).length : 0
  },
  canDelete: (state, getters, rootState, rootGetters) => (id) => {
    // is admin?
    if (rootGetters['authentication/isAdmin']) return true
    // is supplier?
    if (!rootGetters['authentication/isSupplier']) return false
    // is supplier of this organization?
    const organization = getters.get(id)
    const { user } = rootState.authentication
    return organization && user && organization.supplier === user.organization
  },
  canEdit: (state, getters, rootState) => (id) => {
    if (!id) return false
    if (getters.canDelete(id)) return true
    // additionally, edit is allowed for organization owners
    const { user } = rootState.authentication
    return user && user.role === 'owner' && user.organization === id
  },
  address: () => (adr) => {
    if (!(adr instanceof Object)) return ''
    const cz = [adr.country || '', adr.zip || ''].filter(Boolean).join('-')
    const city = [cz || '', adr.city || ''].filter(Boolean).join(' ')
    return [adr.street || '', city || ''].filter(Boolean).join(', ')
  },
  billingAddress: (state, getters) => (id) => {
    const org = getters.get(id)
    if (!org || !org.billing || !org.billing.address) return null
    return getters.address(org.billing.address)
  },
  license: (state, getters) => (id) => {
    const org = getters.get(id)
    return org && org.license ? org.license : 0
  },
  logoPath: () => (id) => `${id}/logo`,
  logoFullPath: (state, getters) => (id) =>
    `${collectionName}/${getters.logoPath(id)}`,
}
