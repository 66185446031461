export default {
  user: undefined,
  claims: {},
  listenerSingle: null,
  loginPending: false,
  loginError: null,
  loginRedirect: null,
  resetPending: false,
  resetError: null,
  resetDone: false,
}
