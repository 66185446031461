export default {
  all: {
    owner: {},
    organization: {},
    user: {},
  },
  loading: {
    // collections
    owner: null,
    organization: null,
    user: null,
    // active collection references
    contents: null,
    releases: null,
  },
  deletionPending: {},

  active: null,
  editItem: null,
  editIsNew: true,
  editShow: false,

  listenerAll: {
    owner: null,
    organization: null,
    user: null,
  },

  showIds: false,
  showType: 'releases', // releases, contents

  publicationsFromContents: null,

  lastUsed: [],
}
