import Vue from 'vue'

import MfTable from 'Shared/components/MfTable.vue'
import MfColumn from 'Shared/components/MfColumn.vue'
import '@/assets/css/mf-table.scss'

import MfButton from 'Shared/components/MfButton.vue'
import MfButtonDanger from 'Shared/components/MfButtonDanger.vue'
import MfButtonSecondary from 'Shared/components/MfButtonSecondary.vue'
import MfButtonText from 'Shared/components/MfButtonText.vue'
import MfImage from 'Shared/components/MfImage.vue'
import MfProgress from 'Shared/components/MfProgress.vue'

Vue.component('MfTable', MfTable)
Vue.component('MfColumn', MfColumn)

Vue.component('MfButtonDanger', MfButtonDanger)
Vue.component('MfButtonPrimary', MfButton)
Vue.component('MfButtonSecondary', MfButtonSecondary)
Vue.component('MfButtonText', MfButtonText)
Vue.component('MfImage', MfImage)
Vue.component('MfProgress', MfProgress)
