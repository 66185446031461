import { cloneDeep, defaults, forIn, get, groupBy, map } from 'lodash'
import truncate from 'Shared/filters/truncate'
import i18n from '@/plugins/i18n'
import ratioFromFormat from 'Modules/templates/shared/utils/ratioFromFormat'

const sortLocaleCompare = (a, b) => {
  const { locale } = i18n
  const localeCompareOptions = {
    numeric: true,
    sensitivity: 'base',
  }
  return String(a.name || '').localeCompare(
    String(b.name || ''),
    locale,
    localeCompareOptions
  )
}

const collectionsTree = (list) => {
  const groups = groupBy(list, (el) => el.parentId || 'ROOT')
  const tree = cloneDeep(groups.ROOT)
  delete groups.ROOT
  forIn(groups, (children, parentId) => {
    const parentIndex = tree.findIndex((el) => el.id === parentId)
    if (parentIndex !== -1) {
      tree[parentIndex].collections = children
    } else {
      tree.push(...children)
    }
  })
  return tree
}

export default {
  loading: (state) =>
    state.loading.owner || state.loading.organization || state.loading.user,

  isListeningToAll: (state, getters, rootState, rootGetters) => {
    const { organization, owner, user } = state.listenerAll
    if (rootGetters['authentication/organization'] === 'mcs') {
      return !!organization
    }
    return !!(organization && owner && user)
  },

  all: (state) => {
    const all = {}
    defaults(all, state.all.owner, state.all.organization, state.all.user)
    return Object.values(all)
  },

  allNameAsc: (state, getters) => getters.all.sort(sortLocaleCompare),

  allTree: (state, getters) => collectionsTree(getters.allNameAsc),

  allByOrganization: (state, getters, rootState, rootGetters) => {
    const byOrg = groupBy(getters.allNameAsc, (col) => col.organization ?? '')
    return map(byOrg, (collections, orgId) => {
      const getOrg =
        orgId !== ''
          ? rootGetters['organizations/get'](orgId)
          : {
              id: null,
              name: i18n.t('collections.field.organizationUnassigned'),
            }
      const org = getOrg ? { ...getOrg } : { id: orgId }
      if (!org.name) org.name = orgId
      org.collections = collectionsTree(collections)
      return org
    }).sort(sortLocaleCompare)
  },

  /* get single item */
  get: (state) => (id) => {
    if (state.all.organization[id]) return state.all.organization[id]
    if (state.all.owner[id]) return state.all.owner[id]
    if (state.all.user[id]) return state.all.user[id]
    return null
  },
  getOrDirect: (state, getters) => (colOrId) =>
    colOrId instanceof Object ? colOrId : getters.get(colOrId),
  getFirstRelease: (state, getters) => (id) => {
    const item = getters.get(id)
    if (
      !item ||
      !Object.prototype.hasOwnProperty.call(item, 'releases') ||
      !item.releases
    )
      return null
    return Object.keys(item.releases)[0]
  },
  numContents: (state, getters) => (id) => {
    const collection = id ? getters.get(id) : state.editItem
    if (!collection) return false
    if (!collection.contents) return 0
    return Object.keys(collection.contents).length
  },
  releaseIds: (state, getters) => (id) => {
    const collection = id ? getters.get(id) : state.editItem
    if (!collection || !collection.releases) return []
    return Object.keys(collection.releases)
  },
  numReleases: (state, getters) => (id) => {
    return getters.releaseIds(id).length
  },
  releases: (state, getters, rootState, rootGetters) => (id) => {
    return getters.releaseIds(id).map(rootGetters['releases/get'])
  },
  isDefaultVisible: (state, getters, rootState) => (colOrId) => {
    const col = getters.getOrDirect(colOrId)
    const { user } = rootState.authentication
    if (!user) return false
    if (col.owner === user.id) return true
    if (get(col, `organizations.${user.organization}`) === true) return true
    return get(col, `users.${user.id}`) === true
  },
  numSharesOrg: (state, getters) => (colOrId) => {
    const col = getters.getOrDirect(colOrId)
    return Object.keys((col && col.organizations) || {}).length
  },
  numSharesUser: (state, getters) => (colOrId) => {
    const col = getters.getOrDirect(colOrId)
    return Object.keys((col && col.users) || {}).length
  },
  isPrivate: (state, getters) => (colOrId) =>
    getters.isDefaultVisible(colOrId) &&
    getters.numSharesOrg(colOrId) + getters.numSharesUser(colOrId) === 0,
  isOwner: (state, getters, rootState) => (id) => {
    const collection = getters.get(id)
    const { user } = rootState.authentication
    if (!collection || !collection.owner || !user) return null
    return user.id === collection.owner
  },
  canEdit: (state, getters, rootState, rootGetters) => (id) => {
    const col = getters.get(id)
    if (!col) return false
    if (rootGetters['authentication/isAdmin']) return true
    if (rootGetters['authentication/organizationId'] === 'mcs') return true
    const user = rootGetters['authentication/currentUser']
    return (user && col.owner === user.uid) || false
  },
  isShowType: (state) => (type) => state.showType === type,
  titleRaw: (state, getters) => (id) => {
    const item = id ? getters.get(id) : state.editItem
    const name = (item && item.name) || ''
    const typeId = (item && item.type) || 'generic'
    const type = i18n.tc(`collections.type.${typeId}`, 1)
    if (id) {
      return name
        ? ['collections.title.show', { type, name }]
        : ['collections.title.showGeneral', { type }]
    }
    return state.editIsNew
      ? ['collections.title.create', { type }]
      : ['collections.title.edit', { type, name }]
  },
  title: (state, getters) => (id) => {
    const [name, params] = getters.titleRaw(id)
    return i18n.t(name, params)
  },
  label:
    (state, getters, rootState, rootGetters) =>
    (id, orgLabel = true) => {
      const col = getters.get(id)
      if (!col || !col.name) return id
      if (!orgLabel || !rootGetters['authentication/isSupplier'])
        return col.name
      const org = rootGetters['organizations/get'](col.organization)
      if (!org || !org.name) return col.name
      return `${col.name} (${truncate(org.name)})`
    },
  sharedBy: (state, getters, rootState, rootGetters) => (id) => {
    const item = getters.get(id)
    if (!item || !item.owner) return ''
    const user = rootGetters['users/get'](item.owner)
    const name = (user && (user.name || user.email)) || id
    return i18n.t('collections.field.ownerSharedBy', {
      user: name,
    })
  },
  referenceOwner: (s, g, rs, rootGetters) => {
    if (rootGetters['authentication/isSupplier']) return null
    if (rootGetters['authentication/isAdmin']) return null
    return 'organization'
  },
  hasListenerContents: (state, getters, rootState, rootGetters) => (id) => {
    const { filterCollection, filterOwner, filterType } = rootState.contents
    // check filter type
    if (filterType !== null) return false
    // check filter collection
    if (filterCollection !== id) return false
    // check filter owner
    if (filterOwner !== getters.referenceOwner) return false
    // check has listener for this filters
    return rootGetters['contents/hasListenerListConstraints']
  },
  hasListenerReleases: (state, getters, rootState, rootGetters) => (id) => {
    const { filterCollection, filterOwner, filterType } = rootState.releases
    // check filter type
    if (filterType !== null) return false
    // check filter collection
    if (filterCollection !== id) return false
    // check filter owner
    if (filterOwner !== getters.referenceOwner) return false
    // check has listener for this filters
    return rootGetters['releases/hasListenerListConstraints']
  },
  lastUsed:
    (state, getters) =>
    (max = 6) =>
      state.lastUsed.map(getters.get).filter(Boolean).slice(0, max),
  lastChanged:
    (state, getters) =>
    (max = 12, excludeLastUsed = false, excludeMemo = false) =>
      getters.all
        .filter(
          (c) =>
            (!excludeMemo || c.type !== 'memo') &&
            (!excludeLastUsed || !state.lastUsed.includes(c.id))
        )
        .sort((a, b) => {
          if (a.updateTimestamp > b.updateTimestamp) return -1
          if (a.updateTimestamp < b.updateTimestamp) return 1
          return 0
        })
        .slice(0, max)
        .sort(sortLocaleCompare),
  dashboardMemoLists: (state, getters) => (max) => {
    const list = getters.all
      .filter((c) => c.type === 'memo')
      .filter(getters.isDefaultVisible)
      .sort(sortLocaleCompare)
    if (!max) return list
    return list.slice(0, max + 1)
  },

  showType: (state, getters, rootState, rootGetters) => {
    if (rootGetters['authentication/simpleView']) return 'releases'
    return state.showType
  },

  workflowAvailable: (state, getters, rootState, rootGetters) => (id) => {
    const col = getters.get(id)
    if (!col) return false
    const tplId = col.releaseDefaultTemplate
    const tpl = rootGetters['templates/get'](tplId)
    if (!tpl || tpl.type !== 'digital') return false
    return !!col.webhook && col.workflowEnabled
  },
  workflowTitle: (state, getters) => (id) => {
    const col = getters.get(id)
    return col && col.workflowTitle
  },
  releaseDisplayRatio: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return undefined
    const c = getters.get(id)
    if (!c) return undefined
    if (c.releaseDefaultTemplate) {
      return rootGetters['templates/getRatio'](c.releaseDefaultTemplate)
    }
    if (c.releaseDefaultFormat) {
      return ratioFromFormat(c.releaseDefaultFormat)
    }
    return undefined
  },
}
