import vueSetPath from 'Shared/store/_partials/vueSetPath'

export default {
  id: (state, value) => (state.id = value),
  report: (state, value) => (state.report = value),
  sheet: (state, value) => (state.sheet = value),
  owner: (state, value) => (state.owner = value),
  type: (state, value) => (state.type = value),
  mapping: (state, value) => (state.mapping = value),
  updateMapping: (state, { path, value }) =>
    vueSetPath(state.mapping, path, value),
  importedRows: (state, value) => (state.importedRows = value),
  uploadLoading: (state, val) => (state.uploadLoading = !!val),
  analysisLoading: (state, val) => (state.analysisLoading = !!val),
  importLoading: (state, val) => (state.importLoading = !!val),
  dragActive: (state, val) => (state.dragActive = !!val),
}
