import { orderBy } from 'lodash'
import ratioFromFormat from '../utils/ratioFromFormat'

export default {
  allDigital: (state) => {
    if (!state.all) return []
    return Object.values(state.all).filter((item) => item.type === 'digital')
  },

  allPrint: (state) => {
    if (!state.all) return []
    return Object.values(state.all).filter((item) => item.type !== 'digital')
  },

  /* get item from state if exists */
  get: (state) => (id) => {
    return (id && state.all[id]) || null
  },

  label: (state, getters) => (id) => {
    const template = getters.get(id)
    if (!template || !template.name) return id
    if (!template.format) return template.name
    return `${template.name} (${template.format})`
  },

  placeholders: (state, getters) => (id) => {
    const tpl = getters.get(id)
    if (!tpl || !tpl.placeholders) return []
    return orderBy(tpl.placeholders, 'order')
  },

  /* get a placeholder of item from store */
  getPlaceholder: (state, getters) => (tplId, plhId) => {
    const plh = getters.placeholders(tplId)
    if (!plh) return null
    const idx = plh.findIndex((el) => el.id === plhId)
    return idx > -1 ? plh[idx] : null
  },

  getPlaceholderProperty:
    (state, getters, rootState, rootGetters) => (tplId, plhId) => {
      const plh = getters.getPlaceholder(tplId, plhId)
      return rootGetters['properties/propLangIdxFromString'](
        plh && plh.property
      )
    },

  getRatio: (state, getters) => (tplId) => {
    const t = getters.get(tplId)
    if (!t) return undefined
    if (t.width && t.height) return t.width / t.height
    return ratioFromFormat(t.format)
  },

  list: (state) => {
    if (!state.all) return []
    return Object.values(state.all).sort((a, b) => {
      if (a.name === b.name) return 0
      return a.name < b.name ? -1 : 1
    })
  },
  listByOwner: (state) => (owner) => {
    if (!state.all) return []
    const list = Object.values(state.all)
    if (!owner) return list
    return list
      .filter((tpl) => tpl.owner === owner)
      .sort((a, b) => {
        if (a.name === b.name) return 0
        return a.name < b.name ? -1 : 1
      })
  },
}
