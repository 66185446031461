export default {
  filtered: (state) => {
    if (!state.filter) return state.all
    const filter = state.filter.toLowerCase()
    return state.all.filter(
      (hyphen) =>
        hyphen.name.toLowerCase().includes(filter) ||
        ((hyphen.yaml.language && hyphen.yaml.language.name) || '')
          .toLowerCase()
          .includes(filter)
    )
  },
  isLoading: (state) => !!state.loading,
}
