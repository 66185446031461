export default {
  user: (state, value) => (state.user = value),
  claims: (state, value) => (state.claims = value),
  addListenerSingle: (state, { unsubscribe }) =>
    (state.listenerSingle = unsubscribe),
  removeListenerSingle: (state) => (state.listenerSingle = null),
  loginPending: (state, val) => (state.loginPending = !!val),
  loginError: (state, err) => (state.loginError = err),
  loginRedirect: (state, route) => (state.loginRedirect = route),
  resetPending: (state, val) => (state.resetPending = !!val),
  resetError: (state, err) => (state.resetError = err),
  resetDone: (state, val) => (state.resetDone = !!val),
}
