import { cloneDeep, keys, set, defaultsDeep } from 'lodash'
import { getAuth } from 'firebase/auth'
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  documentId,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore'
import { debug, error as logError } from 'Shared/utils/log'

const db = getFirestore()

/**
 * Generic Firestore Database
 */
export default class GenericDB {
  /**
   * Constructor function
   * @param collectionName
   * @param commit
   * @param addUserDetails
   */
  constructor(collectionName, commit = null, addUserDetails = false) {
    this.collectionName = collectionName
    this.commit = commit
    this.addUserDetails = !!addUserDetails
  }

  /**
   * Create or overwrite a document in the collection
   * @param data
   * @param id
   * @returns {Promise<*>}
   */
  async create(data, id = null) {
    debug(`create ${this.collectionName}/${id || '*'}`)

    const { currentUser } = getAuth()
    const updateUser = this.addUserDetails
      ? {
          id: currentUser.uid,
          displayName:
            currentUser.displayName || currentUser.email || currentUser.uid,
          email: currentUser.email || null,
        }
      : currentUser.uid

    const dataToCreate = {
      ...data,
      updateTimestamp: serverTimestamp(),
      updateUser,
    }

    defaultsDeep(dataToCreate, {
      createTimestamp: serverTimestamp(),
      createUser: updateUser,
    })

    const createPromise =
      typeof id !== 'string' || !id.length
        ? // Create doc with generated id
          addDoc(collection(db, this.collectionName), dataToCreate).then(
            (docRef) => {
              debug(`created ${this.collectionName}/${docRef.id}`)
              return docRef.id
            }
          )
        : // Create doc with custom id
          setDoc(doc(db, this.collectionName, id), dataToCreate).then(() => id)

    return createPromise.then((docId) => {
      const returnData = {
        id: docId,
        ...data,
        updateTimestamp: new Date(),
        updateUser,
      }

      defaultsDeep(returnData, {
        createTimestamp: new Date(),
        createUser: updateUser,
      })

      return returnData
    })
  }

  /**
   * Read a document from the collection
   * @param id
   */
  async read(id) {
    if (typeof id !== 'string' || !id.length)
      throw Error('Parameter id must be of type non-empty string')

    debug(`read ${this.collectionName}/${id}`)

    return getDoc(doc(db, this.collectionName, id)).then((docSnap) => {
      if (!docSnap.exists) return null
      const data = docSnap.data()
      GenericDB.convertObjectTimestampPropertiesToDate(data)
      return Object.freeze({ id, ...data })
    })
  }

  /**
   * Query documents from the collection, optionally following constraints
   * @param constraints
   * @param limitDocs
   * @param startAfterDoc
   * @param order
   */
  async query(
    constraints = [],
    limitDocs = 100,
    startAfterDoc = null,
    order = []
  ) {
    debug(
      `query ${this.collectionName}${constraints ? ' constraints:yes' : ''}${
        orderBy ? ' orderBy:yes' : ''
      } startAfter:${startAfter} limit:${limit}`
    )

    const params = []

    if (constraints) {
      constraints.forEach((constraint) => {
        if (constraint[0] === 'id') {
          params.push(where(documentId(), constraint[1], constraint[2]))
        } else {
          params.push(where(...constraint))
        }
      })
    }

    if (order) {
      order.forEach((o) => params.push(orderBy(...o)))
    }

    if (startAfterDoc) {
      params.push(startAfter(startAfterDoc))
    }

    if (limitDocs) {
      params.push(limit(limitDocs))
    }

    const q = query(collection(db, this.collectionName), ...params)

    const formatResult = (querySnapshot) =>
      querySnapshot.docs.map((docSnap) =>
        GenericDB.convertObjectTimestampPropertiesToDate({
          id: docSnap.id,
          ...docSnap.data(),
        })
      )

    return getDocs(q).then(formatResult)
  }

  /**
   * Update a document in the collection
   * @param data
   */
  async update(data) {
    if (typeof data !== 'object')
      throw Error('Parameter data must be of type object')
    const { id } = data
    if (typeof id !== 'string' || !id.length)
      throw Error('Parameter data.id must be of type non-empty string')
    const clonedData = cloneDeep(data)
    delete clonedData.id

    const updatedData = { id }
    Object.entries(clonedData).forEach(([path, value]) => {
      set(updatedData, path, value)
    })

    debug(`update ${this.collectionName}/${id}`)

    const { currentUser } = getAuth()
    const updateUser = this.addUserDetails
      ? {
          id: currentUser.uid,
          displayName:
            currentUser.displayName || currentUser.email || currentUser.uid,
          email: currentUser.email || null,
        }
      : currentUser.uid

    return updateDoc(doc(db, this.collectionName, id), {
      ...clonedData,
      updateTimestamp: serverTimestamp(),
      updateUser,
    }).then(() => {
      updatedData.updateTimestamp = new Date()
      updatedData.updateUser = updateUser
      return updatedData
    })
  }

  /**
   * Delete a document from the collection
   * @param id
   */
  async delete(id) {
    if (typeof id !== 'string' || !id.length)
      throw Error('Parameter id must be of type non-empty string')

    return deleteDoc(doc(db, this.collectionName, id)).then(() => {
      debug(`delete ${this.collectionName}/${id} done`)
      return id
    })
  }

  /**
   * Convert all object Timestamp properties to date
   * @param obj
   */
  static convertObjectTimestampPropertiesToDate(obj) {
    keys(obj)
      .filter((prop) => obj[prop] instanceof Object)
      .forEach((prop) =>
        obj[prop] instanceof Timestamp
          ? (obj[prop] = obj[prop].toDate())
          : GenericDB.convertObjectTimestampPropertiesToDate(obj[prop])
      )
    return Object.freeze(obj)
  }

  /**
   * Read a document from the collection and listen for changes
   * @param id
   * @param commitTo
   */
  async readAndListen(id, commitTo = null) {
    if (typeof id !== 'string' || !id.length)
      throw Error('Parameter id must be of type non-empty string')
    if (commitTo === null) {
      commitTo = 'update'
    }
    const commitListener =
      commitTo.indexOf('/') > -1
        ? `${commitTo.split('/')[0]}/addListenerSingle`
        : 'addListenerSingle'

    let firstRead = true

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(
        doc(db, this.collectionName, id),
        (snapshot) => {
          debug(
            `readAndListen ${this.collectionName}/${snapshot.id} -> ${commitTo} `
          )

          const data = GenericDB.convertObjectTimestampPropertiesToDate({
            id: snapshot.id,
            ...snapshot.data(),
          })
          if (snapshot.exists && this.commit) {
            this.commit(commitTo, data, { root: commitTo !== 'update' })
          }
          if (firstRead) {
            firstRead = false
            if (snapshot.exists) {
              resolve(data)
            } else {
              reject(
                new Error(
                  `Snapshot ${this.collectionName}/${id} does not exist`
                )
              )
            }
          }
        },
        (error) => {
          debug(`readAndListen ${commitTo} ${id} ERROR`, error)
          if (firstRead) {
            firstRead = false
            reject(error.message)
          }
        }
      )
      if (unsubscribe && this.commit) {
        this.commit(
          commitListener,
          { id, unsubscribe },
          { root: commitListener !== 'addListenerSingle' }
        )
      }
    })
  }

  /**
   * Read all documents from the collection, optionally following constraints and listen for changes
   * @param constraints
   * @param limitDocs
   * @param offsetDocs
   * @param order
   * @param mutationSuffix
   * @param listMutation
   */
  async queryAndListen(
    constraints = [],
    limitDocs = 100,
    offsetDocs = 0,
    order = [],
    mutationSuffix = '',
    listMutation = ''
  ) {
    const params = []

    if (constraints) {
      constraints.forEach((constraint) => params.push(where(...constraint)))
    }

    if (limitDocs) {
      params.push(limit(limitDocs))
    }

    if (offsetDocs) {
      params.push(startAfter(offsetDocs))
    }

    if (order) {
      order.forEach((o) => params.push(orderBy(...o)))
    }

    let firstRead = true

    if (this.commit) {
      this.commit(`loading${mutationSuffix}`, true)
    }

    const q = query(collection(db, this.collectionName), ...params)

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          if (this.commit && listMutation) {
            const listIds = []
            querySnapshot.forEach((snapDoc) => listIds.push(snapDoc.id))
            this.commit(listMutation, listIds)
          }
          querySnapshot.docChanges().forEach((change) => {
            const mutation =
              (change.type === 'removed' ? 'remove' : 'update') + mutationSuffix

            let debugMsg = `queryAndListen ${this.collectionName}/${mutation} (${change.type})`
            if (change.type !== 'added') debugMsg += ` ${change.doc.id}`
            debug(debugMsg)

            const docObj = { id: change.doc.id, ...change.doc.data() }
            if (this.commit) {
              if (change.type === 'removed') {
                this.commit(mutation, docObj)
              } else {
                const data =
                  GenericDB.convertObjectTimestampPropertiesToDate(docObj)
                this.commit(mutation, data)
              }
            }
          })
          if (firstRead) {
            firstRead = false
            if (this.commit) {
              this.commit(`loading${mutationSuffix}`, false)
            }
            resolve(unsubscribe)
          }
        },
        (error) => {
          logError(`queryAndListen ${this.collectionName} ERROR`, error)

          if (firstRead) {
            firstRead = false
            reject(error)
          }
        }
      )
    })
  }
}
