import { isEqual } from 'lodash'

export default {
  editInlineActive: (state) => !!state.id,
  editInlineMulti: (state) => {
    const { id, ids } = state
    return id && ids && ids.length > 1 && ids.includes(id)
  },
  isEditInlineMaster: (state) => (id, path) => {
    if (!state.id || id !== state.id) return false
    return state.path === path
  },
  isEditInlineAffected: (state, getters) => (id, path) => {
    // inline edit active?
    if (!state.id) return false
    // same path?
    if (state.path !== path) return false
    // same id in single mode?
    if (state.id === id) return true
    // id affected in multi mode?
    return getters.editInlineMulti && state.ids.includes(id)
  },
  hasEditInlineChange: (state, getters) => (id, path) => {
    if (!getters.isEditInlineAffected(id, path)) return false
    return !isEqual(state.value, getters.getValue(id, path))
  },
  selection: (state, getters, rootState) => {
    const { list } = rootState.contents
    if (!list.length || !state.ids.length) return []
    return list.filter((id) => state.ids.includes(id))
  },
  isSelected: (state, getters) => (id) => {
    return id && getters.selection.length && getters.selection.includes(id)
  },
}
