export default [
  {
    path: '/notifications',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    children: [
      {
        path: '/notifications',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-notifications" */ './notifications.vue'
          ),
        name: 'notifications-list',
        hidden: true,
        meta: {
          title: 'notifications.title.general',
          icon: 'las la-bell',
          noCache: true,
        },
      },
    ],
  },
]
