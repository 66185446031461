export default Object.freeze({
  general: [
    'billing.address.company',
    'billing.address.street',
    'billing.address.zip',
    'billing.address.city',
    'billing.address.country',
    'billing.paymentMethod',
    'logistics.shippingMethod',
    'items',
    'termsAccepted',
  ],
  logisticsAddress: [
    'logistics.address.company',
    'logistics.address.street',
    'logistics.address.zip',
    'logistics.address.city',
    'logistics.address.country',
  ],
})
