import Vue from 'vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import router from 'App/routes'
import store from 'App/store'
import filters from 'Shared/filters'
import initLocalStorage from 'Shared/store/_localStorage'
import App from '@/App.vue'
import i18n from '@/plugins/i18n'
import nprogress from '@/plugins/nprogress'
import '@/assets/css/tailwind.css'
import '@/plugins/element-ui'
import '@/plugins/vue-clipboard2'
import '@/plugins/mf-table'
import '@/plugins/virtual-scroller'
import '@/plugins/vue-zoomer'

import 'Shared/misc/register-service-worker'
import 'Shared/misc/handle-network-status'
import 'Shared/misc/handle-apple-install-prompt'
import 'pwacompat'

Vue.config.productionTip = true
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = false // process.env.NODE_ENV !== 'production'

// permission control
// no redirect whitelist
const whiteList =
  /^\/(login|password-reset|register|confirm|404|dpl\/[a-zA-Z0-9]+)?$/

router.beforeEach(async (to, from, next) => {
  // start progress bar
  nprogress.start()
  if (store.state.authentication.user) {
    if (to.path === '/login' || to.path === '/password-reset') {
      // if is logged in, redirect to the home page
      nprogress.done()
      next({ path: '/' })
    } else {
      next()
    }
  } else if (whiteList.test(to.path)) {
    // in the free login whitelist, go directly
    next()
  } else {
    // other pages that do not have permission to access are redirected to the login page.
    nprogress.done()
    next(`/?redirect=${to.path}`)
  }
})

router.afterEach(() => {
  // finish progress bar
  nprogress.done()
})

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

const auth = getAuth()
onAuthStateChanged(auth, async (firebaseUser) => {
  await initLocalStorage()
  await store
    .dispatch(`authentication/stateChanged`, firebaseUser)
    .then(() => {
      new Vue({
        router,
        store,
        i18n,
        nprogress,
        render: (h) => h(App),
      }).$mount('#app')
    })
    .catch(() => {
      console.warn(
        `authStateChanged: ${
          firebaseUser ? 'login' : 'logout'
        } authentication/login error`
      )
      new Vue({
        router,
        store,
        i18n,
        nprogress,
        render: (h) => h(App),
      }).$mount('#app')
    })
})
