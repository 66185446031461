import Vue from 'vue'
import vueSetPath from 'Shared/store/_partials/vueSetPath'

export default {
  /* all */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  remove: (state, item) => Vue.delete(state.all, item.id),

  /* list items with filters */
  list: (state, list) => (state.list = list),
  listConstraints: (state, value) => (state.listConstraints = value),
  loading: (state, value) => (state.loading = !!value),
  filterCollection: (state, value) => (state.filterCollection = value),
  filterOwner: (state, value) => (state.filterOwner = value),
  filterType: (state, value) => (state.filterType = value),
  filterStatus: (state, value) => {
    state.filterStatus = value
    state.listPage = 1
  },

  /* list item selection */
  selection: (state, value) => {
    if (state.selectionLock) return
    state.selection = value
    state.selectionAdded = false
  },
  selectionAdd: (state, id) => {
    if (state.selectionLock) return
    state.selection.push(id)
    state.selectionAdded = true
  },
  selectionRemove: (state, id) => {
    if (state.selectionLock) return
    const index = state.selection.findIndex((el) => el === id)
    if (index < 0) return
    state.selection.splice(index, 1)
    state.selectionAdded = false
  },
  selectionLock: (state, value) => (state.selectionLock = value),

  /* display settings */
  layout: (state, value) => (state.layout = value),
  zoom: (state, value) => (state.zoom = value),
  scrollPosition: (state, { collectionId, layout, position }) =>
    Vue.set(state.scrollPosition[layout], collectionId, position),
  dpiWarning: (state, value) => (state.dpiWarning = value),
  previewPage: (state, value) => (state.previewPage = value),

  /* editing */
  active: (state, active) => (state.active = active),
  editItem: (state, item) => (state.editItem = item),
  updateEditItem: (state, { path, value }) =>
    vueSetPath(state.editItem, path, value),
  editIsNew: (state, val) => (state.editIsNew = val),
  updatePending: (state, val) => (state.updatePending = val),
  editDataLink: (state, val) => (state.editDataLink = val),

  /* deletionPending */
  addDeletionPending: (state, id) => Vue.set(state.deletionPending, id, true),
  removeDeletionPending: (state, id) => Vue.delete(state.deletionPending, id),

  /* publication's releases list */
  pubId: (state, id) => (state.pubId = id),
  pubList: (state, list) => (state.pubList = list),

  /* firestore unsubscribe functions for active listeners */
  listenerAll: (state, unsubscribe) => (state.listenerAll = unsubscribe),
  addListenerSingle: (state, { id, unsubscribe }) =>
    Vue.set(state.listenerSingle, id, unsubscribe),
  removeListenerSingle: (state, id) => Vue.delete(state.listenerSingle, id),
  listenerPub: (state, f) => (state.listenerPub = f),
}
