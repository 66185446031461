export default {
  /* get single item */
  get: (state) => (id) => {
    return id && state.all && state.all[id] ? state.all[id] : null
  },
  list: (state, getters) => state.list.map((id) => getters.get(id)),
  listUnread: (state, getters) => getters.list.filter((el) => !el.read),
  total: (state, getters) => getters.list.length,
  totalUnread: (state, getters) => getters.listUnread.length,
}
