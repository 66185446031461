export default {
  releaseAction: (state, getters, rootState, rootGetters) => (item) => {
    const { publicationIds } = item.meta
    if (publicationIds.length === 0) return { action: 'create' }
    if (publicationIds.length !== 1)
      return { action: 'none', reason: 'ambiguous' }
    const pubId = publicationIds[0]
    const editRelease = rootGetters['publications/getEditableRelease'](pubId)
    if (editRelease) return { action: 'update', releaseId: editRelease.id }
    if (rootGetters['publications/isNewReleaseAllowed'](pubId))
      return { action: 'release', publicationId: pubId }
    return { action: 'none', reason: 'locked' }
  },
  releaseLabel: (state, getters, rootState, rootGetters) => (item) => {
    const { contentId, numberMcs } = item.meta
    if (contentId) {
      const contentLabel = rootGetters['contents/getLabel'](contentId)
      if (contentLabel) return contentLabel
    }
    const ra = getters.releaseAction(item)
    if (ra.action === 'update') {
      const release = rootGetters['releases/get'](ra.releaseId)
      return (release && release.label) || ''
    }
    if (ra.action === 'release') {
      const pub = rootGetters['publications/get'](ra.publicationId)
      return (pub && pub.label && pub.label.de) || ''
    }
    return numberMcs || ''
  },
}
