export const all = Object.freeze([
  'pending',
  'review',
  'approval',
  'reviewAndPublish',
  'published',
  'rejected',
  'disabled',
])

export const orderAllowed = Object.freeze(['reviewAndPublish', 'published'])

export const filterDefault = Object.freeze([
  'pending',
  'review',
  'approval',
  'reviewAndPublish',
  'published',
])

export default all
