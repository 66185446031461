import { escapeRegExp, get } from 'lodash'

export default {
  fieldLabel: (state, getters, rootState, rootGetters) => {
    const { propId, lang, index } = rootGetters['contents/colToPropLangIdx'](
      state.field
    )
    const label = rootGetters['properties/getLabel']({
      id: propId,
      propLang: lang,
    })
    return index === null ? label : `${label} ${index + 1}`
  },
  fieldPath: (state, getters, rootState, rootGetters) =>
    state.field && rootGetters['contents/getValuePath'](state.field),
  isValid: (state, getters) => !!(state.field && state.search && getters.regex),
  matches: (state, getters) => (value) =>
    Array.from(String(value).matchAll(getters.regex), (m) => [
      m.index,
      m.index + m[0].length,
    ]),
  list: (state, getters, rootState, rootGetters) => {
    if (!getters.isValid) return []
    return rootGetters['contents/list']
      .filter(
        (c) =>
          !state.useSelection ||
          rootGetters['contents/selection'].includes(c.id)
      )
      .map((c) => {
        const original = get(c, getters.fieldPath, '')
        return {
          id: c.id,
          original,
          matchesAt: getters.matches(original),
          replaced: getters.replace(original),
        }
      })
      .filter((el) => el.matchesAt.length > 0)
  },
  regex: (state) => {
    const { search, useRegex } = state
    const pattern = useRegex ? search : escapeRegExp(search)
    try {
      return new RegExp(pattern, 'gu')
    } catch (e) {
      return undefined
    }
  },
  regexError: (state) => {
    if (!state.useRegex) return undefined
    try {
      RegExp(state.search, 'gu')
    } catch (e) {
      return e.message
    }
    return undefined
  },
  replace: (state, getters) => (value) =>
    String(value).replace(getters.regex, state.replace),
}
