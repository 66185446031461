import axios from 'axios'
import { isEqual } from 'lodash'
import config from '@/config'

export default {
  /**
   * Perform axios request
   * @param commit
   * @param dispatch
   * @param url
   * @param params
   * @param method
   * @param data
   * @param cancel
   * @returns {Promise<unknown>}
   */
  request: async (
    { commit, dispatch },
    { url, params = {}, method = 'get', data = null, cancel = false }
  ) => {
    if (cancel) {
      dispatch('stop')
      commit('loading', true)
    }
    const headers = { accept: 'application/json' }
    const token = await dispatch('authentication/getToken', null, {
      root: true,
    })
    if (token) {
      headers.authorization = `Bearer ${token}`
    }
    const options = {
      baseURL: config.api.picture.url,
      url,
      method,
      headers,
      params,
      data,
    }
    if (!cancel) return axios(options)

    const source = axios.CancelToken.source()
    commit('cancel', source.cancel)
    options.cancelToken = source.token

    return axios(options)
      .then((response) => {
        commit('cancel', null)
        return response
      })
      .catch((error) => {
        if (!axios.isCancel(error)) throw error
      })
      .finally(() => commit('loading', false))
  },

  /**
   * Cancel pending axios request
   * @param state
   * @param commit
   */
  stop: ({ state, commit }) => {
    if (state.cancel) {
      state.cancel()
      commit('cancel', null)
    }
  },

  /**
   * Runs query when any parameter has changed
   * @returns {Promise<*>}
   */
  search: async ({ state, commit, getters, dispatch }, reset = false) => {
    if (reset) {
      // goto first page
      commit('page', 1)
    }

    // params have changed?
    if (isEqual(getters.params, state.params)) return state.result.data

    // run query
    return dispatch('query')
  },

  /**
   * Query pictures from API
   * @param context
   * @returns {Promise<any|Promise>}
   */
  query: async ({ state, commit, getters, dispatch }) => {
    commit('params', getters.params)
    const request = {
      url: '/pictures',
      params: { ...state.params },
      cancel: true,
    }
    return dispatch('request', request).then((response) => {
      commit('result', response.data)
      response.data.data.forEach((item) => commit('cacheUpdate', item))
      return response.data.data
    })
  },

  /**
   * Get picture by ID
   * @param dispatch
   * @param commit
   * @param id
   * @returns {Promise<*>}
   */
  get: async ({ dispatch, commit }, id) => {
    const request = {
      url: `/pictures/${id}`,
    }
    return dispatch('request', request).then((response) => {
      if (!response.data.success) throw Error(response.data.message)
      commit('cacheUpdate', response.data.data)
      return response.data.data
    })
  },

  /**
   * Get picture by file name
   * @param commit
   * @param getters
   * @param dispatch
   * @param name
   * @returns {Promise<*>}
   */
  getByName: async ({ commit, getters, dispatch }, name) => {
    name = getters.base(name)
    const request = {
      url: `/pictures/by-name/${name}`,
    }
    return dispatch('request', request).then((response) => {
      if (!response.data.success) throw Error(response.data.message)
      commit('cacheUpdate', response.data.data)
      return response.data.data
    })
  },

  /**
   * Get all pictures for a given list of file names, uses cached records
   * @param dispatch
   * @param getters
   * @param names
   * @returns {Promise<unknown[]>}
   */
  getMissing: async ({ dispatch, getters }, names = []) => {
    return Promise.all(
      names.map((name) => getters.get(name) || dispatch('getByName', name))
    )
  },

  /**
   * Upload files
   * @param context
   * @param files
   * @returns {Promise}
   */
  upload: async ({ commit, dispatch }, files) => {
    commit('uploadsFlush')
    commit('uploadsShow', true)
    const tasks = Array.prototype.map.call(files, (file, index) => {
      commit('uploadsAdd', {
        index,
        name: file.name,
        size: file.size,
        type: file.type,
        done: false,
        error: null,
      })
      const formData = new FormData()
      formData.set('upload', file, file.name)
      return dispatch('request', {
        url: '/pictures',
        method: 'post',
        data: formData,
      })
        .then((response) => {
          if (response.data.success) {
            commit('uploadsSetDone', index)
          } else {
            commit('uploadsSetError', {
              index,
              message: 'upload not successful',
            })
          }
        })
        .catch((error) => {
          let message = 'upload error'
          if (error.response) {
            const { data } = error.response
            if (data && data.errors && data.errors.upload) {
              ;[message] = data.errors.upload
            } else if (data && data.message) {
              message = data.message
            }
          } else {
            message = error.message
          }
          commit('uploadsSetError', {
            index,
            message,
          })
        })
    })
    return Promise.all(tasks).then(() => {
      commit('search', '')
      commit('source', 'owner')
      commit('sortBy', 'created_at')
      commit('sortDesc', true)
      commit('page', 1)
      return dispatch('query')
    })
  },

  /**
   * Delete file
   * @param dispatch
   * @param id
   * @returns {Promise<*>}
   */
  delete: async ({ dispatch }, id) => {
    return dispatch('request', {
      url: `/pictures/${id}`,
      method: 'delete',
    })
      .then((response) => {
        if (!(response.data && response.data.success))
          throw Error('api did not return success')
      })
      .finally(() => dispatch('query'))
  },
}
