export default [
  {
    path: '/contents',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    children: [
      {
        path: '/contents/:content',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-contents-content" */ './content.vue'
          ),
        name: 'content-show',
        props: { action: 'show' },
        hidden: true,
        meta: {
          title: 'contents.title.entry',
          icon: 'las la-book',
          noCache: true,
        },
      },
    ],
  },
]
