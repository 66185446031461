import { isNil } from 'lodash'
import MAX_POSITIONS from 'Modules/orders/shared/constants/max-positions'
import Database from '../../basket-db'

export default {
  /**
   * Get all basket items from server
   * @param state
   * @param commit
   * @returns {Promise<void>}
   */
  getAll: async ({ state, commit }) => {
    if (state.listenerAll !== null)
      return Promise.reject(Error('already listening to basket'))
    commit('flush')
    const db = new Database(commit)
    await db
      .queryAndListen(
        [],
        MAX_POSITIONS,
        0,
        [['createTimestamp', 'asc']],
        '',
        'list'
      )
      .then((f) => commit('listenerAll', f))
    return Promise.resolve()
  },

  /**
   * create or update item in database
   * @param context
   * @param doc
   */
  set: async ({ commit }, doc) => {
    const db = new Database(commit)
    const id = !isNil(doc.id) && doc.id ? doc.id : null
    if ('id' in doc) delete doc.id
    const created = await db.create(doc, id)
    commit('update', created)
  },

  /**
   * Update item in database
   * @param context
   * @param doc
   * @returns {Promise<*>}
   */
  update: async ({ commit }, doc) => {
    if (isNil(doc.id)) return false
    const db = new Database(commit)
    return db.update(doc)
  },

  updateValue: async ({ dispatch }, { basketId, path, value }) => {
    const updateDoc = { id: basketId }
    updateDoc[path] = value
    return dispatch('update', updateDoc)
  },

  /**
   * Add item to basket or increase quantity if already in basket
   * @param context
   * @param releaseId
   * @returns {Promise<*>}
   */
  addOrIncrease: async ({ getters, dispatch, rootGetters }, releaseId) => {
    const release = await dispatch('releases/getOneOnce', releaseId, {
      root: true,
    })
    const basketItem = getters.getByRelease(releaseId)
    if (!rootGetters['releases/isOrderAllowed'](releaseId)) {
      if (basketItem) {
        dispatch('delete', basketItem.id)
      }
      throw Error(`Order not allowed for release ${releaseId}`)
    }
    const itemData = getters.releaseToItemData(release, true)
    if (basketItem) {
      return dispatch('update', { id: basketItem.id, ...itemData })
    }
    if (getters.totalPositions >= MAX_POSITIONS) {
      throw Error('Maximum number of positions reached')
    }
    const label = release && release.label ? release.label : releaseId
    return dispatch('set', { releaseId, label, ...itemData })
  },

  updateItemsFromReleases: async ({ getters, dispatch }) => {
    const tasks = getters.list.map(({ id, releaseId }) => {
      if (!releaseId) return dispatch('delete', id)
      return dispatch('releases/getOneOnce', releaseId, { root: true }).then(
        (release) => {
          if (!release)
            throw new Error(
              `Cannot update basket item from release ${releaseId}: Release not found`
            )
          const data = getters.releaseToItemData(release)
          return dispatch('update', { id, ...data })
        }
      )
    })
    return Promise.all(tasks)
  },

  /**
   * Delete item from database
   * @param context
   * @param docId
   * @returns {Promise<void>}
   */
  delete: async ({ commit }, docId) => {
    const db = new Database(commit)
    return db.delete(docId)
  },

  deleteAll: async ({ state, dispatch }) => {
    const deleting = state.list.map((id) => dispatch('delete', id))
    return Promise.all(deleting)
  },
}
