import { isEqual, cloneDeep } from 'lodash'
import { debug } from 'Shared/utils/log'
import Database from '../../publications-db'

export default {
  validateFilters: ({ state, commit, rootGetters }) => {
    if (
      state.filterOwner === 'supplier' &&
      rootGetters['authentication/isSupplier']
    )
      return true
    if (!state.filterOwner && rootGetters['authentication/isAdmin']) return true
    if (state.filterOwner === 'organization') return true
    commit('filterOwner', 'organization')
    return false
  },

  /**
   * Fetch new paginated list
   * @param context
   * @returns {Promise<void>}
   */
  newList: async ({ state, commit, getters, dispatch }) => {
    dispatch('validateFilters')
    const constraints = getters.listConstraints
    if (state.listenerAll) {
      // active listener has same constraints?
      if (isEqual(state.listConstraints, constraints)) return Promise.resolve()
      // stop listener
      state.listenerAll()
      commit('listenerAll', null)
    }
    // clear list
    commit('list', [])
    commit('listPage', 1)
    // remember constraints
    commit('listConstraints', constraints)

    // set up listener
    const db = new Database(commit)
    await db
      .queryAndListen(constraints, 250, false, false, '', 'list')
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
    return Promise.resolve()
  },

  /**
   * Request one from database and listen for changes
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  getOne: async ({ state, getters, commit }, id) => {
    if (state.listenerSingle[id]) {
      debug(`already listening to publication/${id}`)
      return getters.get(id)
    }
    if (state.listenerAll && state.list.includes(id)) {
      debug(`listener all covers publication/${id}`)
      return getters.get(id)
    }
    debug(`add single listener for publication/${id}`)
    const db = new Database(commit)
    return db.readAndListen(id)
  },
  stopListening: ({ state, commit }, id) => {
    if (!state.listenerSingle[id]) {
      debug(`unknown single listener for publication/${id}`)
      return
    }
    debug(`stop listener for publication/${id}`)
    state.listenerSingle[id]()
    commit('removeListenerSingle', id)
  },

  getOneOnce: async ({ state, getters, commit }, id) => {
    if (state.listenerSingle[id]) return getters.get(id)
    if (state.listenerAll && state.list.includes(id)) return getters.get(id)
    const db = new Database(commit)
    return db.read(id).then((doc) => {
      // add document to cache
      commit('update', doc)
      return doc
    })
  },

  /**
   * Fetch one and set as editItem
   * @param commit
   * @param state
   * @param id
   * @returns {Promise<void>}
   */
  loadEditItem: async ({ commit, dispatch }, id) => {
    commit('editItem', null)
    commit('editIsNew', false)
    const item = await dispatch('getOne', id)
    commit('editItem', cloneDeep(item))
  },

  /**
   * Call loadEditItem and set as new item
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  loadEditItemCopy: async ({ commit, dispatch, rootGetters }, id) => {
    commit('editIsNew', true)
    commit('editItem', null)
    const item = cloneDeep(await dispatch('getOneOnce', id))
    delete item.id
    item.owner = rootGetters['authentication/organizationId']
    item.supplier = rootGetters['authentication/supplier']
    commit('editItem', item)
  },

  /**
   * Set editItem to empty item template
   * @param rootState
   * @param rootGetters
   * @param commit
   * @returns {Promise<void>}
   */
  resetEditItem: async ({ rootGetters, commit }) => {
    const editItem = {
      owner: rootGetters['authentication/organizationId'],
      supplier: rootGetters['authentication/supplier'],
    }
    commit('editItem', editItem)
    commit('editIsNew', true)
  },
}
