import { getAuth } from 'firebase/auth'
import GenericDb from 'Shared/firebase/generic-db'

export default class BasketDb extends GenericDb {
  constructor(commit) {
    const auth = getAuth()
    const userId = auth.currentUser.uid
    super(`users/${userId}/basket`, commit)
  }
}
