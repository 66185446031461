export default [
  {
    path: '/artwork',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    meta: {
      title: 'artwork.title.navigation',
      icon: 'las la-brush',
      roles: ['supplier'],
    },
    children: [
      {
        path: '/artwork/templates',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-templates" */ 'Modules/templates/templates.vue'
          ),
        children: [
          {
            path: '/artwork/templates/:organization?',
            components: {
              default: () =>
                import(
                  /* webpackChunkName: "client-chunk-templates" */ 'Modules/templates/shared/components/list/index.vue'
                ),
              filter: () =>
                import(
                  /* webpackChunkName: "client-chunk-templates" */ 'Modules/templates/shared/components/FilterOrganization.vue'
                ),
            },
            name: 'templates',
            props: {
              default: true,
            },
            meta: {
              title: 'templates.title.navigation',
              icon: 'las la-file-pdf',
              noCache: true,
              roles: ['supplier'],
            },
          },
        ],
      },
      {
        path: '/artwork/template/:id',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-templates" */ 'Modules/templates/template-edit/template-edit.vue'
          ),
        name: 'templates-edit',
        hidden: true,
        props: true,
        meta: {
          title: 'templates.title.edit',
          icon: 'las la-file-pdf',
          noCache: true,
          roles: ['supplier'],
        },
      },
      {
        path: '/artwork/fonts',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-artwork" */ './fonts-list/fonts-list.vue'
          ),
        name: 'fonts',
        meta: {
          title: 'artwork.fonts.title',
          icon: 'las la-font',
          noCache: true,
        },
      },
      {
        path: '/artwork/hyphens',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-artwork" */ './hyphens-list/hyphens-list.vue'
          ),
        name: 'hyphens',
        meta: {
          title: 'artwork.hyphens.title',
          icon: 'las la-spell-check',
          noCache: true,
        },
      },
    ],
  },
]
