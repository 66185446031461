import romanMonths from './roman-months'
import getMonthsFromRange from './months-array-from-range'

const findKey = (obj, search) => {
  const idx = Object.values(obj).findIndex((el) => el === search)
  if (idx === -1) return undefined
  return Object.keys(obj)[idx]
}

export default (sMonths) => {
  const monthGroups = sMonths.split(/[,;]/).map((p) => p.trim())

  const aMonths = []

  const regexDecimal =
    /^([ame])?(1[0-2]|0?[1-9])(\s?(-|.)\s?([ame])?(1[0-2]|0?[1-9]))?$/i

  // try filling month array with regexDecimal
  monthGroups.forEach((sGroup) => {
    const match = regexDecimal.exec(sGroup)
    if (match) {
      // each match can be a range, break that down to month numbers
      const aMonthsRange = getMonthsFromRange(match[2], match[6] || match[2])

      // and append it to months array
      aMonthsRange.forEach((month) => {
        if (!aMonths.includes(month)) {
          aMonths.push(month)
        }
      })
    }
  })

  if (aMonths.length) return aMonths.sort()

  const allRoman = Object.values(romanMonths).join('|')
  const regexRoman = new RegExp(
    `^(${allRoman})(\\s?\\-\\s?(${allRoman}))?$`,
    'i'
  )

  // try filling month array with regexRoman
  monthGroups.forEach((sGroup) => {
    const match = regexRoman.exec(sGroup)
    if (match) {
      // translate roman numbers to decimals
      const monthFrom = findKey(romanMonths, match[1])
      const monthTo = match[3] ? findKey(romanMonths, match[3]) : monthFrom

      // each match can be a range, break that down to month numbers
      const aMonthsRange = getMonthsFromRange(monthFrom, monthTo)

      // and append it to months array
      aMonthsRange.forEach((month) => {
        if (!aMonths.includes(month)) {
          aMonths.push(month)
        }
      })
    }
  })

  return aMonths.sort()
}
