export default [
  {
    path: '/config/properties',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-properties" */ './properties.vue'
      ),
    name: 'properties',
    meta: {
      title: 'properties.title.navigation',
      icon: 'las la-i-cursor',
      noCache: true,
      roles: [],
    },
  },
]
