import Vue from 'vue'

export default {
  /* all */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  remove: (state, item) => Vue.delete(state.all, item.id),

  /* handle firestore listeners for changes */
  listenerAll: (state, func) => (state.listenerAll = func),
  loading: (state, val) => (state.loading = val),
}
