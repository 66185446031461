import { cloneDeep, get, isNil, isEqual, set } from 'lodash'
import { deleteField } from 'firebase/firestore'
import monthStringToArray from 'Shared/misc/month-string-to-array'
import { debug } from 'Shared/utils/log'
import i18n from '@/plugins/i18n'
import axios from 'axios'
import config from '@/config'
import Database from '../../contents-db'

export default {
  validateFilters: ({ state, commit, rootGetters }) => {
    if (state.filterOwner === 'public') return true
    if (
      (!state.filterOwner || state.filterOwner === 'supplier') &&
      rootGetters['authentication/isSupplier']
    )
      return true
    if (!state.filterOwner && rootGetters['authentication/isAdmin']) return true
    if (state.filterOwner === 'organization') return true
    commit('filterOwner', 'organization')
    return false
  },

  /**
   * Fetch new list
   * @param context
   * @returns {Promise<void>}
   */
  newList: async ({ state, getters, commit, dispatch }) => {
    dispatch('validateFilters')
    const constraints = getters.listConstraints
    if (state.listenerAll) {
      if (isEqual(state.listConstraints, constraints)) return Promise.resolve()
      // stop listener all
      state.listenerAll()
      commit('listenerAll', null)

      // update listCollection entry (if any)
      const filterCollection = state.listConstraints.find((filter) =>
        filter[0].startsWith('collections.')
      )
      const collectionId =
        (filterCollection && filterCollection[0].substr(12)) || null
      const listCollection =
        (collectionId && state.listCollection[collectionId]) || null
      if (listCollection) {
        listCollection.hasListener = false
        commit('listCollection', {
          id: collectionId,
          item: listCollection,
        })
      }
    }
    commit('listConstraints', constraints)
    commit('list', [])
    commit('editInlineIds', [])
    if (state.filterCollection && !state.filterType) {
      commit('listCollection', {
        id: state.filterCollection,
        item: {
          lastUpdate: null,
          hasListener: true,
          list: [],
        },
      })
    }

    const db = new Database(commit)
    await db
      .queryAndListen(constraints, 3000, false, false, '', 'list')
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
    return Promise.resolve()
  },

  /**
   * Get one item from database and listen for changes
   * @param context
   * @param id
   * @returns {Promise<*>}
   */
  getOne: async ({ state, getters, commit }, id) => {
    if (state.listenerSingle[id]) {
      debug(`already listening to content/${id}`)
      return getters.get(id)
    }
    if (state.listenerAll && state.list.includes(id)) {
      debug(`listener all covers content/${id}`)
      return getters.get(id)
    }
    debug(`add single listener for content/${id}`)
    const db = new Database(commit)
    return db.readAndListen(id)
  },
  stopListening: ({ state, commit }, id) => {
    if (!state.listenerSingle[id]) {
      debug(`unknown single listener for content/${id}`)
      return
    }
    debug(`stop listener for content/${id}`)
    state.listenerSingle[id]()
    commit('removeListenerSingle', id)
  },

  getOneOnce: async ({ state, getters, commit }, id) => {
    if (state.listenerSingle[id]) return getters.get(id)
    if (state.listenerAll && state.list.includes(id)) return getters.get(id)
    const db = new Database(commit)
    return db.read(id).then((doc) => {
      if (doc !== null) commit('update', doc)
      return doc
    })
  },

  getReferenced: async ({ dispatch, getters, rootGetters }, id) => {
    const content = await dispatch('getOneOnce', id)
    if (!content || !content.data) return []
    const refProps = rootGetters['properties/byType']('reference')
    const refIds = []
    Object.keys(content.data).forEach((propId) => {
      if (refProps.findIndex((p) => p.id === propId) !== -1) {
        const propRefIds = getters.getValueFromData(content.data, propId)
        if (propRefIds && propRefIds instanceof Array) {
          propRefIds.forEach(
            (refId) => !refIds.includes(refId) && refIds.push(refId)
          )
        }
      }
    })
    const references = refIds.map(
      (refId) => getters.get(refId) || dispatch('getOneOnce', refId)
    )
    return Promise.all(references)
  },

  getByCollection: async ({ state, getters, commit, rootGetters }, id) => {
    if (!id) throw Error('Parameter collection ID is required')
    if (state.listCollection[id]) {
      const { hasListener, lastUpdate } = state.listCollection[id]
      if (hasListener) {
        debug(`getByCollection(${id}): covered by listener all`)
        return getters.listCollection(id)
      }
      const maxAge = new Date()
      maxAge.setMinutes(maxAge.getMinutes() - 10)
      if (lastUpdate && lastUpdate > maxAge) {
        debug(`getByCollection(${id}): last update within 10 minutes`)
        return getters.listCollection(id)
      }
    }
    debug(`getByCollection(${id}): get list once`)
    const constraints = [[`collections.${id}`, '==', true]]
    if (
      !rootGetters['authentication/isSupplier'] &&
      !rootGetters['authentication/isAdmin']
    ) {
      constraints.push([
        'owner',
        '==',
        rootGetters['authentication/organizationId'],
      ])
    }
    const db = new Database(commit)
    const result = await db.query(constraints, 1000)
    const list = result.map((el) => el.id)
    commit('listCollection', { id, item: { lastUpdate: new Date(), list } })
    result.forEach((item) => commit('update', item))
    return result
  },

  getByRelease: async ({ state, commit, rootGetters }, id) => {
    if (!id) throw Error('Parameter release ID is required')
    if (state.listenerRel && state.relId === id) {
      debug(`already listening for contents of release ${id}`)
      return Promise.resolve()
    }
    const release = rootGetters['releases/get'](id)
    const contentIds =
      release && release.referencedContents
        ? Object.keys(release.referencedContents)
        : []
    if (contentIds.length) {
      const missing = contentIds.filter((cId) => {
        if (state.listenerAll && state.list.includes(cId)) return false
        if (state.listenerRel && state.relList.includes(cId)) return false
        return !state.listenerSingle[cId]
      })
      if (missing.length === 0) {
        debug(`release ${id} contents covered by existing listeners`)
        return Promise.resolve()
      }
    }
    if (state.listenerRel) {
      debug(`stop listener for release ${state.relId} contents`)
      state.listenerRel()
      commit('listenerRel', null)
    }
    debug(`start listener for release ${id} contents`)
    commit('relId', id)
    commit('relList', [])
    const constraints = [[`releases.${id}`, '==', true]]
    if (!rootGetters['authentication/isAdmin']) {
      if (rootGetters['authentication/isSupplier']) {
        constraints.push([
          'supplier',
          '==',
          rootGetters['authentication/organizationId'],
        ])
      } else {
        constraints.push([
          'owner',
          '==',
          rootGetters['authentication/organizationId'],
        ])
      }
    }
    const db = new Database(commit)
    await db
      .queryAndListen(constraints, 250, false, false, '', 'relList')
      .then((f) => commit('listenerRel', f))
    return Promise.resolve()
  },
  stopListenerRelease: ({ state, commit }) => {
    if (!state.listenerRel) {
      debug(`no listener for release contents to stop`)
      return
    }
    debug(`stop listener for release ${state.relId} contents`)
    state.listenerRel()
    commit('listenerRel', null)
    commit('relId', null)
    commit('relList', [])
  },

  /**
   * Fetch one and set as editItem
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  loadEditItem: async ({ commit, dispatch }, id) => {
    commit('active', id)
    commit('editIsNew', false)
    commit('editItem', null)
    const item = await dispatch('getOne', id)
    commit('editItem', cloneDeep(item))
  },

  create: async ({ dispatch }, { collectionId, names, lang }) => {
    if (isNil(collectionId)) throw Error('Parameter collectionId is required')
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    names = names
      .split('\n')
      .map((el) => el.trim())
      .filter(Boolean)
    return axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'ContentsCreate',
        variables: { collectionId, names, lang },
        query: `
mutation ContentsCreate($collectionId: ID!, $names: [String], $lang: String) {
  contentsCreate(collectionId: $collectionId, names: $names, lang: $lang) { id }
}
`,
      },
    }).then((result) => {
      if (result.data.errors && result.data.errors[0]) {
        throw Error(result.data.errors[0].message)
      }
      return get(result, 'data.data.contentsCreate', []).map(({ id }) => id)
    })
  },

  /**
   * @param context
   * @param id
   * @returns {Promise<*>}
   */
  duplicate: async ({ dispatch }, { ids, collectionId }) => {
    if (isNil(ids)) throw Error('Parameter ids is required')
    if (!(ids instanceof Array)) ids = [ids]
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'ContentsDuplicate',
        variables: { ids, collectionId },
        query: `
mutation ContentsDuplicate($ids: [ID!]!, $collectionId: ID) {
  contentsDuplicate(ids: $ids, collectionId: $collectionId) {
    id
  }
}
`,
      },
    }).then((result) => {
      if (result.data.errors && result.data.errors[0]) {
        throw Error(result.data.errors[0].message)
      }
      return get(result, 'data.data.contentsDuplicate', [])
    })
  },

  /**
   * @param context
   * @param id
   * @returns {Promise<*>}
   */
  createVariants: async ({ dispatch }, { ids, collectionId }) => {
    if (isNil(ids)) throw Error('Parameter ids is required')
    if (!(ids instanceof Array)) ids = [ids]
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'ContentsCreateVariants',
        variables: { ids, collectionId },
        query: `
mutation ContentsCreateVariants($ids: [ID!]!, $collectionId: ID) {
  contentsCreateVariants(ids: $ids, collectionId: $collectionId) {
    id
  }
}
`,
      },
    }).then((result) => {
      if (result.data.errors && result.data.errors[0]) {
        throw Error(result.data.errors[0].message)
      }
      return get(result, 'data.data.contentsCreateVariants', [])
    })
  },

  /**
   * create or update item in database
   * @param context
   * @param item
   * @returns {Promise<*>}
   */
  set: async ({ commit }, item) => {
    const db = new Database(commit)
    const id = !isNil(item.id) && item.id ? item.id : null
    delete item.id
    return db.create(item, id)
  },

  /**
   * Update item in database
   * @param context
   * @param item
   * @returns {Promise<*>}
   */
  update: async ({ commit }, item) => {
    if (isNil(item.id)) return false
    const db = new Database(commit)
    return db.update(item)
  },

  updateValue: async (
    { getters, rootGetters, dispatch },
    { contentId, path, value }
  ) => {
    if (isNil(contentId)) throw Error('parameter "contentId" is required')
    if (isNil(path)) throw Error('parameter "path" is required')
    const current = getters.getValue(contentId, path)
    if ((current && isEqual(current, value)) || (!current && !value))
      return true

    const { propId, index, lang } = getters.pathToPropLangIdx(path)
    const property = rootGetters['properties/get'](propId)
    if (property && property.multiple) {
      if (
        property.type === 'period' &&
        isNil(index) &&
        !(value instanceof Array)
      ) {
        value = monthStringToArray(value)
      } else if (!isNil(index)) {
        /*
         * firestore cannot update single array element by index,
         * must update complete array
         */
        // remove index portion from path
        path = getters.propLangIdxToPath(propId, lang)
        // get copy of current array
        const arrayCurrent = getters.getValue(contentId, path) || []
        const arrayUpdate = [...arrayCurrent]
        // remove (value === undefined) or update element at given index
        arrayUpdate.splice(index, 1, value)
        value = arrayUpdate
      }
    }

    const updateDoc = { id: contentId }
    if (value === undefined) {
      updateDoc[path] = deleteField()
    } else {
      updateDoc[path] = value
    }

    // check automatic label
    const currentDoc = getters.get(contentId)
    if (path.indexOf('label') !== 0 && getters.labelUpToDate(currentDoc)) {
      // label could be updated
      const contentType = rootGetters['contentTypes/get'](currentDoc.type)
      const template = (contentType && contentType.labelDefault) || '{name}'
      const labelPropIds = Array.from(
        template.matchAll(/{([\w.]+)}/g),
        (match) => getters.colToPropLangIdx(match[1]).propId
      )
      if (labelPropIds.includes(propId)) {
        // updated property affects automatic label
        const newDoc = cloneDeep(currentDoc)
        set(newDoc, path, value)
        updateDoc[`label.${i18n.locale}`] = getters.generateLabel(
          contentType,
          newDoc.data
        )
      }
    }

    return dispatch('update', updateDoc)
  },

  updateLabels: async ({ state, getters, dispatch }) => {
    const contents = state.list.map(getters.getInherited).filter((item) => {
      if (!state.filterShowVariants && item.parentId) return false
      if (!state.filterShowInactive && !item.active) return false
      return (
        !state.editInline.ids.length || state.editInline.ids.includes(item.id)
      )
    })
    return Promise.all(
      contents.map((content) => {
        const autoLabel = getters.generateLabel(content.type, content.data)
        if (content.label[i18n.locale] === autoLabel) return null
        const updateDoc = { id: content.id }
        updateDoc[`label.${i18n.locale}`] = autoLabel
        return dispatch('update', updateDoc)
      })
    ).then((result) => result.filter(Boolean).length)
  },

  /**
   * add collection.collectionID field
   * @param dispatch
   * @param id
   * @param collectionId
   * @returns {Promise<*>}
   */
  addCollection: async ({ dispatch }, { id, collectionId }) => {
    // check parameters
    if (isNil(id)) throw Error('Parameter id is required')
    if (isNil(collectionId)) throw Error('Parameter collectionId is required')

    // check if link exists
    const content = await dispatch('getOneOnce', id)
    if (get(content, `collections.${collectionId}`)) {
      // link already exists
      return Promise.resolve(content)
    }

    // add collection to content
    const doc = { id }
    doc[`collections.${collectionId}`] = true
    return dispatch('update', doc)
  },

  /**
   * remove collection.collectionID field
   * @param context
   * @param id
   * @param collectionId
   * @returns {Promise<*>}
   */
  removeCollection: async ({ dispatch }, { id, collectionId }) => {
    // check parameters
    if (isNil(id)) throw Error('Parameter id is required')
    if (isNil(collectionId)) throw Error('Parameter collectionId is required')

    // check if link exists
    const content = await dispatch('getOneOnce', id)
    if (
      !content.collections ||
      !{}.hasOwnProperty.call(content.collections, collectionId)
    ) {
      // link does not exist
      return Promise.resolve(content)
    }

    // remove collection from content
    const doc = { id }
    doc[`collections.${collectionId}`] = deleteField()
    return dispatch('update', doc)
  },

  /**
   * add releases.releaseID field
   * @param dispatch
   * @param id
   * @param releaseId
   * @returns {Promise<*>}
   */
  addRelease: async ({ dispatch }, { id, releaseId }) => {
    if (isNil(id) || isNil(releaseId)) return false
    const doc = { id }
    doc[`releases.${releaseId}`] = true
    return dispatch('update', doc)
  },

  /**
   * remove releases.releaseID field
   * @param dispatch
   * @param id
   * @param releaseId
   * @returns {Promise<*>}
   */
  removeRelease: async ({ dispatch }, { id, releaseId }) => {
    if (isNil(id) || isNil(releaseId)) return false
    const doc = { id }
    doc[`releases.${releaseId}`] = deleteField()
    return dispatch('update', doc)
  },

  /**
   * Create a new property and reset property name input
   */
  commitEditItem: ({ dispatch, state, commit }) => {
    if (state.editItem === null) return false
    const item = cloneDeep(state.editItem)
    commit('editItem', null)
    commit('active', null)
    if (state.filterType !== item.type) commit('filterType', item.type)
    return dispatch('set', item)
  },

  /**
   * Delete item from database
   * @param context
   * @param id
   * @returns {Promise<*>}
   */
  delete: async ({ commit }, id) => {
    if (isNil(id)) throw Error('Parameter id is required')
    const db = new Database(commit)
    return db.delete(id)
  },

  /**
   * Delete items from database
   * @param context
   * @param ids
   * @returns {Promise<unknown[]>}
   */
  deleteMany: async ({ dispatch }, ids) => {
    if (isNil(ids)) throw Error('Parameter ids is required')
    if (!(ids instanceof Array))
      throw Error('Parameter ids must be of type Array')
    if (!ids.length) throw Error('Parameter ids is empty array')
    const remove = ids.map((id) => dispatch('delete', id))
    return Promise.all(remove)
  },
}
