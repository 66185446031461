import i18n from '@/plugins/i18n'
import ROMAN from './roman-months'

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index
}

const formatMonth = (month, format, lang = 'de') => {
  if (format === 'monthsRoman') return ROMAN[month]
  const name = i18n.t(`month.${month}`, lang)
  if (format === 'monthsFull' || format === 'monthsFullText') return name
  if (format === 'monthsShort') {
    if (name.length < 4) return name
    return `${name.substr(0, 3)}.`
  }
  return month
}

const formatRange = (start, end, format = 'monthsRoman', lang = 'de') => {
  if (start === end) {
    return formatMonth(start, format, lang)
  }
  let divider = ' – '
  if (format === 'monthsRoman') {
    divider = '–'
  } else if (format === 'monthsFullText') {
    divider = ` ${i18n.t('month.till', lang)} `
  }
  return `${formatMonth(start, format, lang)}${divider}${formatMonth(
    end,
    format,
    lang
  )}`
}

export default (months, format, lang) => {
  if (!months || !(months instanceof Array)) return ''

  const unique = months.filter(onlyUnique)
  months = unique.sort((a, b) => {
    return a - b
  })

  const ranges = []
  let start = months[0]
  let end = start
  months.forEach((month, i) => {
    if (month - end > 1) {
      // list interrupted
      ranges.push([start, end])
      start = month
    }
    end = month
    if (i === months.length - 1) {
      ranges.push([start, end])
    }
  })

  if (ranges.length === 2 && ranges[0][0] === 1 && ranges[1][1] === 12) {
    ranges.splice(0, 2, [ranges[1][0], ranges[0][1]])
  }

  const divider =
    format === 'monthsFullText' ? ` ${i18n.t('month.and', lang)} ` : ', '

  return ranges
    .map(([from, to]) => formatRange(from, to, format, lang))
    .join(divider)
}
