import { isNil, cloneDeep } from 'lodash'
import { debug } from 'Shared/utils/log'
import Database from '../../properties-db'

export default {
  /**
   * Fetch list and listen for changes
   * @param context
   * @returns {Promise<void>}
   */
  getAll: async ({ commit, state, rootGetters }) => {
    // already listening to all documents?
    if (state.listenerAll !== null)
      return Promise.reject(Error('already listening to all properties'))
    // clear vuex store
    commit('flush')
    // get constraints
    const constraints = []
    if (!rootGetters['authentication/isAdmin']) {
      const organization = rootGetters['authentication/organization']
      if (organization && organization.contentTypes) {
        constraints.push([
          'contentTypes',
          'array-contains-any',
          Object.keys(organization.contentTypes),
        ])
      }
    }
    if (!constraints.length) {
      debug('get ALL properties')
    }
    // set up listener
    const db = new Database(commit)
    await db
      .queryAndListen(constraints, 250)
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
    return Promise.resolve()
  },

  /**
   * Request one from database and listen for changes
   * @param context
   * @param id
   * @returns {Promise<*>}
   */
  getOne: async ({ state, getters, commit }, id) => {
    // already listening to all documents?
    if (state.listenerAll) return getters.get(id)
    // already listening to this document?
    if (state.listenerSingle[id]) return getters.get(id)
    const db = new Database(commit)
    return db.readAndListen(id)
  },

  /**
   * Fetch one and set as editItem
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  loadEditItem: async ({ getters, commit }, id) => {
    const cache = getters.get(id)
    if (!cache) throw Error(`Property ${id} not found`)
    const item = cloneDeep(cache)
    commit('editItem', item)
    commit('editIsNew', false)
    return item
  },

  /**
   * Set editItem to empty item template
   * @param commit
   * @returns {Promise<void>}
   */
  resetEditItem: async ({ commit }) => {
    commit('editItem', {})
    commit('editIsNew', true)
  },

  /**
   * create or update item in database
   * @param context
   * @param item
   */
  set: async ({ commit }, item) => {
    const db = new Database(commit)
    const id = !isNil(item.id) && item.id ? item.id : null
    if ('id' in item) delete item.id
    return db.create(item, id)
  },

  /**
   * Update item in database
   * @param context
   * @param item
   * @returns {Promise<*>}
   */
  update: async ({ commit }, item) => {
    if (isNil(item.id)) throw Error('Field item.id is required')
    const db = new Database(commit)
    return db.update(item)
  },

  /**
   * Create a new property and reset property name input
   */
  commitEditItem: ({ dispatch, state, commit }) => {
    if (!state.editItem) throw Error('Empty editItem')
    const item = state.editItem
    commit('editItem', null)
    dispatch('set', item)
  },

  /**
   * Delete item from database
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  delete: async ({ commit }, id) => {
    const db = new Database(commit)
    return db.delete(id)
  },
}
