import { debug } from 'Shared/utils/log'
import packageJson from '@/../package.json'
import vueSetPath from './_partials/vueSetPath'
import stateToStore from './_localStorage/state-to-store'

const { version } = packageJson

const mutations = {
  loadStoreFromLocalStorage: (state) => {
    debug('loadStoreFromLocalStorage')

    // set version from package.json
    state.version = version

    if (localStorage.getItem('store')) {
      // get store from local storage
      const localStore = JSON.parse(localStorage.getItem('store'))
      if (!localStore || !localStore.version) return

      // check if major version is equal
      const storeVersion = localStore.version.substr(
        0,
        localStore.version.indexOf('.')
      )
      const appVersion = version.substr(0, version.indexOf('.'))
      if (storeVersion !== appVersion) return

      // apply values from local storage store
      stateToStore.forEach((path) => {
        if (Object.prototype.hasOwnProperty.call(localStore, path)) {
          vueSetPath(state, path, localStore[path])
        }
      })
    }
  },
}

export default mutations
