<script>
const classesSm =
  'tw-inline-flex tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-red-500 disabled:hover:tw-bg-red-500 disabled:tw-opacity-25 tw-px-2.5 tw-py-1.5 tw-text-xs tw-font-normal tw-text-white tw-shadow-sm hover:tw-bg-red-500/80 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-red-400 focus:tw-ring-offset-2 tw-leading-none'

const classesMd =
  'tw-inline-flex tw-items-center tw-rounded-md tw-border tw-border-transparent tw-bg-red-500 disabled:hover:tw-bg-red-500 disabled:tw-opacity-25 tw-px-4 tw-py-2 tw-text-sm tw-font-normal tw-text-white tw-shadow-sm hover:tw-bg-red-500/80 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-red-400 focus:tw-ring-offset-2 tw-leading-none'

export default {
  name: 'MfButtonDanger',
  props: {
    small: Boolean,
    to: Object,
  },
  render(h) {
    const el = this.to ? 'router-link' : 'button'
    return h(
      el,
      {
        class: this.small ? classesSm : classesMd,
        attrs: el === 'button' ? { type: 'button' } : undefined,
        props: el === 'router-link' ? { to: this.to } : undefined,
        on: {
          click: (ev) => this.$emit('click', ev),
        },
      },
      [this.$slots.default]
    )
  },
}
</script>
