import { orderAllowed } from '../constants/release-status'

/**
 * Check if it is allowed to order a given release
 * @param release
 * @returns {boolean}
 */
export default (release) => {
  // deny without release
  if (!release) return false
  // deny for digital release
  if (release.type === 'digital') return false
  // deny for release without status
  if (!release.status) return false
  // allow for release.status in white list
  return orderAllowed.includes(release.status)
}
