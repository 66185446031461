import Vue from 'vue'
import { pick } from 'lodash'

export default {
  /* cache */
  cacheClear: (state) => (state.all = {}),
  cacheUpdate: (state, item) => {
    Vue.set(
      state.all,
      item.name,
      pick(item, ['name', 'thumbnails', 'title', 'synonym', 'id'])
    )
  },

  /* filter */
  search: (state, val) => (state.search = val),
  source: (state, val) => (state.source = val),

  /* sort */
  sortBy: (state, val) => (state.sortBy = val),
  sortDesc: (state, val) => (state.sortDesc = !!val),

  /* pagination */
  page: (state, val) => (state.page = val),
  perPage: (state, val) => (state.perPage = val),

  /* result */
  params: (state, val) => (state.params = val),
  result: (state, val) => (state.result = val),
  flush: (state) => (state.result = { data: [] }),

  /* request */
  loading: (state, val) => (state.loading = !!val),
  cancel: (state, val) => (state.cancel = val),

  /* uploads */
  uploadsFlush: (state) => (state.uploads = []),
  uploadsAdd: (state, val) => state.uploads.push(val),
  uploadsSetDone: (state, index) => Vue.set(state.uploads[index], 'done', true),
  uploadsSetError: (state, { index, message }) =>
    Vue.set(state.uploads[index], 'error', message),
  uploadsShow: (state, val) => (state.uploadsShow = !!val),
}
