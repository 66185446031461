<script>
const classesSm =
  'tw-items-center tw-rounded tw-border tw-border-transparent disabled:tw-opacity-25 tw-px-2.5 tw-py-1.5 tw-text-xs tw-font-normal tw-text-zinc-900 hover:tw-bg-zinc-50 focus:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-green-400 focus-visible:tw-ring-offset-2 tw-leading-none'

const classesMd =
  'tw-items-center tw-rounded-md tw-border tw-border-transparent disabled:tw-opacity-25 tw-px-4 tw-py-2 tw-text-sm tw-font-normal tw-text-zinc-900 hover:tw-bg-zinc-50 focus:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-green-400 focus-visible:tw-ring-offset-2 tw-leading-none'

export default {
  name: 'MfButtonText',
  props: {
    block: Boolean,
    small: Boolean,
    to: Object,
  },
  render(h) {
    const el = this.to ? 'router-link' : 'button'
    return h(
      el,
      {
        class: [
          this.block ? 'tw-w-full tw-flex' : 'tw-inline-flex',
          this.small ? classesSm : classesMd,
        ],
        attrs: el === 'button' ? { type: 'button' } : undefined,
        props: el === 'router-link' ? { to: this.to } : undefined,
        on: {
          click: (ev) => this.$emit('click', ev),
        },
      },
      [this.$slots.default]
    )
  },
}
</script>
