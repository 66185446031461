import Vue from 'vue'
import vueSetPath from '../../../../shared/store/_partials/vueSetPath'

export default {
  /* all */
  flush: (state) => (state.all = { owner: {}, organization: {}, user: {} }),
  updateOwner: (state, item) => Vue.set(state.all.owner, item.id, item),
  updateOrganization: (state, item) =>
    Vue.set(state.all.organization, item.id, item),
  updateUser: (state, item) => Vue.set(state.all.user, item.id, item),
  removeOwner: (state, item) => Vue.delete(state.all.owner, item.id),
  removeOrganization: (state, item) =>
    Vue.delete(state.all.organization, item.id),
  removeUser: (state, item) => Vue.delete(state.all.user, item.id),
  loadingOwner: (state, val) => (state.loading.owner = !!val),
  loadingOrganization: (state, val) => (state.loading.organization = !!val),
  loadingUser: (state, val) => (state.loading.user = !!val),
  loadingContents: (state, val) => (state.loading.contents = !!val),
  loadingReleases: (state, val) => (state.loading.releases = !!val),

  /* active */
  active: (state, active) => (state.active = active),

  /* editItem */
  editItem: (state, item) => (state.editItem = item),
  updateEditItem: (state, { path, value }) =>
    vueSetPath(state.editItem, path, value),

  /* editIsNew */
  editIsNew: (state, val) => (state.editIsNew = val),

  /* editShow */
  editShow: (state, val) => (state.editShow = !!val),

  /* deletionPending */
  addDeletionPending: (state, id) => Vue.set(state.deletionPending, id, true),
  removeDeletionPending: (state, id) => Vue.delete(state.deletionPending, id),

  /* handle firestore listeners for changes */
  addListenerAll: (state, { source, unsubscribe }) =>
    Vue.set(state.listenerAll, source, unsubscribe),
  removeListenerAll: (state, source) => Vue.delete(state.listenerAll, source),

  showIds: (state, value) => (state.showIds = !!value),
  showType: (state, value) => (state.showType = value),

  publicationsFromContents: (state, colId) =>
    (state.publicationsFromContents = colId),

  lastUsed: (state, id) => {
    if (!(state.lastUsed instanceof Array)) {
      state.lastUsed = [id]
      return
    }
    // search for element to add
    const lastUsedIdx = state.lastUsed.findIndex((el) => el === id)
    if (lastUsedIdx === 0) return
    if (lastUsedIdx > 0) state.lastUsed.splice(lastUsedIdx, 1)
    state.lastUsed.unshift(id)
    if (state.lastUsed.length > 10) state.lastUsed = state.lastUsed.slice(0, 10)
  },
}
