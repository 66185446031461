import { isNil } from 'lodash'
import { DEFAULT_LANGUAGE } from '../../config/language'

const STATE = {
  sidebar: {
    opened: false,
    withoutAnimation: false,
  },
  device: 'desktop',
  networkOnLine: true,
  SWRegistrationForNewContent: null,
  showAddToHomeScreenModalForApple: false,
  refreshingApp: false,
  language: DEFAULT_LANGUAGE,
  showInfoDialog: false,
  showInfoName: null,
  loading: null,
}

const mutations = {
  setNetworkOnline: (state, value) => (state.networkOnLine = value),
  setLoading: (state, value) => (state.loading = value),
  setSWRegistrationForNewContent: (state, value) =>
    (state.SWRegistrationForNewContent = value),
  setShowAddToHomeScreenModalForApple: (state, value) =>
    (state.showAddToHomeScreenModalForApple = value),
  showInfoDialog: (state, value) => (state.showInfoDialog = !!value),
  showInfoName: (state, value) => (state.showInfoName = value),

  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  language: (state, lang) => (state.language = lang),
  loading: (state, val) => (state.loading = !!val),
}

const actions = {
  /**
   * Closes "add to home screen" modal for apple
   */
  closeAddToHomeScreenModalForApple: async ({ commit }) => {
    localStorage.setItem('addToHomeIosPromptLastDate', Date.now().toString())
    commit('setShowAddToHomeScreenModalForApple', false)
  },

  /**
   * Trigger service worker skipWating so the new service worker can take over.
   * This will also trigger a window refresh (see /src/misc/register-service-worker.js)
   */
  serviceWorkerSkipWaiting({ state, commit }) {
    if (isNil(state.SWRegistrationForNewContent)) return

    commit('setRefreshingApp', true)
    state.SWRegistrationForNewContent.waiting.postMessage('skipWaiting')
  },
}

const GETTERS = {
  modulesLoading: (state, getters, rootState, rootGetters) => {
    return {
      organizations: rootState.organizations.loading,
      contentTypes: rootState.contentTypes.loading,
      properties: rootState.properties.loading,
      collections: rootGetters['collections/loading'],
      templates: rootState.templates.loading,
      basket: rootState.basket.loading,
      notifications: rootState.notifications.loading,
      deployments: rootState.deployments.loading,
    }
  },
  startProgress: (state, getters) => {
    const modules = Object.values(getters.modulesLoading)
    const numModules = modules.length
    const numReady = modules.filter((e) => e === false).length
    return Math.round((100 * numReady) / numModules)
  },
  newContentAvailable: (state) => !isNil(state.SWRegistrationForNewContent),
}

export default {
  namespaced: true,
  state: STATE,
  mutations,
  actions,
  getters: GETTERS,
}
