// shared messages
import sharedDe from 'Shared/lang/de.json'
import sharedEn from 'Shared/lang/en.json'
import sharedFr from 'Shared/lang/fr.json'
import sharedIt from 'Shared/lang/it.json'

// module messages
import moduleArtwork from 'Modules/artwork/artwork-lang'
import moduleAuthentication from 'Modules/authentication/authentication-lang'
import moduleBasket from 'Modules/basket/basket-lang'
import moduleCollections from 'Modules/collections/collections-lang'
import moduleContents from 'Modules/contents/contents-lang'
import moduleContentTypes from 'Modules/content-types/content-types-lang'
import moduleDeployments from 'Modules/deployments/deployments-lang'
import moduleNotifications from 'Modules/notifications/notifications-lang'
import moduleOrders from 'Modules/orders/orders-lang'
import moduleOrganizations from 'Modules/organizations/organizations-lang'
import modulePictures from 'Modules/pictures/pictures-lang'
import moduleProperties from 'Modules/properties/properties-lang'
import modulePublications from 'Modules/publications/publications-lang'
import moduleReleases from 'Modules/releases/releases-lang'
import moduleSearch from 'Modules/search/search-lang'
import moduleTemplates from 'Modules/templates/templates-lang'
import moduleUsers from 'Modules/users/users-lang'

export default {
  de: {
    ...sharedDe,
    artwork: moduleArtwork.de,
    authentication: moduleAuthentication.de,
    basket: moduleBasket.de,
    collections: moduleCollections.de,
    contents: moduleContents.de,
    contentTypes: moduleContentTypes.de,
    deployments: moduleDeployments.de,
    notifications: moduleNotifications.de,
    orders: moduleOrders.de,
    organizations: moduleOrganizations.de,
    pictures: modulePictures.de,
    properties: moduleProperties.de,
    publications: modulePublications.de,
    releases: moduleReleases.de,
    search: moduleSearch.de,
    templates: moduleTemplates.de,
    users: moduleUsers.de,
  },
  en: {
    ...sharedEn,
    artwork: moduleArtwork.en,
    authentication: moduleAuthentication.en,
    basket: moduleBasket.en,
    collections: moduleCollections.en,
    contents: moduleContents.en,
    contentTypes: moduleContentTypes.en,
    deployments: moduleDeployments.en,
    notifications: moduleNotifications.en,
    orders: moduleOrders.en,
    organizations: moduleOrganizations.en,
    pictures: modulePictures.en,
    properties: moduleProperties.en,
    publications: modulePublications.en,
    releases: moduleReleases.en,
    search: moduleSearch.en,
    templates: moduleTemplates.en,
    users: moduleUsers.en,
  },
  fr: {
    ...sharedFr,
    artwork: moduleArtwork.en, // todo
    authentication: moduleAuthentication.fr,
    basket: moduleBasket.fr,
    collections: moduleCollections.fr,
    contents: moduleContents.fr,
    contentTypes: moduleContentTypes.en, // todo
    deployments: moduleDeployments.en, // todo
    notifications: moduleNotifications.en, // todo
    orders: moduleOrders.fr,
    organizations: moduleOrganizations.en, // todo
    pictures: modulePictures.en, // todo
    properties: moduleProperties.en, // todo
    publications: modulePublications.fr,
    releases: moduleReleases.fr,
    search: moduleSearch.en, // todo
    templates: moduleTemplates.en, // todo
    users: moduleUsers.fr,
  },
  it: {
    ...sharedIt,
  },
}
