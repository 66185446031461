import axios from 'axios'
import FormData from 'form-data'
import config from '@/config'

export default {
  _request: async (
    { dispatch },
    { url, method = 'get', headers = {}, data = null }
  ) => {
    await dispatch('authentication/getToken', null, {
      root: true,
    }).then((token) => (headers.authorization = `Bearer ${token}`))

    return axios.request({
      baseURL: config.api.artwork.url,
      method,
      url,
      headers,
      data,
    })
  },
  getAll: async ({ commit, state, dispatch }, refresh = false) => {
    if (state.loading) return state.loading
    let url = '/fonts'
    if (refresh) {
      url += '?forceRead=1'
    }
    const query = dispatch('_request', { url })
      .then((response) => {
        if (response.data.success && response.data.data) {
          commit('update', response.data.data)
        }
      })
      .finally(() => commit('loading', null))
    commit('loading', query)
    return query
  },
  delete: async ({ dispatch }, name) => {
    return dispatch('_request', {
      url: `/fonts/${name}`,
      method: 'delete',
    })
      .then((response) => {
        if (!response.data.success)
          throw Error(response.data.message || 'Delete not successful')
        return Promise.resolve()
      })
      .finally(() => dispatch('getAll'))
  },
  upload: async ({ dispatch }, { upload, options }) => {
    const form = new FormData()
    form.append('file', upload.file)
    form.append('isFixedWidth', options.isFixedWidth ? 1 : 0)
    form.append('isSymbolFont', options.isSymbolFont ? 1 : 0)
    form.append('isItalic', options.isItalic ? 1 : 0)
    return dispatch('_request', {
      url: '/fonts',
      method: 'post',
      data: form,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (!response.data.success)
          throw Error(
            response.data.error ||
              response.data.message ||
              'Upload not successful'
          )
        return Promise.resolve()
      })
      .finally(() => dispatch('getAll'))
  },
}
