// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
export default {
  ab: { nameIso: 'Abkhazian', nameNative: 'аҧсуа бызшәа, аҧсшәа' },
  aa: { nameIso: 'Afar', nameNative: 'Afaraf' },
  af: { nameIso: 'Afrikaans', nameNative: 'Afrikaans' },
  ak: { nameIso: 'Akan', nameNative: 'Akan' },
  sq: { nameIso: 'Albanian', nameNative: 'Shqip' },
  am: { nameIso: 'Amharic', nameNative: 'አማርኛ' },
  ar: { nameIso: 'Arabic', nameNative: 'العربية' },
  an: { nameIso: 'Aragonese', nameNative: 'aragonés' },
  hy: { nameIso: 'Armenian', nameNative: 'Հայերեն' },
  as: { nameIso: 'Assamese', nameNative: 'অসমীয়া' },
  av: { nameIso: 'Avaric', nameNative: 'авар мацӀ, магӀарул мацӀ' },
  ae: { nameIso: 'Avestan', nameNative: 'avesta' },
  ay: { nameIso: 'Aymara', nameNative: 'aymar aru' },
  az: { nameIso: 'Azerbaijani', nameNative: 'azərbaycan dili' },
  bm: { nameIso: 'Bambara', nameNative: 'bamanankan' },
  ba: { nameIso: 'Bashkir', nameNative: 'башҡорт теле' },
  eu: { nameIso: 'Basque', nameNative: 'euskara, euskera' },
  be: { nameIso: 'Belarusian', nameNative: 'беларуская мова' },
  bn: { nameIso: 'Bengali', nameNative: 'বাংলা' },
  bh: { nameIso: 'Bihari languages', nameNative: 'भोजपुरी' },
  bi: { nameIso: 'Bislama', nameNative: 'Bislama' },
  bs: { nameIso: 'Bosnian', nameNative: 'bosanski jezik' },
  br: { nameIso: 'Breton', nameNative: 'brezhoneg' },
  bg: { nameIso: 'Bulgarian', nameNative: 'български език' },
  my: { nameIso: 'Burmese', nameNative: 'ဗမာစာ' },
  ca: { nameIso: 'Catalan, Valencian', nameNative: 'català, valencià' },
  ch: { nameIso: 'Chamorro', nameNative: 'Chamoru' },
  ce: { nameIso: 'Chechen', nameNative: 'нохчийн мотт' },
  ny: { nameIso: 'Chichewa, Chewa, Nyanja', nameNative: 'chiCheŵa, chinyanja' },
  zh: { nameIso: 'Chinese', nameNative: '中文 (Zhōngwén), 汉语, 漢語' },
  cv: { nameIso: 'Chuvash', nameNative: 'чӑваш чӗлхи' },
  kw: { nameIso: 'Cornish', nameNative: 'Kernewek' },
  co: { nameIso: 'Corsican', nameNative: 'corsu, lingua corsa' },
  cr: { nameIso: 'Cree', nameNative: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  hr: { nameIso: 'Croatian', nameNative: 'hrvatski jezik' },
  cs: { nameIso: 'Czech', nameNative: 'čeština, český jazyk' },
  da: { nameIso: 'Danish', nameNative: 'dansk' },
  dv: { nameIso: 'Divehi, Dhivehi, Maldivian', nameNative: 'ދިވެހި' },
  nl: { nameIso: 'Dutch, Flemish', nameNative: 'Nederlands, Vlaams' },
  dz: { nameIso: 'Dzongkha', nameNative: 'རྫོང་ཁ' },
  en: { nameIso: 'English', nameNative: 'English' },
  eo: { nameIso: 'Esperanto', nameNative: 'Esperanto' },
  et: { nameIso: 'Estonian', nameNative: 'eesti, eesti keel' },
  ee: { nameIso: 'Ewe', nameNative: 'Eʋegbe' },
  fo: { nameIso: 'Faroese', nameNative: 'føroyskt' },
  fj: { nameIso: 'Fijian', nameNative: 'vosa Vakaviti' },
  fi: { nameIso: 'Finnish', nameNative: 'suomi, suomen kieli' },
  fr: { nameIso: 'French', nameNative: 'français, langue française' },
  ff: { nameIso: 'Fulah', nameNative: 'Fulfulde, Pulaar, Pular' },
  gl: { nameIso: 'Galician', nameNative: 'Galego' },
  ka: { nameIso: 'Georgian', nameNative: 'ქართული' },
  de: { nameIso: 'German', nameNative: 'Deutsch' },
  el: { nameIso: 'Greek, Modern (1453-)', nameNative: 'ελληνικά' },
  gn: { nameIso: 'Guarani', nameNative: "Avañe'ẽ" },
  gu: { nameIso: 'Gujarati', nameNative: 'ગુજરાતી' },
  ht: { nameIso: 'Haitian, Haitian Creole', nameNative: 'Kreyòl ayisyen' },
  ha: { nameIso: 'Hausa', nameNative: '(Hausa) هَوُسَ' },
  he: { nameIso: 'Hebrew', nameNative: 'עברית' },
  hz: { nameIso: 'Herero', nameNative: 'Otjiherero' },
  hi: { nameIso: 'Hindi', nameNative: 'हिन्दी, हिंदी' },
  ho: { nameIso: 'Hiri Motu', nameNative: 'Hiri Motu' },
  hu: { nameIso: 'Hungarian', nameNative: 'magyar' },
  ia: {
    nameIso: 'Interlingua (International Auxiliary Language Association)',
    nameNative: 'Interlingua',
  },
  id: { nameIso: 'Indonesian', nameNative: 'Bahasa Indonesia' },
  ie: {
    nameIso: 'Interlingue, Occidental',
    nameNative: '(originally:) Occidental, (after WWII:) Interlingue',
  },
  ga: { nameIso: 'Irish', nameNative: 'Gaeilge' },
  ig: { nameIso: 'Igbo', nameNative: 'Asụsụ Igbo' },
  ik: { nameIso: 'Inupiaq', nameNative: 'Iñupiaq, Iñupiatun' },
  io: { nameIso: 'Ido', nameNative: 'Ido' },
  is: { nameIso: 'Icelandic', nameNative: 'Íslenska' },
  it: { nameIso: 'Italian', nameNative: 'Italiano' },
  iu: { nameIso: 'Inuktitut', nameNative: 'ᐃᓄᒃᑎᑐᑦ' },
  ja: { nameIso: 'Japanese', nameNative: '日本語 (にほんご)' },
  jv: { nameIso: 'Javanese', nameNative: 'ꦧꦱꦗꦮ, Basa Jawa' },
  kl: {
    nameIso: 'Kalaallisut, Greenlandic',
    nameNative: 'kalaallisut, kalaallit oqaasii',
  },
  kn: { nameIso: 'Kannada', nameNative: 'ಕನ್ನಡ' },
  kr: { nameIso: 'Kanuri', nameNative: 'Kanuri' },
  ks: { nameIso: 'Kashmiri', nameNative: 'कश्मीरी, كشميري‎' },
  kk: { nameIso: 'Kazakh', nameNative: 'қазақ тілі' },
  km: { nameIso: 'Central Khmer', nameNative: 'ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ' },
  ki: { nameIso: 'Kikuyu, Gikuyu', nameNative: 'Gĩkũyũ' },
  rw: { nameIso: 'Kinyarwanda', nameNative: 'Ikinyarwanda' },
  ky: { nameIso: 'Kirghiz, Kyrgyz', nameNative: 'Кыргызча, Кыргыз тили' },
  kv: { nameIso: 'Komi', nameNative: 'коми кыв' },
  kg: { nameIso: 'Kongo', nameNative: 'Kikongo' },
  ko: { nameIso: 'Korean', nameNative: '한국어' },
  ku: { nameIso: 'Kurdish', nameNative: 'Kurdî, کوردی‎' },
  kj: { nameIso: 'Kuanyama, Kwanyama', nameNative: 'Kuanyama' },
  la: { nameIso: 'Latin', nameNative: 'latine, lingua latina' },
  lb: { nameIso: 'Luxembourgish, Letzeburgesch', nameNative: 'Lëtzebuergesch' },
  lg: { nameIso: 'Ganda', nameNative: 'Luganda' },
  li: { nameIso: 'Limburgan, Limburger, Limburgish', nameNative: 'Limburgs' },
  ln: { nameIso: 'Lingala', nameNative: 'Lingála' },
  lo: { nameIso: 'Lao', nameNative: 'ພາສາລາວ' },
  lt: { nameIso: 'Lithuanian', nameNative: 'lietuvių kalba' },
  lu: { nameIso: 'Luba-Katanga', nameNative: 'Kiluba' },
  lv: { nameIso: 'Latvian', nameNative: 'latviešu valoda' },
  gv: { nameIso: 'Manx', nameNative: 'Gaelg, Gailck' },
  mk: { nameIso: 'Macedonian', nameNative: 'македонски јазик' },
  mg: { nameIso: 'Malagasy', nameNative: 'fiteny malagasy' },
  ms: { nameIso: 'Malay', nameNative: 'Bahasa Melayu, بهاس ملايو‎' },
  ml: { nameIso: 'Malayalam', nameNative: 'മലയാളം' },
  mt: { nameIso: 'Maltese', nameNative: 'Malti' },
  mi: { nameIso: 'Maori', nameNative: 'te reo Māori' },
  mr: { nameIso: 'Marathi', nameNative: 'मराठी' },
  mh: { nameIso: 'Marshallese', nameNative: 'Kajin M̧ajeļ' },
  mn: { nameIso: 'Mongolian', nameNative: 'Монгол хэл' },
  na: { nameIso: 'Nauru', nameNative: 'Dorerin Naoero' },
  nv: { nameIso: 'Navajo, Navaho', nameNative: 'Diné bizaad' },
  nd: { nameIso: 'North Ndebele', nameNative: 'isiNdebele' },
  ne: { nameIso: 'Nepali', nameNative: 'नेपाली' },
  ng: { nameIso: 'Ndonga', nameNative: 'Owambo' },
  nb: { nameIso: 'Norwegian Bokmål', nameNative: 'Norsk Bokmål' },
  nn: { nameIso: 'Norwegian Nynorsk', nameNative: 'Norsk Nynorsk' },
  no: { nameIso: 'Norwegian', nameNative: 'Norsk' },
  ii: { nameIso: 'Sichuan Yi, Nuosu', nameNative: 'ꆈꌠ꒿ Nuosuhxop' },
  nr: { nameIso: 'South Ndebele', nameNative: 'isiNdebele' },
  oc: { nameIso: 'Occitan', nameNative: "occitan, lenga d'òc" },
  oj: { nameIso: 'Ojibwa', nameNative: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  cu: {
    nameIso:
      'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic',
    nameNative: 'ѩзыкъ словѣньскъ',
  },
  om: { nameIso: 'Oromo', nameNative: 'Afaan Oromoo' },
  or: { nameIso: 'Oriya', nameNative: 'ଓଡ଼ିଆ' },
  os: { nameIso: 'Ossetian, Ossetic', nameNative: 'ирон æвзаг' },
  pa: { nameIso: 'Punjabi, Panjabi', nameNative: 'ਪੰਜਾਬੀ, پنجابی‎' },
  pi: { nameIso: 'Pali', nameNative: 'पालि, पाळि' },
  fa: { nameIso: 'Persian', nameNative: 'فارسی' },
  pl: { nameIso: 'Polish', nameNative: 'język polski, polszczyzna' },
  ps: { nameIso: 'Pashto, Pushto', nameNative: 'پښتو' },
  pt: { nameIso: 'Portuguese', nameNative: 'Português' },
  qu: { nameIso: 'Quechua', nameNative: 'Runa Simi, Kichwa' },
  rm: { nameIso: 'Romansh', nameNative: 'Rumantsch Grischun' },
  rn: { nameIso: 'Rundi', nameNative: 'Ikirundi' },
  ro: { nameIso: 'Romanian, Moldavian, Moldovan', nameNative: 'Română' },
  ru: { nameIso: 'Russian', nameNative: 'русский' },
  sa: { nameIso: 'Sanskrit', nameNative: 'संस्कृतम्' },
  sc: { nameIso: 'Sardinian', nameNative: 'sardu' },
  sd: { nameIso: 'Sindhi', nameNative: 'सिन्धी, سنڌي، سندھی‎' },
  se: { nameIso: 'Northern Sami', nameNative: 'Davvisámegiella' },
  sm: { nameIso: 'Samoan', nameNative: "gagana fa'a Samoa" },
  sg: { nameIso: 'Sango', nameNative: 'yângâ tî sängö' },
  sr: { nameIso: 'Serbian', nameNative: 'српски језик' },
  gd: { nameIso: 'Gaelic, Scottish Gaelic', nameNative: 'Gàidhlig' },
  sn: { nameIso: 'Shona', nameNative: 'chiShona' },
  si: { nameIso: 'Sinhala, Sinhalese', nameNative: 'සිංහල' },
  sk: { nameIso: 'Slovak', nameNative: 'Slovenčina, Slovenský Jazyk' },
  sl: { nameIso: 'Slovenian', nameNative: 'Slovenski Jezik, Slovenščina' },
  so: { nameIso: 'Somali', nameNative: 'Soomaaliga, af Soomaali' },
  st: { nameIso: 'Southern Sotho', nameNative: 'Sesotho' },
  es: { nameIso: 'Spanish, Castilian', nameNative: 'Español' },
  su: { nameIso: 'Sundanese', nameNative: 'Basa Sunda' },
  sw: { nameIso: 'Swahili', nameNative: 'Kiswahili' },
  ss: { nameIso: 'Swati', nameNative: 'SiSwati' },
  sv: { nameIso: 'Swedish', nameNative: 'Svenska' },
  ta: { nameIso: 'Tamil', nameNative: 'தமிழ்' },
  te: { nameIso: 'Telugu', nameNative: 'తెలుగు' },
  tg: { nameIso: 'Tajik', nameNative: 'тоҷикӣ, toçikī, تاجیکی‎' },
  th: { nameIso: 'Thai', nameNative: 'ไทย' },
  ti: { nameIso: 'Tigrinya', nameNative: 'ትግርኛ' },
  bo: { nameIso: 'Tibetan', nameNative: 'བོད་ཡིག' },
  tk: { nameIso: 'Turkmen', nameNative: 'Türkmen, Түркмен' },
  tl: { nameIso: 'Tagalog', nameNative: 'Wikang Tagalog' },
  tn: { nameIso: 'Tswana', nameNative: 'Setswana' },
  to: { nameIso: 'Tonga (Tonga Islands)', nameNative: 'Faka Tonga' },
  tr: { nameIso: 'Turkish', nameNative: 'Türkçe' },
  ts: { nameIso: 'Tsonga', nameNative: 'Xitsonga' },
  tt: { nameIso: 'Tatar', nameNative: 'татар теле, tatar tele' },
  tw: { nameIso: 'Twi', nameNative: 'Twi' },
  ty: { nameIso: 'Tahitian', nameNative: 'Reo Tahiti' },
  ug: { nameIso: 'Uighur, Uyghur', nameNative: 'ئۇيغۇرچە‎, Uyghurche' },
  uk: { nameIso: 'Ukrainian', nameNative: 'Українська' },
  ur: { nameIso: 'Urdu', nameNative: 'اردو' },
  uz: { nameIso: 'Uzbek', nameNative: 'Oʻzbek, Ўзбек, أۇزبېك‎' },
  ve: { nameIso: 'Venda', nameNative: 'Tshivenḓa' },
  vi: { nameIso: 'Vietnamese', nameNative: 'Tiếng Việt' },
  vo: { nameIso: 'Volapük', nameNative: 'Volapük' },
  wa: { nameIso: 'Walloon', nameNative: 'Walon' },
  cy: { nameIso: 'Welsh', nameNative: 'Cymraeg' },
  wo: { nameIso: 'Wolof', nameNative: 'Wollof' },
  fy: { nameIso: 'Western Frisian', nameNative: 'Frysk' },
  xh: { nameIso: 'Xhosa', nameNative: 'isiXhosa' },
  yi: { nameIso: 'Yiddish', nameNative: 'ייִדיש' },
  yo: { nameIso: 'Yoruba', nameNative: 'Yorùbá' },
  za: { nameIso: 'Zhuang, Chuang', nameNative: 'Saɯ cueŋƅ, Saw cuengh' },
  zu: { nameIso: 'Zulu', nameNative: 'isiZulu' },
}
