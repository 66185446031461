var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mf-table",class:{ 'is-loading': _vm.loading }},[_c('RecycleScroller',{ref:"scroller",staticClass:"table tw-h-full tw-w-full",attrs:{"key-field":"id","grid-items":1,"items":_vm.newData,"item-size":32,"item-secondary-size":_vm.rowWidth},nativeOn:{"&scroll":function($event){return _vm.$emit('scroll', $event)}},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('div',{ref:"head",staticClass:"thead"},[_c('div',{staticClass:"tr"},_vm._l((_vm.visibleColumns),function(column,index){return _c('div',{key:column.customKey || column.label,class:{
              th: true,
              'is-current-sort': _vm.currentSortColumn === column,
              'is-sortable': column.sortable,
              'is-sticky': !!column.sticky,
            },style:({
              left: _vm.nullOrLength(column.sticky),
              width:
                _vm.nullOrLength(column.width) || _vm.nullOrLength(column.minWidth),
              flexGrow: column.minWidth,
            }),on:{"click":function($event){$event.stopPropagation();return _vm.sort(column, null, $event)}}},[_c('div',{staticClass:"th-wrap",class:{
                'is-numeric': column.numeric,
                'is-centered': column.centered,
              }},[(column.$scopedSlots && column.$scopedSlots.header)?[_c('MfSlotComponent',{attrs:{"component":column,"scoped":true,"name":"header","tag":"span","props":{ column, index }}})]:(_vm.$scopedSlots.header)?[_vm._t("header",null,{"column":column,"index":index})]:_c('span',{staticClass:"overflow-hidden text-overflow-ellipsis text-nowrap"},[_vm._v(_vm._s(column.label))]),(column.sortable)?_c('i',{class:[
                  'icon',
                  _vm.sortIcon,
                  { 'is-desc': _vm.currentSortColumn === column && !_vm.isAsc },
                ]}):_vm._e()],2)])}),0)])]},proxy:true},{key:"default",fn:function({ item, index }){return [_c('div',{class:[
          'tr',
          { 'is-selected': item === _vm.selected },
          _vm.rowClass(item, index),
        ],on:{"click":function($event){return _vm.selectRow(item)},"dblclick":function($event){return _vm.$emit('dblclick', item)}}},[(_vm.$scopedSlots.default)?_vm._t("default",null,{"row":item,"index":index}):_vm._l((_vm.newColumns),function(column){return _c('mf-column',_vm._b({key:column.customKey || column.label,attrs:{"internal":""}},'mf-column',column,false),[_vm._v(" "+_vm._s(_vm.getValueByPath(item, column.prop))+" ")])})],2)]}},(!_vm.newData.length)?{key:"after",fn:function(){return [_c('div',{staticClass:"tr is-empty"},[_c('div',{staticClass:"td w-100 text-center color-text-secondary"},[_vm._t("empty",function(){return [_c('span',[_vm._v(_vm._s(_vm.emptyText))])]})],2)])]},proxy:true}:null],null,true)}),_c('div',{staticClass:"loader"},[_c('div',{staticClass:"loader-inner"},[_c('i',{staticClass:"el-icon-loading"}),_c('br'),_vm._v(" "+_vm._s(_vm.loadingText)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }