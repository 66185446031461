export default {
  filtered: (state) => {
    if (!state.filter) return state.all
    const filter = state.filter.toLowerCase()
    return state.all.filter(
      (font) =>
        font.filename.toLowerCase().includes(filter) ||
        font.name.toLowerCase().includes(filter)
    )
  },
  isLoading: (state) => !!state.loading,
}
