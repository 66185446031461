import axios from 'axios'
import config from '@/config'
import { get } from 'lodash'

export default {
  invite: async ({ dispatch }, { id, emails }) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'OrganizationMembersInvite',
        variables: { id, emails },
        query: `
mutation OrganizationMembersInvite($id: ID!, $emails: String!) {
  organizationMembersInvite(id: $id, emails: $emails)
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.organizationMembersInvite', null)
  },

  roleChange: async ({ dispatch }, { organization, role, user }) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'OrganizationMemberChangeRole',
        variables: { id: organization, userId: user, role },
        query: `
mutation OrganizationMemberChangeRole($id: ID!, $userId: ID!, $role: Role!) {
  organizationMemberChangeRole(id: $id, userId: $userId, role: $role)
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.organizationMemberChangeRole', null)
  },

  remove: async ({ dispatch }, { id, userId }) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'OrganizationMemberRemove',
        variables: { id, userId },
        query: `
mutation OrganizationMemberRemove($id: ID!, $userId: ID!) {
  organizationMemberRemove(id: $id, userId: $userId)
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.organizationRemoveRemove', null)
  },
}
