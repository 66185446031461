import VueI18n from 'vue-i18n'
import Vue from 'vue'
import {
  DEFAULT_LANGUAGE,
  FALLBACK_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from 'Shared/config/language'
import messages from 'App/lang'

Vue.use(VueI18n)

export const i18nInstance = new VueI18n({
  locale: DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages,
})

export default i18nInstance

export const i18nVuexPlugin = (store) => {
  store.subscribe((mutation) => {
    if (mutation.type === 'app/language') {
      const lang = mutation.payload
      if (lang && Object.keys(SUPPORTED_LANGUAGES).includes(lang)) {
        i18nInstance.locale = lang
      }
    }
  })
}
