<template>
  <div class="mf-table" :class="{ 'is-loading': loading }">
    <RecycleScroller
      ref="scroller"
      class="table tw-h-full tw-w-full"
      key-field="id"
      :grid-items="1"
      :items="newData"
      :item-size="32"
      :item-secondary-size="rowWidth"
      @scroll.native.passive="$emit('scroll', $event)"
    >
      <template #before>
        <div ref="head" class="thead">
          <div class="tr">
            <div
              v-for="(column, index) in visibleColumns"
              :key="column.customKey || column.label"
              :class="{
                th: true,
                'is-current-sort': currentSortColumn === column,
                'is-sortable': column.sortable,
                'is-sticky': !!column.sticky,
              }"
              :style="{
                left: nullOrLength(column.sticky),
                width:
                  nullOrLength(column.width) || nullOrLength(column.minWidth),
                flexGrow: column.minWidth,
              }"
              @click.stop="sort(column, null, $event)"
            >
              <div
                class="th-wrap"
                :class="{
                  'is-numeric': column.numeric,
                  'is-centered': column.centered,
                }"
              >
                <template
                  v-if="column.$scopedSlots && column.$scopedSlots.header"
                >
                  <MfSlotComponent
                    :component="column"
                    :scoped="true"
                    name="header"
                    tag="span"
                    :props="{ column, index }"
                  />
                </template>
                <template v-else-if="$scopedSlots.header">
                  <slot name="header" :column="column" :index="index" />
                </template>
                <span
                  v-else
                  class="overflow-hidden text-overflow-ellipsis text-nowrap"
                  >{{ column.label }}</span
                >

                <i
                  v-if="column.sortable"
                  :class="[
                    'icon',
                    sortIcon,
                    { 'is-desc': currentSortColumn === column && !isAsc },
                  ]"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #default="{ item, index }">
        <div
          :class="[
            'tr',
            { 'is-selected': item === selected },
            rowClass(item, index),
          ]"
          @click="selectRow(item)"
          @dblclick="$emit('dblclick', item)"
        >
          <slot v-if="$scopedSlots.default" :row="item" :index="index" />
          <template v-else>
            <mf-column
              v-for="column in newColumns"
              :key="column.customKey || column.label"
              v-bind="column"
              internal
            >
              {{ getValueByPath(item, column.prop) }}
            </mf-column>
          </template>
        </div>
      </template>

      <template v-if="!newData.length" #after>
        <div class="tr is-empty">
          <div class="td w-100 text-center color-text-secondary">
            <slot name="empty">
              <span>{{ emptyText }}</span>
            </slot>
          </div>
        </div>
      </template>
    </RecycleScroller>
    <div class="loader">
      <div class="loader-inner">
        <i class="el-icon-loading" /><br />
        {{ loadingText }}
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
import MfColumn from './MfColumn.vue'
import MfSlotComponent from './MfSlotComponent'

export default {
  name: 'MfTable',

  components: {
    MfColumn,
    MfSlotComponent,
  },

  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    defaultSort: [String, Array],
    defaultSortDirection: {
      type: String,
      default: 'asc',
    },
    sortIcon: {
      type: String,
      default: 'el-icon-bottom',
    },
    emptyText: {
      type: String,
      default: 'no data',
    },
    loading: Boolean,
    loadingText: String,
    pageMode: Boolean,
    rowClass: {
      type: Function,
      default() {},
    },
    rowKey: String,
    selected: Object,
  },

  data() {
    return {
      currentSortColumn: {},
      firstTimeSort: true,
      getValueByPath: get,
      isAsc: true,
      isMfTable: true,
      newData: this.data,
      newColumns: [...this.columns],
      retryScrollTo: null,
      rowWidth: 32,
    }
  },

  computed: {
    visibleColumns() {
      if (!this.newColumns) return this.newColumns
      return this.newColumns.filter((column) => {
        return column.visible || column.visible === undefined
      })
    },
    nullOrLength() {
      return (value) => {
        if (value === undefined) return null
        return Number.isNaN(value) ? value : `${value}px`
      }
    },
  },

  watch: {
    data(value) {
      this.newData = value
      if (this.retryScrollTo) {
        this.scrollTo(this.retryScrollTo)
      }
    },
    columns(value) {
      this.newColumns = [...value]
    },
    newColumns() {
      this.checkSort()
      this.updateRowWidth()
    },
  },

  beforeDestroy() {
    this.newData = []
    this.newColumns = []
    window.removeEventListener('resize', this.updateRowWidth)
  },

  mounted() {
    this.$nextTick(() => this.updateRowWidth())
    window.addEventListener('resize', this.updateRowWidth, {
      passive: true,
    })
  },

  methods: {
    sort(column, updatingData = false, event = null) {
      if (!column || !column.sortable) return
      if (!updatingData) {
        this.isAsc =
          column === this.currentSortColumn
            ? !this.isAsc
            : this.defaultSortDirection.toLowerCase() !== 'desc'
      }
      if (!this.firstTimeSort) {
        this.$emit('sort', column.prop, this.isAsc ? 'asc' : 'desc', event)
      }
      this.currentSortColumn = column
    },
    checkSort() {
      if (this.newColumns.length && this.firstTimeSort) {
        this.initSort()
        this.firstTimeSort = false
      } else if (this.newColumns.length) {
        if (this.currentSortColumn.prop) {
          for (let i = 0; i < this.newColumns.length; i += 1) {
            if (this.newColumns[i].prop === this.currentSortColumn.prop) {
              this.currentSortColumn = this.newColumns[i]
              break
            }
          }
        }
      }
    },
    initSort() {
      if (!this.defaultSort) return
      let sortField = ''
      let sortDirection = this.defaultSortDirection
      if (Array.isArray(this.defaultSort)) {
        // eslint-disable-next-line prefer-destructuring
        sortField = this.defaultSort[0]
        if (this.defaultSort[1]) {
          // eslint-disable-next-line prefer-destructuring
          sortDirection = this.defaultSort[1]
        }
      } else {
        sortField = this.defaultSort
      }
      this.newColumns.forEach((column) => {
        if (column.prop === sortField) {
          this.isAsc = sortDirection.toLowerCase() !== 'desc'
          this.sort(column, true)
        }
      })
    },
    selectRow(row) {
      this.$emit('click', row)
      if (this.selected === row) return
      // Emit new and old row
      this.$emit('select', row, this.selected)
      // Emit new row to update user variable
      this.$emit('update:selected', row)
    },
    scroll({ left, top }) {
      this.$refs.scroller.$el.scroll({ left, top, behavior: 'smooth' })
    },
    scrollTo(itemId) {
      const index = this.newData.findIndex((item) => item.id === itemId)
      if (index !== -1) {
        this.$refs.scroller.scrollToItem(index)
        this.selectRow(this.newData[index])
        this.retryScrollTo = null
      } else {
        this.retryScrollTo = itemId
        setTimeout(() => (this.retryScrollTo = null), 3000)
      }
    },
    isVisible(itemId) {
      const index = this.newData.findIndex((item) => item.id === itemId)
      if (index === -1) return null
      return (
        index >= this.$refs.scroller.$_startIndex &&
        index <= this.$refs.scroller.$_endIndex
      )
    },
    updateRowWidth() {
      this.rowWidth = (this.$refs.head && this.$refs.head.clientWidth) || 32
    },
  },
}
</script>
