import axios from 'axios'
import config from '@/config'
import { get } from 'lodash'

export default {
  init: ({ commit }) => {
    commit('dialogOpen', false)
    commit('userId', null)
    commit('loading', false)
    commit('error', null)
    commit('result', null)
  },
  check: async ({ state, commit, dispatch }, id) => {
    if (state.loading) throw Error('check in progress')
    commit('userId', id)
    commit('loading', true)
    commit('error', null)
    commit('result', null)
    commit('dialogOpen', true)
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'UserCheckClaims',
        variables: { id },
        query: `query UserCheckClaims($id: ID!) {
  userCheckClaims(id: $id) {
    success
    message
    errors
  } 
}`,
      },
    })
      .then((result) => {
        if (result.data.errors && result.data.errors[0]) {
          throw Error(result.data.errors[0].message)
        }
        commit('result', get(result, 'data.data.userCheckClaims', null))
      })
      .catch((error) => {
        commit('error', error.message)
      })
      .finally(() => commit('loading', false))
  },
  fixClaims: async ({ state, commit, dispatch }) => {
    if (state.loading) throw Error('check in progress')
    if (!state.userId) throw Error('userId is not set')
    commit('loading', true)
    commit('error', null)
    commit('result', null)
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'UserFixClaims',
        variables: { id: state.userId },
        query: `mutation UserFixClaims($id: ID!) {
  userFixClaims(id: $id) {
    success
    message
    errors
  } 
}`,
      },
    })
      .then((result) => {
        if (result.data.errors && result.data.errors[0]) {
          throw Error(result.data.errors[0].message)
        }
        commit('result', get(result, 'data.data.userFixClaims', null))
      })
      .catch((error) => {
        commit('error', error.message)
      })
      .finally(() => commit('loading', false))
  },
}
