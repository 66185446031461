import {
  forIn,
  get,
  groupBy,
  isEqual,
  isNaN,
  cloneDeep,
  truncate,
} from 'lodash'
import i18n from '@/plugins/i18n'

const listSorted = (list, orderProp, orderDir = 'asc') => {
  if (!orderProp) return list
  let { locale } = i18n
  const reTrans = /\.translation\.([a-z]{2})$/
  if (reTrans.test(orderProp)) {
    ;[, locale] = reTrans.exec(orderProp)
  }
  const localeCompareOptions = {
    numeric: true,
    sensitivity: 'base',
  }
  if (orderProp !== 'data.category_tree.value') {
    const reverse = orderDir === 'desc' ? -1 : 1
    return list.sort((a, b) => {
      return (
        reverse *
        get(a, orderProp, '').localeCompare(
          get(b, orderProp, ''),
          locale,
          localeCompareOptions
        )
      )
    })
  }
  const groups = groupBy(
    list,
    (el) => get(el, 'data.category_tree.value') || '-1'
  )
  const byLabel = (a, b) =>
    get(a, 'label.de', '').localeCompare(
      get(b, 'label.de', ''),
      locale,
      localeCompareOptions
    )
  const sorted = groups['-1'].sort(byLabel)
  delete groups['-1']
  for (let i = 0; i < 10; i += 1) {
    if (!Object.keys(groups).length) break
    forIn(groups, (children, parentId) => {
      const parentIndex = sorted.findIndex((el) => el.id === parentId)
      children = children.sort(byLabel)
      if (parentIndex !== -1) {
        sorted.splice(parentIndex + 1, 0, ...children)
        delete groups[parentId]
      }
    })
  }
  return sorted
}

export default {
  filteredByType: (state) => {
    const filtered = []
    Object.entries(state.all).forEach(([id, data]) => {
      if (data.type === state.filterType) {
        filtered.push({ id, ...data })
      }
    })
    return filtered
  },
  get: (state) => (id) => (state.all && state.all[id]) || null,
  getInherited: (state, getters) => (id) => {
    const item = getters.get(id)
    if (!item || !item.parentId) return item
    const parent = getters.get(item.parentId)
    if (!parent) return null
    const inherited = cloneDeep(item)
    Object.setPrototypeOf(inherited.data, parent.data)
    if (!parent.active) {
      inherited.active = false
    } else if (!{}.hasOwnProperty.call(inherited, 'active')) {
      inherited.active = parent.active
    }
    return inherited
  },
  getInheritedProp: (state, getters) => (id, path) => {
    const content = getters.get(id)
    if (!content) return undefined
    const contentProp = get(content, path, undefined)
    if (contentProp !== undefined) return contentProp
    if (content.parentId) {
      return getters.getInheritedProp(content.parentId, path)
    }
    return undefined
  },
  getCategoryTreeLevel:
    (state, getters) =>
    (id, offset = 0) => {
      const parentId = getters.getInheritedProp(id, 'data.category_tree.value')
      if (!parentId) return offset
      return getters.getCategoryTreeLevel(parentId, offset + 1)
    },
  getCollections: (state, getters) => (id) => {
    const item = getters.get(id)
    if (!item || !item.collections) return []
    return Object.keys(item.collections)
  },
  getLabel: (state, getters) => (id) => {
    const item = getters.get(id)
    if (!item || !item.label) return id
    return item.label[i18n.locale] || id
  },
  pathToPropLangIdx: () => (path) => {
    let propId
    let lang
    let index
    const re = /^data\.(\w+)\.(value|translation\.([a-z]{2}))(\.(\d+))?$/
    if (re.test(path)) {
      ;[, propId, , lang, , index] = re.exec(path)
    }
    if (index) {
      index *= 1
    }
    return { propId, lang, index }
  },
  propLangIdxToPath: () => (propId, lang, index, format) => {
    let path = `data.${propId}.`
    path += lang ? `translation.${lang}` : 'value'
    if (index >= 0) path += `.${index}`
    return format ? `${path}@${format}` : path
  },
  getValue: (state, getters, rootState, rootGetters) => (id, fxPath) => {
    if (!id || !fxPath) return null
    const content = getters.get(id)
    if (!content || !content.data) return null
    const [path] = fxPath.split('@', 2)
    const value = get(content, path, null)
    if (value instanceof Array) {
      const { propId } = getters.pathToPropLangIdx(path)
      const property = rootGetters['properties/get'](propId)
      if (property && property.type === 'period')
        return [...value].sort((a, b) => a - b)
    }
    return value
  },
  getValueFromData:
    (state, getters, rootState, rootGetters) =>
    (data, propertyId, lang = null, index = null) => {
      if (!data) return null
      const property = rootGetters['properties/get'](propertyId)
      if (!property) return null
      if (!data[propertyId]) return null
      const contentProperty = data[propertyId]
      if (property.translatable) {
        if (!lang) return null
        if (!contentProperty.translation || !contentProperty.translation[lang])
          return null
      }
      const value = property.translatable
        ? contentProperty.translation[lang]
        : contentProperty.value
      if (property.multiple) {
        if (index === null) {
          return value || []
        }
        return value[index] || null
      }
      return value || null
    },
  getValuePath: (state, getters, rootState, rootGetters) => (colStr, orgId) => {
    const { propId, lang, index } = getters.colToPropLangIdx(colStr)
    const property = rootGetters['properties/get'](propId, orgId)
    if (!property) return null
    let path = property.translatable
      ? `data.${propId}.translation.${lang}`
      : `data.${propId}.value`
    if (index !== null) path += `.${index}`
    return path
  },
  getMinWidth: (state, getters, rootState, rootGetters) => (colStr) => {
    const { propId } = getters.colToPropLangIdx(colStr)
    if (propId.startsWith('name')) return 300
    const property = rootGetters['properties/get'](propId)
    if (property && property.type === 'combination') return 500
    if (property && ['text', 'image'].includes(property.type)) return 300
    return 150
  },
  colToPropLangIdx:
    () =>
    (colStr = '') => {
      const parts = colStr.split('.')
      const propId =
        parts.length > 3 ? parts.slice(0, parts.length - 1).join('.') : parts[0]
      if (parts.length > 3) parts.splice(0, parts.length - 2, propId)
      const lang =
        parts[1] && isNaN(parseInt(parts[1], 10)) ? parts[1] : i18n.locale
      const indexSrc = parts.length === 3 ? parts[2] : parts[1]
      const index =
        indexSrc && !isNaN(parseInt(indexSrc, 10))
          ? parseInt(indexSrc, 10)
          : null
      return { propId, lang, index }
    },
  listCollectionId: (state) => {
    const constraints = state.listConstraints
    // check if list is filtered by type
    if (constraints.find(([prop]) => prop === 'type')) return null
    // check if list is filtered by collection
    const filter = constraints.find(([prop]) => prop.startsWith('collections.'))
    if (!filter) return null
    return filter[0].substr(12)
  },
  listScrollPosition: (state, getters) => {
    const collectionId = getters.listCollectionId
    return (collectionId && state.listScrollPosition[collectionId]) || null
  },
  listColumns: (state, getters, rootState, rootGetters) => {
    if (state.columns.length) return state.columns
    const type = rootGetters['contentTypes/get'](state.filterType)
    return type && type.columns ? type.columns : ['name']
  },
  generateLabel:
    (state, getters, rootState, rootGetters) => (contentType, data) => {
      if (!(contentType instanceof Object)) {
        contentType = rootGetters['contentTypes/get'](contentType)
      }
      const template =
        contentType instanceof Object && contentType.labelDefault
          ? contentType.labelDefault
          : '{name}'

      const getVariable = (path) => {
        const { propId, lang, index } = getters.colToPropLangIdx(path)
        let variable = getters.getValueFromData(data, propId, lang, index)
        if (!variable) return ''
        if (variable instanceof Date) variable = variable.toISOString()
        else variable = String(variable).replaceAll('\\n', ' ')
        return variable
      }

      const replaceVars = (match, varPath, alt, altPath) => {
        const var1 = getVariable(varPath)
        if (!var1 && altPath) {
          return getVariable(altPath)
        }
        return var1
      }
      const parsed = template
        .replace(/{([\w.]+)(\s*\|\|\s*([\w.]+))?}/g, replaceVars)
        .trim()

      return parsed.replace(/\(\)|''|^- | -$/gi, '').replace(/\s+/gi, ' ')
    },
  labelUpToDate: (state, getters) => (content) => {
    const current = content && content.label && content.label[i18n.locale]
    if (!current) return false
    const generated = getters.generateLabel(content.type, content.data)
    if (generated === current) return true
    // copied release's (2) suffix may have been written to db...
    return generated === current.replace(/ \(\d{1,3}\)$/, '')
  },
  listConstraints: (state, getters, rootState, rootGetters) => {
    const constraints = []
    if (state.filterType) constraints.push(['type', '==', state.filterType])
    if (state.filterOwner === 'public') {
      constraints.push(['public', '==', true])
      constraints.push(['active', '==', true])
    } else if (state.filterOwner === 'organization') {
      constraints.push([
        'owner',
        '==',
        rootGetters['authentication/organizationId'],
      ])
    } else if (state.filterOwner === 'supplier') {
      constraints.push([
        'supplier',
        '==',
        rootGetters['authentication/organizationId'],
      ])
    }
    if (state.filterCollection)
      constraints.push([`collections.${state.filterCollection}`, '==', true])
    return constraints
  },
  hasListenerListConstraints: (state, getters) => {
    if (!state.listenerAll) return false
    return isEqual(state.listConstraints, getters.listConstraints)
  },
  list: (state, getters) => {
    const list = state.list.map(getters.get).filter((item) => {
      if (!state.filterShowVariants && item.parentId) return false
      if (!state.filterShowInactive && !item.active) return false
      return true
    })
    const { listOrderProp, listOrderDir } = state
    return listSorted(list, listOrderProp, listOrderDir)
  },
  listCollection: (state, getters) => (colId) => {
    if (!colId || !state.listCollection[colId]) {
      return []
    }
    return listSorted(
      state.listCollection[colId].list.map(getters.get),
      `label.${i18n.locale}`
    )
  },
  listCollectionCategoryTree: (state, getters) => (colId) => {
    const list = getters
      .listCollection(colId)
      .sort((a, b) =>
        get(a, 'label.de', '').localeCompare(
          get(b, 'label.de', ''),
          i18n.locale,
          { numeric: true, sensitivity: 'base' }
        )
      )
    const groups = groupBy(
      list,
      (el) => get(el, 'data.category_tree.value') || '-1'
    )
    const tree = groups['-1']
    delete groups['-1']

    const mapper = (el) => {
      const node = {
        value: el.id,
        label: (el.label && el.label[i18n.locale]) || el.id,
        leaf: !!groups[el.id],
      }
      if (groups[el.id]) node.children = groups[el.id].map(mapper)
      return node
    }
    return tree.map(mapper)
  },

  listTotal: (state) => state.list.length,
  columnLabel: (state, getters, rootState, rootGetters) => (col) => {
    const { propId, lang } = getters.colToPropLangIdx(col)
    return rootGetters['properties/getLabel']({
      id: propId,
      lang: i18n.locale,
      propLang: lang,
    })
  },

  titleRaw:
    (state, getters) =>
    (id, short = false) => {
      const item = id ? getters.get(id) : state.editItem
      let name = (item && item.label && item.label[i18n.locale]) || null
      if (name && short) name = truncate(name)
      if (id) {
        return ['contents.title.show', { content: name || id }]
      }
      return state.editIsNew
        ? ['contents.title.new']
        : ['contents.title.edit', { content: name || item.id }]
    },

  title:
    (state, getters) =>
    (id, short = false) => {
      const [name, params] = getters.titleRaw(id)
      const title = i18n.t(name, params).toString()
      return short ? truncate(title) : title
    },

  groups: (state, getters, rootState, rootGetters) => (id) => {
    const item = id ? getters.get(id) : state.editItem
    const typeId = (item && item.type) || state.filterType
    if (!typeId) return []
    const type = rootGetters['contentTypes/get'](typeId)
    return (type && type.groups) || []
  },

  languages: (state, getters, rootState, rootGetters) => (latin, orgId) => {
    let list = rootGetters['properties/languages'](orgId)
    if (!latin) {
      list = list.filter((l) => l !== 'la')
    }
    const filter = state.filterLanguages
    if (filter && filter.length) {
      list = list.filter((l) => filter.includes(l))
    }
    return list
  },

  isInherited: (state, getters) => (contentId, propertyId) => {
    const content = getters.getInherited(contentId)
    if (!content || !content.data || !content.data[propertyId]) return null
    if (!content.parentId) return false
    return !{}.hasOwnProperty.call(content.data, propertyId)
  },
}
