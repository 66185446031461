import { defaults, pick } from 'lodash'
import i18n from '@/plugins/i18n'
import groups from '../constants/property-groups'
import { languageCodesDefault } from '../constants/property-languages'

export default {
  /* props default sort by group and order */
  propsSorted: (state) =>
    Object.values(state.all).sort((a, b) => {
      const aGrpIdx = groups.findIndex((g) => g === a.group)
      const bGrpIdx = groups.findIndex((g) => g === b.group)
      if (aGrpIdx !== bGrpIdx) return aGrpIdx - bGrpIdx
      return a.order - b.order
    }),

  filtered: (state, getters) => {
    const search = (state.search || '').toLowerCase()
    if (!search) return getters.propsSorted
    return getters.propsSorted.filter(
      (el) =>
        el.id.toLowerCase().includes(search) ||
        (el.label.en && el.label.en.toLowerCase().includes(search)) ||
        (el.label.de && el.label.de.toLowerCase().includes(search))
    )
  },

  propsByGroup: (state) => (group) => {
    if (!(state.all instanceof Object)) return []
    return Object.values(state.all)
      .filter((el) => el.group === group)
      .sort((a, b) => a.order - b.order)
  },

  propsGrouped: (state, getters) => {
    if (!(state.all instanceof Object)) return []
    return groups.map((groupId) => ({
      id: groupId,
      label: groupId,
      options: getters.propsByGroup(groupId),
    }))
  },

  byType: (state) => (type) => {
    return Object.values(state.all).filter((prop) => prop.type === type)
  },

  /* get all property ids */
  propertyIds: (state) => {
    if (state.all === null) return null
    if (!(state.all instanceof Object)) return []
    return Object.keys(state.all)
  },

  getRaw: (state) => (id) => {
    return (state.all && state.all[id]) || null
  },
  get: (state, getters, rootState, rootGetters) => (propId, customId) => {
    const prop = getters.getRaw(propId)
    if (!prop) return prop
    const orgId = customId || rootGetters['authentication/organizationId']
    const org = rootGetters['organizations/get'](orgId)
    if (!org || !org.properties || !org.properties[propId]) return prop
    const customProp = pick(org.properties[propId], [
      'individual',
      'label',
      'translatable',
      'values',
    ])
    return defaults(customProp, prop)
  },
  getLabel:
    (state, getters) =>
    ({ id, lang = i18n.locale, propLang = null, customId = null }) => {
      let name = id
      const prop = getters.get(id, customId)
      if (prop && lang && prop.label && prop.label[lang]) {
        name = prop.label[lang]
      }
      if (!propLang) propLang = lang
      return prop && prop.translatable && propLang !== lang
        ? `${name} (${propLang})`
        : name
    },
  getHelpText: (state, getters) => (id) => {
    const property = getters.getRaw(id)
    return (
      (property && property.helpText && property.helpText[i18n.locale]) || ''
    )
  },
  getInfoText: (state, getters) => (id) => {
    const property = getters.getRaw(id)
    return (
      (property && property.infoText && property.infoText[i18n.locale]) || ''
    )
  },

  languages: (state, getters, rootState, rootGetters) => (orgId) => {
    if (!orgId) orgId = rootGetters['authentication/organizationId']
    const org = rootGetters['organizations/get'](orgId)
    const list = [...languageCodesDefault]
    if (org && org.languages) {
      org.languages.forEach((lang) => {
        if (!list.includes(lang)) {
          list.push(lang)
        }
      })
    }
    return list
  },

  translatable: (state, getters) => (id, orgId) => {
    const prop = getters.get(id, orgId)
    return (prop && prop.translatable) || false
  },

  hasLatin: (state, getters) => (ids, orgId) => {
    if (!(ids instanceof Array)) ids = [ids]
    return (
      ids.filter((id) => {
        const prop = getters.get(id, orgId)
        return prop && !!prop.latin
      }).length > 0
    )
  },
  hasTranslatable: (state, getters) => (ids, orgId) => {
    return (
      ids.filter((id) => {
        const prop = getters.get(id, orgId)
        return prop && !!prop.translatable
      }).length > 0
    )
  },

  propLangIdxFromString: () => (input) => {
    if (typeof input !== 'string' || !input.length) return {}
    const [path, transform] = input.split('@', 2)
    if (!path) return {}
    const parts = path.split('.')

    // single part is considered as propId
    if (parts.length < 2) return { propId: path, transform }

    // if the last part consists only of numbers, it will be used as index
    const lastIsInt = /^\d+$/.test(parts[parts.length - 1])
    const index = lastIsInt ? parseInt(parts.pop(), 10) : undefined

    // if min. 2 parts remain and last part has two characters,
    // that will be used as lang
    const hasLang = parts.length > 1 && parts[parts.length - 1].length === 2
    const lang = hasLang ? parts.pop() : undefined

    // remaining parts are considered as propId
    return { propId: parts.join('.'), lang, index, transform }
  },
}
