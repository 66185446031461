import { cloneDeep, set } from 'lodash'
import Vue from 'vue'

export default (state, item) => {
  if (!item.id) return
  if (state.all[item.id]) {
    const newItem = cloneDeep(state.all[item.id])
    Object.entries(item).forEach(([path, value]) => {
      set(newItem, path, value)
    })
    Vue.set(state.all, item.id, newItem)
  } else {
    Vue.set(state.all, item.id, item)
  }
}
