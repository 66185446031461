import { get } from 'lodash'
import { getAuth } from 'firebase/auth'
import { moduleLicenseValue } from 'Shared/misc/license-modules'

export default {
  isUserLoggedIn: (state, getters) => !!getters.currentUser,
  currentUser: () => {
    const auth = getAuth()
    return auth.currentUser || undefined
  },
  organizationId: (state) => {
    return get(state, 'claims.org', null)
  },
  organization: (state, getters, rootState, rootGetters) => {
    const orgId = getters.organizationId
    return orgId ? rootGetters['organizations/get'](orgId) : null
  },
  isSupplier: (state) => get(state, 'claims.sup') === true,
  supplier: (state) => {
    const supplier = get(state, 'claims.sup')
    return supplier === true ? get(state, 'claims.org') : supplier
  },
  isRole: (state, getters) => (role) =>
    getters.isUserLoggedIn && get(state, 'claims.role') === role,
  isAdmin: (state, getters) => getters.isRole('admin'),
  isAdminOrSupplier: (state, getters) => getters.isAdmin || getters.isSupplier,
  constraints: (state, getters) => {
    if (!getters.isUserLoggedIn) return false
    if (getters.isAdmin) return null
    if (getters.isSupplier) {
      if (getters.organizationId === 'mcs') return null
      return [['supplier', '==', getters.organizationId]]
    }
    return [['owner', '==', getters.organizationId]]
  },
  userPhoto: (state, getters) => {
    return getters.isUserLoggedIn && state && state.user && state.user.photoURL
      ? state.user.photoURL
      : null
  },
  hasLicense: (state) => (module) => {
    // eslint-disable-next-line no-bitwise
    return state.claims.lic && !!(state.claims.lic & moduleLicenseValue(module))
  },
  simpleView: (state, getters) => {
    // deprecated user doc property, for compatibility:
    if (state.user && state.user.simpleView) return true
    // simple view for guests, editors and buyers
    if (!getters.isUserLoggedIn) return true
    return ['editor', 'buyer'].includes(get(state, 'claims.role'))
  },
}
