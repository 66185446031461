// list of Vuex state paths to save in localStorage
export default [
  'app.sidebar.opened',
  'collections.lastUsed',
  'collections.layout',
  'collections.showIds',
  'collections.showType',
  'contents.filterShowInactive',
  'contents.filterShowVariants',
  'contents.list.columns',
]
