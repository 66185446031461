import axios from 'axios'
import { isEqual } from 'lodash'
import config from '@/config'
import Router from 'App/routes'

export default {
  /**
   * Prepare and run search
   * @returns {Promise<*>}
   */
  search: async ({ commit, state, getters, dispatch }) => {
    // term valid?
    if (!state.term || state.term.length < 3) {
      dispatch('stop')
      commit('flush')
      return false
    }

    // params have changed?
    if (isEqual(getters.params, state.params)) return false

    // run query
    commit('params', getters.params)
    return dispatch('query')
  },

  /**
   * Perform search query
   * @param state
   * @param commit
   * @param dispatch
   * @returns {Promise<any|Promise>}
   */
  query: async ({ state, commit, dispatch }) => {
    const { params } = state
    if (!params || !params.q) throw Error('Required params for query missing')
    dispatch('stop')
    commit('loading', true)
    return dispatch('authentication/getToken', null, {
      root: true,
    })
      .then((token) => {
        const source = axios.CancelToken.source()
        commit('cancel', source.cancel)
        return axios({
          url: config.api.search,
          method: 'post',
          headers: { authorization: token ? `Bearer ${token}` : '' },
          data: { ...state.params, n: 250 },
          cancelToken: source.token,
        })
          .then((res) => {
            commit('cancel', null)
            commit('result', res.data.data)
            commit('total', res.data.total)
            return res.data.data
          })
          .catch((err) => {
            if (!axios.isCancel(err)) throw err
          })
      })
      .finally(() => commit('loading', false))
  },

  /**
   * Stop query / cancel pending axios request
   * @param state
   * @param commit
   */
  stop: ({ state, commit }) => {
    if (state.cancel) {
      state.cancel()
      commit('cancel', null)
    }
  },

  /**
   * Push route to show selected item
   * @param state
   * @returns {Promise<Route>}
   */
  openSelected: ({ state }) => {
    const { selected } = state
    if (!selected || !selected.collection || !selected.docId)
      throw Error(`Invalid selected item`)
    const route = {}
    if (selected.collection === 'collections') {
      route.name = 'collection-show'
      route.params = { collection: selected.docId }
    } else if (selected.collection === 'content') {
      route.name = 'content-show'
      route.params = { content: selected.docId }
    } else if (selected.collection === 'publications') {
      route.name = 'publications-show'
      route.params = { publicationId: selected.docId }
    } else if (selected.collection === 'releases') {
      if (selected.doc.publicationId) {
        route.name = 'publications-release-show'
        route.params = {
          publicationId: selected.doc.publicationId,
          releaseId: selected.docId,
        }
      } else {
        route.name = 'release-show'
        route.params = { releaseId: selected.docId }
      }
    } else if (selected.collection === 'templates') {
      if (selected.organization) {
        route.name = 'templates-organization'
        route.params = { organization: selected.organization }
      } else {
        route.name = 'templates'
      }
    } else {
      throw Error(
        `Not implemented: Goto document of collection ${selected.collection}`
      )
    }
    return Router.push(route)
  },

  navigate: ({ commit, rootGetters, dispatch }, route) => {
    const { name, params } = route
    if (name === 'collections') {
      commit('scope', 'collections')
      commit('collectionId', null)
    } else if (name === 'collection-show') {
      const type = rootGetters['collections/showType']
      commit('scope', type === 'contents' ? 'content' : type)
      commit('collectionId', params.collection)
    } else if (name === 'content-show') {
      commit('scope', 'content')
      commit('collectionId', null)
    } else if (String(name).startsWith('publications')) {
      commit('scope', 'releases')
      commit('collectionId', null)
    } else {
      commit('scope', null)
      commit('collectionId', null)
    }
    dispatch('search')
  },
}
