export default {
  all: {},

  // list items
  list: [],
  listConstraints: [],
  loading: null,
  listCollection: {},

  // list filters
  filterType: null,
  filterOwner: 'organization', // ... supplier, public,
  filterCollection: null,
  filterShowInactive: true,
  filterShowVariants: false,
  filterLanguages: null,

  // list order
  listOrderProp: null,
  listOrderDir: null,

  // editing
  active: null,
  editItem: null,
  editIsNew: true,
  editShow: false,
  editLanguagePrimary: 'de',
  editLanguageSecondary: 'fr',
  editTranslations: null,
  deletionPending: {},

  // releases' contents list
  relId: null,
  relList: [],

  // listeners
  listenerSingle: {},
  listenerAll: null,
  listenerRel: null,

  // display settings
  columns: [],
  showEditColumns: false,
  listScrollPosition: {},
}
