<template>
  <div
    v-if="visible"
    ref="cell"
    :class="rootClasses"
    :style="{
      left: nullOrLength(sticky),
      width: nullOrLength(width) || nullOrLength(minWidth),
      flexGrow: minWidth || 0,
    }"
    @click="handleClick"
  >
    <div class="cell">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MfColumn',

  props: {
    label: String,
    prop: String,
    width: [Number, String],
    minWidth: Number,
    sortable: Boolean,
    sticky: [Boolean, String],
    numeric: Boolean,
    centered: Boolean,
    cellClass: [String, Array, Object],
    customKey: [String, Number],
    visible: {
      type: Boolean,
      default: true,
    },
    internal: Boolean,
  },

  data() {
    return {
      newKey: this.customKey || this.label,
      parent: false,
    }
  },

  computed: {
    rootClasses() {
      const cellClasses =
        this.cellClass instanceof Array ? this.cellClass : [this.cellClass]
      return [
        ...cellClasses,
        {
          td: true,
          'text-right': this.numeric && !this.centered,
          'text-center': this.centered,
          'is-sticky': !!this.sticky,
        },
      ]
    },
    nullOrLength() {
      return (value) => {
        if (value === undefined) return null
        return Number.isNaN(value) ? value : `${value}px`
      }
    },
  },

  beforeMount() {
    const parent = this.findParent()

    if (!parent || !parent.$data.isMfTable) {
      this.$destroy()
      throw new Error('You should wrap MfColumn on a MfTable')
    }

    if (this.internal) return

    const repeated = parent.newColumns.some(
      (column) => column.newKey === this.newKey
    )
    if (!repeated) {
      parent.newColumns.push(this)
    }
  },

  beforeDestroy() {
    if (this.internal) return
    if (!this.parent || !this.parent.newColumns.length) return
    const index = this.parent.newColumns
      .map((column) => column.newKey)
      .indexOf(this.newKey)
    if (index >= 0) {
      this.parent.newColumns.splice(index, 1)
    }
  },

  methods: {
    handleClick() {
      this.$emit('click', this.$refs.cell)
    },
    findParent(el = this) {
      if (!el.$parent) return false
      if (el.$parent && el.$parent.$data && el.$parent.$data.isMfTable) {
        this.parent = el.$parent
        return el.$parent
      }
      return this.findParent(el.$parent)
    },
  },
}
</script>
