export default [
  {
    path: '/publications',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    name: 'publications',
    hidden: true,
    meta: {
      title: 'publications.title.general',
      icon: 'las la-file-medical-alt',
      noCache: false,
    },
    children: [
      {
        path: '/publications/:publicationId/release/:releaseId',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-publications-publication-show" */ './publication-show/publication-show.vue'
          ),
        name: 'publications-release-show',
        props: true,
        hidden: true,
        meta: {
          title: 'publications.title.showId',
          noCache: true,
          icon: 'las la-file',
        },
      },
      {
        path: '/publications/:publicationId/release/:releaseId/edit',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-publications-publication-edit" */ './publication-edit/publication-edit.vue'
          ),
        name: 'publications-release-edit',
        props: true,
        hidden: true,
        meta: {
          title: 'publications.title.edit',
          noCache: true,
          icon: 'el-icon-document-checked',
        },
      },
      {
        path: '/publications/:publicationId',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-publications-publication-show" */ './publication-show/publication-show.vue'
          ),
        name: 'publications-show',
        props: true,
        hidden: true,
        meta: {
          title: 'publications.title.showGeneral',
          noCache: true,
          icon: 'las la-file',
        },
      },
      {
        path: '/release/:releaseId',
        name: 'release-show',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-publications-publication-show" */ '../releases/release.vue'
          ),
        hidden: true,
      },
    ],
  },
]
