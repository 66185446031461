import axios from 'axios'
import { cloneDeepWith, isNil, defaults, pickBy, get, isEqual } from 'lodash'
import config from '@/config'
import Database from '../../orders-db'
import required from '../constants/required-fields'

const checkFields = (fields, item) => {
  fields.forEach((path) => {
    const value = get(item, path)
    if (!value || (value instanceof Array && !value.length)) {
      throw new Error(`orders.field.${path}Required`)
    }
  })
}

export default {
  getAll: async ({ state, commit, rootGetters }) => {
    const orgId = rootGetters['authentication/organizationId']
    const constraints = []
    if (orgId !== 'mcs' && !rootGetters['authentication/isAdmin']) {
      if (rootGetters['authentication/isSupplier']) {
        constraints.push(['supplier', '==', orgId])
      } else {
        constraints.push(['owner', '==', orgId])
      }
    }

    if (
      state.listenerAll &&
      isEqual(state.listenerAllConstraints, constraints)
    ) {
      return Promise.resolve()
    }
    if (state.listenerAll) {
      state.listenerAll()
    }
    commit('flush')
    commit('listenerAllConstraints', constraints)

    const orderBy = [['createTimestamp', 'desc']]
    const db = new Database(commit)
    return db
      .queryAndListen(constraints, 100, 0, orderBy, '', 'list')
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
  },
  unsubscribeAll: ({ state, commit }) => {
    if (state.listenerAll) state.listenerAll()
    commit('listenerAll', null)
    commit('listenerAllConstraints', null)
  },

  /**
   * Get one item from database and listen for changes
   * @param context
   * @param id
   * @returns {Promise<*>}
   */
  getOne: async ({ state, getters, commit }, id) => {
    if (Object.prototype.hasOwnProperty.call(state.listenerSingle, id))
      return getters.get(id)
    const db = new Database(commit)
    return db.readAndListen(id)
  },
  stopListening: ({ state, commit }, id) => {
    if (!Object.prototype.hasOwnProperty.call(state.listenerSingle, id)) return
    state.listenerSingle[id]()
    commit('removeListenerSingle', id)
  },

  /**
   * Prepare new order
   * @param context
   */
  initiateOrder: async ({ commit, dispatch, rootGetters }) => {
    if (rootGetters['basket/totalQuantity'] < 1) {
      throw new Error('basket.message.empty')
    }
    await dispatch('basket/updateItemsFromReleases', null, { root: true })
    const org = rootGetters['authentication/organization']
    const user = rootGetters['authentication/currentUser']
    const item = {
      billing: {
        address: {
          ...org.billing.address,
          company: org.name,
          name: user.displayName || user.email,
        },
        paymentMethod: get(org, 'billing.paymentMethod') || 'invoice',
      },
      logistics: {
        address: {
          ...org.billing.address,
          company: org.name,
          ...org.logistics.address,
          name: user.displayName || user.email,
        },
        useBillingAddress: get(org, 'logistics.useBillingAddress', true),
        shippingMethod: get(org, 'logistics.shippingMethod') || 'default',
      },
      owner: org.id,
      supplier: rootGetters['authentication/supplier'],
      status: 'open',
      items: rootGetters['basket/list'],
    }
    commit('editItem', item)
    commit('editIsNew', true)
  },

  resetEditItemLogisticsAddress: ({ state, rootState, commit }) => {
    const address = defaults(
      cloneDeepWith(state.editItem.logistics.address, pickBy),
      state.editItem.billing.address
    )
    if (!address.name) {
      address.name = rootState.authentication.user.displayName
    }
    commit('updateEditItem', { path: 'logistics.address', value: address })
  },

  /**
   * Insert or update current editItem in DB
   */
  submitOrder: async ({ dispatch, state, commit }) => {
    checkFields(required.general, state.editItem)
    if (get(state.editItem, 'logistics.useBillingAddress')) {
      commit('updateEditItem', {
        path: 'logistics.address',
        value: state.editItem.billing.address,
      })
    } else {
      checkFields(required.logisticsAddress, state.editItem)
    }

    const order = state.editItem
    return dispatch('set', order).then((result) => {
      commit('editItem', {})
      commit('editIsNew', true)
      if (!order.id) {
        dispatch('basket/deleteAll', null, { root: true })
      }
      commit('tagsView/DEL_VISITED_VIEW', '/order/basket', { root: true })
      commit('tagsView/DEL_VISITED_VIEW', '/order/checkout', { root: true })
      return result
    })
  },

  /**
   * create or update item in database
   * @param context
   * @param item
   * @returns {Promise<*>}
   */
  set: async ({ commit }, item) => {
    const db = new Database(commit)
    const id = !isNil(item.id) && item.id ? item.id : null
    delete item.id
    return db.create(item, id)
  },

  /**
   * Update item in database
   * @param context
   * @param item
   * @returns {Promise<*>}
   */
  update: async ({ commit }, item) => {
    if (isNil(item.id)) return false
    const db = new Database(commit)
    return db.update(item)
  },

  updateStatus: async ({ dispatch }, { orderId, status }) => {
    if (isNil(orderId)) throw Error('parameter "orderId" is required')
    if (isNil(status)) throw Error('parameter "status" is required')
    const updateDoc = { id: orderId, status }
    return dispatch('update', updateDoc)
  },

  /**
   * Delete item from database
   * @param context
   * @param id
   * @returns {Promise<*>}
   */
  delete: async ({ commit }, id) => {
    const db = new Database(commit)
    return db.delete(id)
  },

  sendTestMail: async ({ dispatch }) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'OrderTestMail',
        query: `
mutation OrderTestMail($to: String, $id: ID) {
  orderTestMail(to: $to, id: $id)
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.orderTestMail', null)
  },
}
