import Vue from 'vue'
import Vuex from 'vuex'
import state from 'Shared/store/state'
import mutations from 'Shared/store/mutations'
import getters from 'Shared/store/getters'
import { i18nVuexPlugin } from '@/plugins/i18n'

// ./shared/store/modules
import app from 'Shared/store/modules/app'
import errorLog from 'Shared/store/modules/errorLog'
import permission from 'Shared/store/modules/permission'
import settings from 'Shared/store/modules/settings'
import storageUrls from 'Shared/store/modules/storageUrls'
import tagsView from 'Shared/store/modules/tagsView'

// ./modules/**/shared/store
import artwork from 'Modules/artwork/shared/store'
import authentication from 'Modules/authentication/shared/store'
import basket from 'Modules/basket/shared/store'
import collections from 'Modules/collections/shared/store'
import contents from 'Modules/contents/shared/store'
import contentTypes from 'Modules/content-types/shared/store'
import deployments from 'Modules/deployments/shared/store'
import notifications from 'Modules/notifications/shared/store'
import orders from 'Modules/orders/shared/store'
import organizations from 'Modules/organizations/shared/store'
import pictures from 'Modules/pictures/shared/store'
import properties from 'Modules/properties/shared/store'
import publications from 'Modules/publications/shared/store'
import releases from 'Modules/releases/shared/store'
import search from 'Modules/search/shared/store'
import templates from 'Modules/templates/shared/store'
import users from 'Modules/users/shared/store'

Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  mutations,
  getters,
  modules: {
    // ./shared/store/modules
    app,
    errorLog,
    permission,
    settings,
    storageUrls,
    tagsView,
    // ./modules/**/shared/store
    artwork,
    authentication,
    basket,
    collections,
    contents,
    contentTypes,
    deployments,
    notifications,
    orders,
    organizations,
    pictures,
    properties,
    publications,
    releases,
    search,
    templates,
    users,
  },
  plugins: [i18nVuexPlugin],
})

export default store
