import DOMPurify from 'dompurify'
import { Timestamp } from 'firebase/firestore'
import { get } from 'lodash'
import { marked } from 'marked'
import moment from 'moment'
import slugify from 'slugify'
import escapeCsv from 'Shared/misc/escape-csv'
import escapeHtml from 'Shared/misc/escape-html'
import escapeXml from 'Shared/misc/escape-xml'
import monthArrayToString from 'Shared/misc/month-array-to-string'
import barcodeFont from './barcodeFont'

export default (strTemplate, objVariables) => {
  const getValue = (data, path, modifier, params) => {
    let value = path ? get(data, path, null) : data || null
    if (!value) return ''
    if (value instanceof Timestamp) value = value.toDate()
    if (value instanceof Date) {
      if (modifier === 'dateUnix') {
        value = moment(value).format('X')
      } else if (modifier === 'dateSql') {
        value = moment(value).format('YYYY-MM-DD HH:mm:ss')
      } else {
        value = value.toISOString()
      }
    } else if (modifier === 'pictureSrc') {
      return `picture-src:${value}:picture-src`
    } else if (modifier === 'pictureMime') {
      return `picture-mime:${value}:picture-mime`
    } else if (modifier === 'weblicationCategories') {
      if (!(value instanceof Array)) {
        value = String(value).split(',')
      }
      return value
        .map((item) => {
          const category = slugify(item, { locale: 'de' })
          return `\n        <wd:category>${category}</wd:category>`
        })
        .join('')
    } else if (
      modifier &&
      value instanceof Array &&
      modifier.startsWith('months')
    ) {
      return monthArrayToString(value, modifier, params)
    } else if (modifier === 'ndash' && value instanceof Array) {
      return value.join(' – ')
    } else if (modifier.startsWith('barcode')) {
      return barcodeFont(value, modifier)
    } else if (modifier === 'markdown') {
      return DOMPurify.sanitize(
        marked(String(value), { breaks: true, silent: true })
      )
    } else if (modifier === 'markdownXml') {
      return escapeXml(
        DOMPurify.sanitize(
          marked(String(value), { breaks: true, silent: true, xhtml: true }),
          {
            ALLOWED_TAGS: [
              'div',
              'p',
              'pre',
              'ol',
              'ul',
              'li',
              'a',
              'em',
              'i',
              'strong',
              'b',
              'code',
              'sub',
              'sup',
              'span',
              'br',
              'img',
            ],
          }
        )
      )
    } else if (modifier === 'escapeXml') {
      return escapeXml(String(value))
    } else if (modifier === 'escapeCsv') {
      return escapeCsv(escapeHtml(String(value)))
    } else if (modifier === 'escapeSsv') {
      return escapeCsv(escapeHtml(String(value)), ';')
    } else if (modifier === 'escapeTsv') {
      return escapeCsv(escapeHtml(String(value)), '\t')
    } else if (modifier === 'json') {
      return JSON.stringify(value)
    }
    return escapeHtml(String(value))
  }

  const replaceVar = (_, varPath, __, modifier, ___, params) =>
    getValue(objVariables, varPath, modifier, params)

  const replaceFor = (match, indexVar, dataVar, innerStr) => {
    let str = ''
    const list = get(objVariables, dataVar, []) || []
    list.forEach((item, index) => {
      const replaceForVar = (_, __, path, ___, modifier, ____, params) =>
        getValue(item, path, modifier, params)
      const replaceForIf = (_, __, path, trueStr) =>
        (get(item, path) && trueStr) || ''
      str += innerStr
        .replace(/{loop.index}/g, index + 1)
        .replace(
          new RegExp(
            `{% if ${indexVar}(\\.([\\w.]+))? %}[\\n]?([\\S\\s]*?){% endif %}`,
            'gm'
          ),
          replaceForIf
        )
        .replace(
          new RegExp(
            `{${indexVar}(\\.([\\w.]+))?( ?\\| ?(\\w+)(:([^}]+))?)?}`,
            'g'
          ),
          replaceForVar
        )
    })

    return str
  }

  const replaceIf = (match, test, trueStr, elseStr, falseStr = '') => {
    return get(objVariables, test) ? trueStr : falseStr
  }

  return strTemplate
    .replace(
      /{% for ([a-z]+) in ([\w.]+) %}[\n]?([\S\s]*?){% endfor %}\n?/gm,
      replaceFor
    )
    .replace(
      /{% if ([\w.]+) %}[\n]?([\S\s]*?)({% else %}[\n]?([\S\s]*?))?{% endif %}/gm,
      replaceIf
    )
    .replace(/{([\w.]+)( ?\| ?(\w+)(:([^}]+))?)?}/g, replaceVar)
}
