import i18n from '@/plugins/i18n'

export default {
  execute: async ({ getters, dispatch, rootGetters, state }) => {
    if (!getters.regex) {
      const msg = getters.regexError
      throw Error(
        i18n.t('contents.searchReplace.regexError', { msg }).toString()
      )
    }
    if (!getters.isValid) {
      throw Error(i18n.t('contents.searchReplace.required').toString())
    }
    const path = getters.fieldPath
    if (!path) throw Error('empty field path')
    const list = [...getters.list]
    const releaseUpdates = []
    const contents = await Promise.all(
      list.map((item) =>
        dispatch(
          'contents/updateValue',
          { contentId: item.id, path, value: item.replaced },
          { root: true }
        ).then((updatedContent) => {
          if (state.updateReleases) {
            return dispatch('contents/getOneOnce', item.id, {
              root: true,
            }).then((content) => {
              const { propId } = rootGetters['contents/pathToPropLangIdx'](path)
              const releaseIds = rootGetters.idMapToArray(content.releases)
              releaseIds.forEach((releaseId) => {
                releaseUpdates.push(
                  dispatch(
                    'releases/updateFromContent',
                    { releaseId, content, propId },
                    { root: true }
                  )
                )
              })
              return content
            })
          }
          return updatedContent
        })
      )
    )
    await Promise.all(releaseUpdates)
    return contents
  },
}
