export default {
  // settings
  term: '',
  scope: null,
  collectionId: null,
  contentType: null,
  language: 'de',

  // active search
  loading: false,
  active: false,
  result: [],
  total: 0,
  params: {},
  cancel: null,

  // selected item
  selected: null,
}
