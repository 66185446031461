export default [
  {
    path: '/config/organizations',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-organizations" */ './organizations.vue'
      ),
    name: 'organizations',
    meta: {
      title: 'organizations.title.navigation',
      icon: 'las la-briefcase',
      affix: false,
      noCache: true,
      roles: ['supplier'],
    },
  },
  {
    path: '/config/organizations/:id',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-organizations" */ './organization-show/organization-show.vue'
      ),
    name: 'organizations-show',
    props: true,
    hidden: true,
    meta: {
      icon: 'las la-address-card',
      title: 'organizations.title.show',
      roles: ['supplier', 'owner', 'manager'],
      activeMenu: '/config/organizations',
    },
  },
  {
    path: '/account/organization',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-organizations" */ './my-organization/my-organization.vue'
      ),
    name: 'organizations-my-organization',
    meta: {
      icon: 'las la-address-card',
      title: 'organizations.title.myOrganization',
      roles: ['supplier', 'owner', 'manager'],
    },
  },
]
