export default Object.freeze([
  'general_meta',
  'images',
  'selling',
  'plant_description',
  'product_properties',
  'text_sections',
  'location_care',
  'hints',
  'links',
  'cultivation_sowing_planting',
  'hazardous_material',
])
