import { moduleLicenseValue } from 'Shared/misc/license-modules'

export default [
  {
    path: '/media',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    meta: {
      roles: ['supplier', 'owner', 'manager', 'member', 'editor'],
    },
    children: [
      {
        path: '/media/:id',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-images" */ './picture-show/picture-show.vue'
          ),
        name: 'picture-show',
        hidden: true,
        meta: {
          title: 'pictures.title.show',
          icon: 'las la-image',
          affix: false,
          noCache: false,
          license: moduleLicenseValue('pictures'),
          activeMenu: '/media',
        },
      },
      {
        path: '/media',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-images" */ './pictures.vue'
          ),
        name: 'pictures',
        meta: {
          title: 'pictures.title.list',
          icon: 'las la-image',
          affix: false,
          noCache: false,
        },
      },
    ],
  },
]
