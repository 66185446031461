import Vue from 'vue'

export default {
  /* inline editing */
  editInlineId: (state, val) => (state.id = val),
  editInlineIds: (state, val) => {
    Vue.set(state, 'ids', val)
    state.idAdded = false
  },
  editInlineIdsAdd: (state, id) => {
    state.ids.push(id)
    state.idAdded = true
  },
  editInlineIdsRemove: (state, id) => {
    const idx = state.ids.findIndex((el) => el === id)
    if (idx === -1) return
    state.ids.splice(idx, 1)
    state.idAdded = false
  },
  editInlinePath: (state, val) => (state.path = val),
  editInlineValue: (state, val) => Vue.set(state, 'value', val),
  editInlineSaving: (state, val) => (state.saving = !!val),
  editInlineNext: (state, val) => (state.next = val),
}
