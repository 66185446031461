import extensions from '../constants/extensions'

export default {
  base: () => (name) => {
    const dotIdx = name.lastIndexOf('.')
    if (dotIdx < 1) return name
    const extension = name.substring(dotIdx + 1)
    if (!extensions.includes(extension)) return name
    return name.substring(0, dotIdx)
  },
  get: (state, getters) => (name) => state.all[getters.base(name)] || null,
  params: (state) => {
    const { page, perPage, search, source, sortBy, sortDesc } = state
    const params = { p: page, l: perPage }
    if (search) params.q = search
    if (source) params.s = source.substr(0, 1)
    if (['created_at', 'name', 'updated_at'].includes(sortBy)) {
      params.ob = sortBy.substr(0, 1)
      params.od = sortDesc ? 1 : 0
    }
    return params
  },
  canDelete: (state, getters, rootState, rootGetters) => (picture) => {
    if (rootGetters['authentication/isAdmin']) return true
    if (rootGetters['authentication/isSupplier']) return true
    return (
      picture &&
      picture.owner &&
      picture.owner === rootGetters['authentication/organizationId']
    )
  },
}
