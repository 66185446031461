export default {
  get: (state) => (id) => (state.all && state.all[id]) || null,
  isLoading: (state) => (id) => (state.loading && !!state.loading[id]) || false,
  fontsNumMissing: (state, getters) => (id) => {
    const artwork = getters.get(id)
    const fonts = (artwork && artwork.fonts) || []
    return fonts.filter((font) => !font.available).length
  },
  assets: (state, getters) => (id) => {
    const artwork = getters.get(id)
    return (artwork && artwork.assets) || []
  },
  assetsImages: (state, getters) => (id) =>
    getters.assets(id).filter((asset) => !asset.isLayout),
}
