import shortId from 'Shared/misc/short-id'

const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  permission_routes: (state) => state.permission.routes,
  shortId: () => shortId,
  idMapToArray: () => (map) => {
    if (!(map instanceof Object)) return []
    return Object.keys(map).filter((key) => map[key])
  },
}
export default getters
