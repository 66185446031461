export default [
  {
    path: '/config/content-types',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-content-types" */ './content-types.vue'
      ),
    name: 'content-types',
    meta: {
      title: 'contentTypes.title.navigation',
      icon: 'las la-object-group',
      affix: false,
      noCache: false,
      roles: [],
    },
  },
]
