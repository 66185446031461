import { cloneDeep } from 'lodash'

export default {
  /**
   * Fetch one and set as editItem
   * @param context
   * @param id
   */
  loadEditItem: async ({ commit, rootGetters }, id) => {
    const cache = rootGetters['organizations/get'](id)
    if (!cache) {
      commit('editItem', null)
      throw Error(`Organization ${id} not found`)
    }
    const item = cloneDeep(cache)
    commit('editItem', item)
    commit('editTab', 'general')
    return item
  },

  /**
   * Create a new property and reset property name input
   */
  commitEditItem: ({ dispatch, state, commit }) => {
    if (!state.editItem) throw Error('Empty editItem')
    const item = state.editItem
    commit('editItem', null)
    delete item.numberRanges
    return dispatch('organizations/update', item, { root: true })
  },
}
