export default {
  canInvite: (state, getters, rootState, rootGetters) => (orgId) => {
    // is admin?
    if (rootGetters['authentication/isAdmin']) return true

    const org = rootGetters['organizations/get'](orgId)
    // is supplier?
    if (
      rootGetters['authentication/isSupplier'] &&
      org &&
      org.type === 'client' &&
      org.supplier === rootGetters['authentication/organizationId']
    ) {
      return true
    }
    // is owner?
    if (org.owner && org.owner === rootGetters['authentication/currentUser'].id)
      return true

    // is manager?
    if (
      rootGetters['authentication/organizationId'] === orgId &&
      rootGetters['authentication/isRole']('manager')
    ) {
      return true
    }

    return false
  },
  canDelete: (state, getters, rootState, rootGetters) => (user) => {
    // don't delete yourself
    if (user.id === rootGetters['authentication/currentUser'].uid) return false

    // is admin?
    if (rootGetters['authentication/isAdmin']) return true

    const org = rootGetters['organizations/get'](user.organization)
    // is supplier?
    if (
      rootGetters['authentication/isSupplier'] &&
      org &&
      org.type === 'client' &&
      org.supplier === rootGetters['authentication/organizationId']
    ) {
      return true
    }

    // is owner?
    if (
      org.owner &&
      org.owner === rootGetters['authentication/currentUser'].uid
    )
      return true

    // is manager?
    if (
      rootGetters['authentication/organizationId'] === user.organization &&
      rootGetters['authentication/isRole']('manager') &&
      ['member', 'manager'].includes(user.role)
    ) {
      return true
    }

    return false
  },
  allowedRoleChanges: (state, getters, rootState, rootGetters) => (user) => {
    if (rootGetters['authentication/isAdmin']) {
      // do not lock yourself out
      if (user.id === rootGetters['authentication/currentUser'].uid) return []
      return ['admin', 'owner', 'manager', 'member', 'editor', 'buyer']
    }

    if (user.organization !== rootGetters['authentication/organizationId'])
      return []

    const org = rootGetters['organizations/get'](user.organization)
    if (
      org.owner &&
      org.owner === rootGetters['authentication/currentUser'].uid
    )
      return ['owner', 'manager', 'member', 'editor', 'buyer']

    if (
      rootGetters['authentication/isRole']('manager') &&
      ['member', 'editor', 'buyer'].includes(user.role)
    )
      return ['member', 'editor', 'buyer']

    return []
  },
}
