export default {
  upload: async (
    { dispatch, getters, rootGetters },
    { collectionId, label, upload, previewDivide = false }
  ) => {
    const { action, releaseId, publicationId } = getters.releaseAction(
      upload.file
    )
    if (!label) {
      label = getters.releaseLabel(upload.file)
    }
    const data = {
      numberMcs: {
        property: 'number_mcs',
        value: upload.file.meta.numberMcs,
      },
    }

    if (action === 'create') {
      return dispatch(
        'releases/create',
        { collectionId, label, data, previewDivide },
        { root: true }
      ).then((doc) =>
        dispatch(
          'releases/fileUploadAdd',
          {
            docId: doc.id,
            upload,
            usage: 'print-preview',
          },
          { root: true }
        )
      )
    }

    if (action === 'update') {
      const release = rootGetters['releases/get'](releaseId)
      if (release && release.files) {
        const dir = rootGetters['releases/uploadDir'](releaseId)
        const path = `${dir}/${upload.file.name}`
        const removeFile = release.files.find((f) => f.path === path)
        if (removeFile) {
          await dispatch('releases/fileRemove', removeFile, { root: true })
        }
      }
      return dispatch(
        'releases/fileUploadAdd',
        {
          docId: releaseId,
          upload,
          usage: 'print-preview',
        },
        { root: true }
      )
    }

    if (action === 'release') {
      const release =
        rootGetters['publications/getLatestRelease'](publicationId)
      if (!release) throw new Error('Release not found')
      return dispatch('releases/createNewRelease', release.id, {
        root: true,
      }).then((doc) =>
        dispatch(
          'releases/fileUploadAdd',
          {
            docId: doc.id,
            upload,
            usage: 'print-preview',
          },
          { root: true }
        )
      )
    }

    throw new Error('Cannot upload file, unknown target release document')
  },
}
