import axios from 'axios'
import config from '@/config'

export default {
  _request: async (
    { dispatch },
    { url, method = 'get', headers = {}, data = null }
  ) => {
    await dispatch('authentication/getToken', null, {
      root: true,
    }).then((token) => (headers.authorization = `Bearer ${token}`))

    return axios.request({
      baseURL: config.api.artwork.url,
      method,
      url,
      headers,
      data,
    })
  },
  getAll: async ({ commit, state, dispatch }) => {
    if (state.loading) return state.loading
    const query = dispatch('_request', { url: '/hyphens' })
      .then((response) => {
        if (response.data.success && response.data.data) {
          commit('update', response.data.data)
        }
      })
      .finally(() => commit('loading', null))
    commit('loading', query)
    return query
  },
}
