import Vue from 'vue'
import vueSetPath from 'Shared/store/_partials/vueSetPath'

export default {
  /* all */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  remove: (state, item) => Vue.delete(state.all, item.id),
  list: (state, list) => (state.list = list),
  listenerAll: (state, unsubscribe) => (state.listenerAll = unsubscribe),
  loading: (state, val) => (state.loading = val),
  active: (state, val) => (state.active = val),
  dialogVisible: (state, val) => (state.dialogVisible = !!val),
  addModel: (state, item) => (state.addModel = item),
  addModelUpdate: (state, { path, value }) =>
    vueSetPath(state.addModel, path, value),
  addDeletePending: (state, { id, promise }) =>
    Vue.set(state.deletePending, id, promise),
  removeDeletePending: (state, id) => Vue.delete(state.deletePending, id),
  customReleasePdfs: (state, list) => (state.customReleasePdfs = list),
}
