import Vue from 'vue'
import updatePartial from '../../../../shared/store/_partials/mutationUpdatePartial'
import vueSetPath from '../../../../shared/store/_partials/vueSetPath'

export default {
  /* all */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  updatePartial,
  remove: (state, item) => Vue.delete(state.all, item.id),
  list: (state, list) => (state.list = list),

  filterStatusToggle: (state, status) => {
    const idx = state.filterStatuses.findIndex((i) => i === status)
    if (idx === -1) {
      state.filterStatuses.push(status)
    } else {
      state.filterStatuses.splice(idx, 1)
    }
  },
  filterStatuses: (state, list) => (state.filterStatuses = list),

  active: (state, id) => (state.active = id),

  /* editItem */
  editItem: (state, item) => (state.editItem = item),
  updateEditItem: (state, { path, value }) =>
    vueSetPath(state.editItem, path, value),

  /* editIsNew */
  editIsNew: (state, val) => (state.editIsNew = !!val),

  /* store unsubscribe functions for active listeners */
  listenerAll: (state, unsubscribe) => (state.listenerAll = unsubscribe),
  listenerAllConstraints: (state, constraints) =>
    (state.listenerAllConstraints = constraints),
  removeListenerAll: (state, query) => Vue.delete(state.listenerAll, query),
  addListenerSingle: (state, { id, unsubscribe }) =>
    Vue.set(state.listenerSingle, id, unsubscribe),
  removeListenerSingle: (state, id) => Vue.delete(state.listenerSingle, id),

  loading: (state, val) => (state.loading = !!val),
}
