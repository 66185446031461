import { error as logError } from '@/app/shared/utils/log'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    get: async ({ dispatch }, { templateId, file }) => {
      return dispatch(
        'artwork/_request',
        { url: `/templates/${templateId}/tests/${file}` },
        { root: true }
      )
        .then((response) => {
          return (response.data.success && response.data.data) || null
        })
        .catch((error) => {
          logError(error)
          return null
        })
    },
    list: async ({ dispatch }, { templateId }) => {
      return dispatch(
        'artwork/_request',
        { url: `/templates/${templateId}/tests` },
        { root: true }
      )
        .then((response) => {
          return (response.data.success && response.data.data) || null
        })
        .catch((error) => {
          logError(error)
          return null
        })
    },
    delete: async ({ dispatch }, { templateId, file }) => {
      return dispatch(
        'artwork/_request',
        {
          url: `/templates/${templateId}/tests/${file}`,
          method: 'delete',
        },
        { root: true }
      )
        .then((response) => {
          return !!response.data.success
        })
        .catch((error) => {
          logError(error)
          return false
        })
    },
  },
}
