import { pick, mapValues } from 'lodash'
import iso6391 from '../../../../shared/lang/iso639-1'

const languageCodes = Object.freeze([
  'la', // Latin
  'de', // German
  'en', // English
  'fr', // French
  'bg', // Bulgarian
  'cs', // Czech
  'da', // Danish
  'es', // Spanish
  'et', // Estonian
  'fi', // Finnish
  'hu', // Hungarian
  'it', // Italian
  'lb', // Luxembourgian
  'lt', // Lithuanian
  'lv', // Latvian
  'nl', // Dutch
  'no', // Norwegian
  'pl', // Polish
  'pt', // Portuguese
  'ro', // Romanian
  'ru', // Russian
  'sk', // Slovak
  'sr', // Serbian
  'sv', // Swedish
  'tr', // Turkish
  'uk', // Ukrainian
])

const languageCodesDefault = Object.freeze(['la', 'de'])

const languages = mapValues(pick(iso6391, languageCodes), 'nameIso')

export default languageCodes

export { languageCodes, languageCodesDefault, languages }
