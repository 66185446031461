import i18n from '@/plugins/i18n'

export default {
  get: (state) => (id) =>
    id && state.all && state.all[id] ? state.all[id] : null,
  active: (state, getters) => getters.get(state.active),
  list: (state, getters) => state.list.map(getters.get),
  hasFilterStatus: (state) => (status) =>
    !!state.filterStatuses.find((i) => i === status),
  orgHasOrder: (state) => (orgId) => {
    return Object.values(state.all).findIndex((el) => el.owner === orgId) > -1
  },
  organizations: (state, getters, rootState, rootGetters) => {
    return rootGetters['organizations/list'].filter((org) =>
      getters.orgHasOrder(org.id)
    )
  },
  releaseIds: (state, getters) => (id) => {
    const order = id ? getters.get(id) : state.editItem
    if (!order || !order.items) return []
    return order.items.map((item) => item.releaseId).filter(Boolean)
  },
  displayNumber:
    () =>
    ({ number, supplier }) => {
      const prefix = String(supplier).substring(0, 1).toUpperCase()
      return `${prefix}${number}`
    },
  label: (state, getters) => (id) => {
    const order = getters.get(id)
    return order
      ? getters.displayNumber(order)
      : i18n.t('orders.title.showId', { id })
  },
}
