import {
  deleteObject,
  list,
  ref,
  uploadBytesResumable,
  getMetadata,
  getDownloadURL,
} from 'firebase/storage'
import { debug } from 'Shared/utils/log'
import storage from './get-storage'

/**
 * Generic Firebase Storage
 */
export default class GenericStorage {
  /* pre-select directory */
  collectionName = ''

  /**
   * Constructor function
   * @param collectionName
   */
  constructor(collectionName = '') {
    this.collectionName = collectionName
  }

  /**
   * Read a file from storage
   * @param file
   * @returns {Promise<string>}
   */
  async downloadUrl(file) {
    const fileRef = this.getFileRef(file)

    debug(`downloadUrl ${fileRef.fullPath}`)

    return getDownloadURL(fileRef)
  }

  /**
   * get file reference
   * @param file
   * @returns <StorageReference>
   */
  getFileRef(file) {
    if (file.indexOf(`${this.collectionName}/`) === 0) {
      return ref(storage, file)
    }
    return ref(storage, `${this.collectionName}/${file}`)
  }

  /**
   * upload to storage
   * @param path
   * @param upload
   * @param meta
   * @returns {Promise<firebase.storage.FullMetadata>}
   */
  async uploadTask(path, upload, meta = undefined) {
    const fileRef = this.getFileRef(path)
    const uploadTask = uploadBytesResumable(fileRef, upload.file, meta)
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          upload.onProgress(progress)
        },
        (error) => reject(error),
        async () => {
          getMetadata(uploadTask.snapshot.ref).then(resolve).catch(reject)
        }
      )
    })
  }

  async deleteFile(path) {
    const fileRef = this.getFileRef(path)
    return deleteObject(fileRef)
  }

  async listFiles(path = '') {
    const listRef = this.getFileRef(path)
    return list(listRef, { maxResults: 100 }).then(({ items }) =>
      items.map((itemRef) => itemRef.name)
    )
  }
}
