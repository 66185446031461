import { isNil } from 'lodash'
import selectRange from 'Shared/misc/select-range'

export default {
  commitEditInline: async ({ state, commit, dispatch }) => {
    if (state.saving) return false
    commit('editInlineSaving', true)
    const { id, ids, path, value } = state
    const updateIds = ids && ids.includes(id) ? ids : [id]
    const updates = []
    updateIds.forEach((contentId) =>
      updates.push(dispatch('updateValue', { contentId, path, value }))
    )
    return Promise.all(updates).then(() => {
      dispatch('resetEditInline', true)
    })
  },
  setAsEditMaster: ({ state, getters, commit }, { id, path }) => {
    if (isNil(id)) throw Error('required parameter "id" missing')
    if (isNil(path)) throw Error('required parameter "path" missing')
    if (state.id === id && state.path === path) return
    commit('editInlineId', id)
    commit('editInlinePath', path)
    commit('editInlineValue', getters.getValue(id, path))
  },
  resetEditInline: ({ state, commit }, gotoNext = false) => {
    commit('editInlineId', null)
    commit('editInlinePath', null)
    commit('editInlineValue', '')
    commit('editInlineSaving', false)
    if (gotoNext && state.next) {
      state.next()
    }
    commit('editInlineNext', null)
  },
  editInlineIdsToggleRange: ({ state, getters, commit }, id) => {
    selectRange(getters.list, state.ids, state.idAdded, id, (addId) =>
      commit('editInlineIdsAdd', addId)
    )
  },
  updateSelected: async ({ state, dispatch }, { id, path, value }) => {
    if (isNil(id)) throw Error('required parameter "id" missing')
    if (isNil(path)) throw Error('required parameter "path" missing')
    const { ids } = state
    const updateIds = ids && ids.includes(id) ? ids : [id]
    const updates = updateIds.map((contentId) =>
      dispatch('updateValue', { contentId, path, value })
    )
    return Promise.all(updates)
  },
}
