import axios from 'axios'
import { get, isNil } from 'lodash'
import config from '@/config'
import Database from '../../templates-db'

export default {
  /**
   * Fetch list and listen for changes
   * @param context
   * @returns {Promise<void>}
   */
  getAll: async ({ commit, state, rootGetters }) => {
    // already listening to all documents?
    if (state.listenerAll !== null)
      return Promise.reject(Error('already listening to all templates'))

    // clear vuex store
    commit('flush')

    const db = new Database(commit)
    const constraints = rootGetters['authentication/constraints']

    await db
      .queryAndListen(constraints, 100)
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
    return Promise.resolve()
  },

  /**
   * Update item in database
   * @param context
   * @param item
   * @returns {Promise<*>}
   */
  update: async ({ commit }, item) => {
    if (isNil(item.id)) throw Error('Field item.id is required')
    const db = new Database(commit)
    return db.update(item)
  },

  /**
   * Delete item from database
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  delete: async ({ commit }, id) => {
    const db = new Database(commit)
    return db.delete(id)
  },

  create: async ({ dispatch }, { id, owner }) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'TemplateCreate',
        variables: { template: { id, owner } },
        query: `
mutation TemplateCreate($template: TemplateCreateInput!) {
  templateCreate(template: $template) {
    id
  }
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.templateCreate', {})
  },
}
