export default {
  all: {},

  // list items
  list: [],
  listConstraints: [],
  loading: null,

  // list filters
  filterType: null, // print, digital
  filterOwner: 'organization', // supplier

  // list pagination
  listPage: 1,
  listPerPage: 12,

  // editing
  active: null,
  editItem: null,
  editIsNew: true,
  deletionPending: {},

  // listeners
  listenerAll: null,
  listenerSingle: {},
}
