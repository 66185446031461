import moment from 'moment'
import Store from 'App/store'
import humanFileSize from './human-file-size'
import truncate from './truncate'
import shortId from '../misc/short-id'

moment.locale(Store.state.app.language || 'en')
const currencyFormatter = new Intl.NumberFormat('de-CH', {
  style: 'currency',
  currency: 'CHF',
})
const numberFormatter = new Intl.NumberFormat('de-CH')

export default {
  dateFormat: (input, format) => {
    if (!input) return ''
    if (!format) format = 'LL LTS'
    else if (format === true) format = 'LL'
    return moment(input).format(format)
  },
  dateDiff: (input, from = null, hideSuffix = false) => {
    if (!input) return ''
    if (!from) from = moment()
    return moment(input).from(from, hideSuffix)
  },
  humanFileSize,
  json: (v, indent = 2) => JSON.stringify(v, null, indent),
  round: (v) => Math.round(v),
  truncate,
  currency: (v) => currencyFormatter.format(v),
  number: (v) => numberFormatter.format(v),
  shortId: (v) => shortId(v),
}
