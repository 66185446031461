export default {
  all: {},
  active: null,
  editItem: null,
  editShow: false,
  editIsNew: true,
  listenerSingle: {},
  listenerAll: null,
  search: '',
  loading: null,
}
