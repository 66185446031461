<template>
  <el-dialog
    v-if="newContentAvailable"
    width="400px"
    :title="$t('title.newVersionAvailable')"
    :visible.sync="visible"
  >
    <el-alert
      v-if="refreshingApp"
      class="mb-20"
      type="primary"
      :closable="false"
    >
      <h3>
        <i class="las la-hourglass-half" />
        <span class="ml-5">{{ $t('message.loading') }}</span>
      </h3>
    </el-alert>
    <div>{{ $t('message.newVersionAvailable') }}</div>
    <template #footer>
      <el-button
        type="primary"
        :disabled="refreshingApp"
        @click="serviceWorkerSkipWaiting"
        >{{ $t('button.refresh') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'SharedAppUpdateNotification',
  data() {
    return {
      visible: true,
    }
  },
  computed: {
    newContentAvailable() {
      return this.$store.getters['app/newContentAvailable']
    },
    refreshingApp() {
      return this.$store.state.app.refreshingApp
    },
  },
  methods: {
    serviceWorkerSkipWaiting() {
      this.$store.dispatch('app/serviceWorkerSkipWaiting')
    },
  },
}
</script>
