import { asyncRoutes, constantRoutes } from 'App/routes'

/**
 * Use meta.role to determine if the current user has permission
 * @param claims
 * @param route
 */
function hasPermission(claims, route) {
  if (!route.meta) {
    // unrestricted route
    return true
  }
  if (route.meta.roles) {
    // route requires role
    if (!claims.role || !route.meta.roles.includes(claims.role)) {
      // role not matched
      if (claims.sup !== true || !route.meta.roles.includes('supplier')) {
        // supplier role not matched
        return false
      }
    }
  }
  if (route.meta.license) {
    // route requires license
    // eslint-disable-next-line no-bitwise
    if (!claims.lic || (route.meta.license & claims.lic) === 0) {
      // license not matched
      return false
    }
  }
  // tests passed
  return true
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param claims
 */
export function filterAsyncRoutes(routes, claims = {}) {
  const res = []

  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasPermission(claims, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, claims)
      }
      res.push(tmp)
    }
  })

  return res
}

const STATE = {
  routes: [],
  addRoutes: [],
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
}

const actions = {
  generateRoutes({ commit }, claims = {}) {
    return new Promise((resolve) => {
      let accessedRoutes
      if (claims.role === 'admin') {
        accessedRoutes = asyncRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, claims)
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
}

const getters = {}

export default {
  namespaced: true,
  state: STATE,
  mutations,
  actions,
  getters,
}
