export default (from, to) => {
  if (Number.isNaN(from) || from < 1 || from > 12) from = false
  else from = Math.round(from)
  if (Number.isNaN(to) || to < 1 || to > 12) to = false
  else to = Math.round(to)

  // handle empty or single values
  if (!from && !to) {
    return []
  }
  if (from && !to) {
    return [from]
  }
  if (to && !from) {
    return [to]
  }
  if (from === to) {
    return [from]
  }
  if (to < from) {
    return []
  }

  // fill months array
  const months = []

  for (let i = 0; i < 12; i += 1) {
    let month = from + i

    if (month > 12) {
      month -= 12
    }

    months.push(month)

    if (month === to) {
      break
    }
  }

  return months
}
