import Storage from 'Modules/users/users-storage'
import axios from 'axios'
import config from '@/config'
import { get } from 'lodash'

export default {
  show: ({ commit, rootGetters }, id) => {
    commit('id', id)
    const coll = rootGetters['collections/get'](id)
    const org =
      coll && coll.organization
        ? rootGetters['organizations/get'](coll.organization)
        : rootGetters['authentication/organization']
    commit(
      'type',
      (coll && coll.contentDefaultType) ||
        (org && org.defaultContentType) ||
        null
    )
    commit('owner', (org && org.id) || null)
  },
  reset: ({ commit }) => {
    commit('report', null)
    commit('sheet', null)
    commit('mapping', {})
    commit('importedRows', undefined)
  },
  uploadFile: async ({ commit, rootGetters }, upload) => {
    commit('uploadLoading', true)
    const user = rootGetters['authentication/currentUser']
    const storage = new Storage()
    const path = `${user.uid}/upload.tmp`
    const meta = {
      contentDisposition: `attachment; filename="${upload.file.name}"`,
    }
    return new Promise((resolve, reject) => {
      storage
        .uploadTask(path, upload, meta)
        .then(resolve)
        .catch(reject)
        .then(() => commit('uploadLoading', false))
    })
  },
  analyze: async ({ dispatch, commit }, { upload }) => {
    // reset FormImport
    dispatch('reset')

    // upload file to storage
    const meta = await dispatch('uploadFile', upload)

    commit('analysisLoading', true)
    // trigger api to import uploaded file
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return new Promise((resolve, reject) => {
      axios({
        url: config.api.graphQl,
        method: 'post',
        headers: { authorization: token ? `Bearer ${token}` : '' },
        data: {
          operationName: 'UploadContents',
          query: `mutation UploadContents($file: String!, $name: String) {
          collectionUploadContents(file: $file, name: $name, sampleLength: 3) {
            name
            sheets {
              name
              rows
              columns
              captionsRow
              captions
              mappingProposal
              sample {
                index
                values
              }
            }
          }
        }`,
          variables: {
            file: meta.fullPath,
            name: upload.file.name,
          },
        },
      })
        .then((result) => {
          if (result.data.errors && result.data.errors[0]) {
            throw Error(result.data.errors[0].message)
          }
          const summary = get(
            result,
            'data.data.collectionUploadContents',
            null
          )
          if (summary) {
            summary.file = meta.fullPath
            if (summary.sheets && summary.sheets.length) {
              commit('sheet', summary.sheets[0].name)
              if (summary.sheets[0].mappingProposal instanceof Object) {
                commit('mapping', summary.sheets[0].mappingProposal)
              }
            }
          }
          commit('report', summary)
          resolve(summary)
        })
        .catch(reject)
        .then(() => commit('analysisLoading', false))
    })
  },
  execute: async ({ state, commit, dispatch }, range = null) => {
    const { id, report, sheet, mapping, owner, type } = state
    const file = report ? report.file : undefined
    commit('importedRows', undefined)
    commit('importLoading', true)
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const columnMapping = Object.keys(mapping)
      .filter((key) => !!mapping[key])
      .map((key) => ({ columnIndex: key, property: mapping[key] }))
    const skip = range ? range[0] - 1 : null
    const limit = range ? range[1] - range[0] + 1 : null
    return axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'ImportContents',
        query: `mutation ImportContents($id: ID!, $file: String!, $sheet: String!, $mapping: [ContentColumnMapping!], $skip: Int, $limit: Int, $owner: String, $type: String) {
            collectionImportContents(id: $id, file: $file, sheet: $sheet, mapping: $mapping, skip: $skip, limit: $limit, owner: $owner, type: $type)
          }`,
        variables: {
          id,
          file,
          sheet,
          mapping: columnMapping,
          skip,
          limit,
          owner,
          type,
        },
      },
    })
      .then((result) => {
        if (result.data.errors && result.data.errors[0]) {
          throw Error(result.data.errors[0].message)
        }
        const importedRows = get(
          result,
          'data.data.collectionImportContents',
          null
        )
        commit('importedRows', importedRows)
        commit('collections/showType', 'contents', { root: true })
        return importedRows
      })
      .catch((error) => {
        commit('importedRows', null)
        throw error
      })
      .finally(() => commit('importLoading', false))
  },
}
