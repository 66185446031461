import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import editInline from './editInline'
import searchReplace from './searchReplace'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    editInline,
    searchReplace,
  },
}
