import { isNil, get, isEqual, cloneDeep } from 'lodash'
import axios from 'axios'
import {
  arrayRemove,
  arrayUnion,
  deleteField,
  Timestamp,
} from 'firebase/firestore'
import { debug } from 'Shared/utils/log'
import selectRange from 'Shared/misc/select-range'
import config from '@/config'
import Database from '../../releases-db'
import Storage from '../../releases-storage'

export default {
  validateFilters: ({ state, commit, rootGetters }) => {
    if (
      state.filterOwner === 'supplier' &&
      rootGetters['authentication/isSupplier']
    )
      return true
    if (
      !state.filterOwner &&
      (rootGetters['authentication/isAdmin'] ||
        rootGetters['authentication/isSupplier'])
    )
      return true
    if (state.filterOwner === 'organization') return true
    commit('filterOwner', 'organization')
    return false
  },

  /**
   * Fetch new paginated list
   * @param context
   * @returns {Promise<void>}
   */
  newList: async ({ state, commit, getters, dispatch }) => {
    dispatch('validateFilters')
    const constraints = getters.listConstraints
    if (state.listenerAll) {
      // active listener has same constraints?
      if (isEqual(state.listConstraints, constraints)) return Promise.resolve()
      // stop listener
      state.listenerAll()
      commit('listenerAll', null)
    }
    // clear list
    commit('list', [])
    commit('selection', [])
    // set constraints
    commit('listConstraints', constraints)

    // set up listener
    const db = new Database(commit)
    await db
      .queryAndListen(constraints, 3000, false, false, '', 'list')
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
    return Promise.resolve()
  },

  /**
   * Request one from database and listen for changes
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  getOne: async ({ state, getters, commit }, id) => {
    if (state.listenerSingle[id]) {
      debug(`already listening to releases/${id}`)
      return getters.get(id)
    }
    if (state.listenerAll && state.list.includes(id)) {
      debug(`listener all covers releases/${id}`)
      return getters.get(id)
    }
    if (state.listenerPub && state.pubList.includes(id)) {
      debug(`listener for publication releases covers ${id}`)
      return getters.get(id)
    }
    debug(`add single listener for releases/${id}`)
    const db = new Database(commit)
    return db.readAndListen(id)
  },
  stopListening: ({ state, commit }, id) => {
    if (!state.listenerSingle[id]) {
      debug(`unknown single listener for releases/${id}`)
      return
    }
    debug(`stop listener for releases/${id}`)
    state.listenerSingle[id]()
    commit('removeListenerSingle', id)
  },

  getOneOnce: async ({ state, getters, commit }, id) => {
    if (state.listenerSingle[id]) return getters.get(id)
    if (state.listenerAll && state.list.includes(id)) return getters.get(id)
    if (state.listenerPub && state.pubList.includes(id)) return getters.get(id)
    const db = new Database(commit)
    return db.read(id).then((doc) => {
      // add document to all (cache)
      commit('update', doc)
      return doc
    })
  },

  getOneOnceCached: async ({ getters, dispatch }, id) => {
    if (getters.get(id)) return getters.get(id)
    return dispatch('getOneOnce', id)
  },

  getByPublication: async ({ state, commit, rootGetters }, id) => {
    if (!id) throw Error('Parameter publication ID is required')
    if (state.listenerPub && state.pubId === id) {
      debug(`already listening for releases of publication ${id}`)
      return Promise.resolve()
    }
    const releases = rootGetters['publications/getReleases'](id)
    if (releases.length) {
      const missing = releases.filter((r) => {
        if (state.listenerAll && state.list.includes(r.id)) return false
        return !state.listenerSingle[r.id]
      })
      if (missing.length === 0) {
        debug(`publication ${id} releases covered by existing listeners`)
        return Promise.resolve()
      }
    }
    if (state.listenerPub) {
      state.listenerPub()
      commit('listenerPub', null)
    }
    commit('pubId', id)
    commit('pubList', [])
    const constraints = [['publicationId', '==', id]]
    if (!rootGetters['authentication/isAdmin']) {
      if (rootGetters['authentication/isSupplier']) {
        constraints.push([
          'supplier',
          '==',
          rootGetters['authentication/organizationId'],
        ])
      } else {
        constraints.push([
          'owner',
          '==',
          rootGetters['authentication/organizationId'],
        ])
      }
    }
    const db = new Database(commit)
    await db
      .queryAndListen(constraints, 50, false, false, '', 'pubList')
      .then((f) => commit('listenerPub', f))
    return Promise.resolve()
  },
  stopListenerPublication: ({ state, commit }) => {
    if (!state.listenerPub) {
      debug(`no listener for publication releases to stop`)
      return
    }
    debug(`stop listener for publication ${state.pubId} releases`)
    state.listenerPub()
    commit('listenerPub', null)
    commit('pubId', null)
    commit('pubList', [])
  },

  selectionToggle: ({ state, getters, commit }, { id, range }) => {
    if (!id) return
    if (range) {
      selectRange(
        getters.listFiltered,
        state.selection,
        state.selectionAdded,
        id,
        (addId) => commit('selectionAdd', addId)
      )
    } else if (getters.isSelected(id)) {
      commit('selectionRemove', id)
    } else {
      commit('selectionAdd', id)
    }
  },

  /**
   * Fetch one and set as editItem
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  loadEditItem: async ({ commit, getters, dispatch }, id) => {
    commit('editDataLink', null)
    commit('editItem', null)
    commit('editIsNew', false)
    const item = cloneDeep(await dispatch('getOne', id))
    if (!getters.isEditAllowed(id)) {
      throw Error('Editing not allowed')
    }
    commit('editItem', item)
    return item
  },

  /**
   * Call loadEditItem and set as new item
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  loadEditItemCopy: async ({ commit, dispatch, rootGetters }, id) => {
    commit('editItem', null)
    commit('editIsNew', true)
    const item = await dispatch('getOneOnce', id)
    const doc = cloneDeep(item)
    delete doc.id
    doc.owner = rootGetters['authentication/organizationId']
    doc.supplier = rootGetters['authentication/supplier']
    commit('editItem', doc)
  },

  getExtendedContents: async ({ state, dispatch }, releaseId = null) => {
    const { extendContentsFrom, data } = releaseId
      ? await dispatch('getOneOnce', releaseId)
      : state.editItem
    if (!(extendContentsFrom instanceof Object) || !data) return []
    const getContents = []
    Object.keys(data).forEach((phId) => {
      const ph = data[phId]
      if (
        ph.transform === 'extend' &&
        ph.valueRaw instanceof Array &&
        extendContentsFrom[phId]
      ) {
        ph.valueRaw.forEach((contentId) =>
          getContents.push(
            dispatch('contents/getOneOnce', contentId, { root: true })
          )
        )
      }
    })
    return Promise.all(getContents)
  },

  /**
   * Set editItem to empty item template
   * @param commit
   * @param rootState
   * @param rootGetters
   * @returns {Promise<void>}
   */
  resetEditItem: async ({ commit, rootGetters }) => {
    const item = {
      number: 1,
      status: 'pending',
      owner: rootGetters['authentication/organizationId'],
      supplier: rootGetters['authentication/supplier'],
      label: '',
    }
    commit('editItem', item)
    commit('editIsNew', true)
  },

  updateEditItemDataLink: async ({ state, rootGetters, commit }) => {
    const { editItem } = state
    if (!editItem) throw Error('no editItem')
    if (!editItem.referencedContents) throw Error('no referencedContents')
    const tpl = rootGetters['templates/get'](editItem.template)
    if (!tpl || !tpl.placeholders) throw Error('no template.placeholders')
    const refs = Object.keys(editItem.referencedContents)
    if (!editItem.data) editItem.data = {}
    tpl.placeholders.forEach((ph) => {
      const key = ph.id
      const value = editItem.data[key]
      // check placeholder-content-link
      if (!value || !value.content || !refs.includes(value.content)) {
        // placeholder is not connected to content or content is not referenced in editItem
        // use first referenced contentId if available
        const newContent = refs.length ? refs[0] : null
        const { propId, lang, index, transform } = rootGetters[
          'templates/getPlaceholderProperty'
        ](editItem.template, key)
        // content id given and template has a default property link?
        const link = newContent && propId
        commit('updateEditItem', {
          path: `data.${key}.content`,
          value: link ? newContent : undefined,
        })
        commit('updateEditItem', {
          path: `data.${key}.property`,
          value: link ? propId : undefined,
        })
        commit('updateEditItem', {
          path: `data.${key}.transform`,
          value: link ? transform : undefined,
        })
        commit('updateEditItem', {
          path: `data.${key}.transformFormat`,
          value:
            link && transform === 'extend' ? ph.transformFormat : undefined,
        })
        commit('updateEditItem', {
          path: `data.${key}.lang`,
          value: link ? lang : undefined,
        })
        commit('updateEditItem', {
          path: `data.${key}.index`,
          value: link ? index : undefined,
        })
        if ((!value || !value.value) && link) {
          const path = rootGetters['contents/propLangIdxToPath'](
            propId,
            lang,
            index
          )
          const newValue = rootGetters['contents/getValue'](newContent, path)
          commit('updateEditItem', {
            path: `data.${key}.valueRaw`,
            value: newValue,
          })
          if (!(newValue instanceof Array)) {
            commit('updateEditItem', {
              path: `data.${key}.value`,
              value: newValue,
            })
          }
        }
      }
    })
  },

  fixOutdatedEditItemDataLink: async ({ getters, dispatch }) => {
    const list = getters.editItemLinks.filter((l) => !l.status)
    const dbUpdates = list.map((item) =>
      dispatch(
        'contents/updateValue',
        {
          contentId: item.contentId,
          path: item.contentPath,
          value: item.dataValueRaw,
        },
        { root: true }
      )
    )
    return Promise.all(dbUpdates)
  },

  updateFromContent: async (
    { dispatch, getters, rootGetters },
    { releaseId, content, propId = null }
  ) => {
    // release doc required to check placeholders
    const release = await dispatch('getOneOnce', releaseId).then((doc) =>
      // cache publication doc for isEditAllowed check
      dispatch('publications/getOneOnce', doc.publicationId, {
        root: true,
      }).then(() => doc)
    )
    if (!getters.isEditAllowed(releaseId))
      throw Error(`Update prohibited for release ${releaseId}`)
    if (!release.data) return Promise.resolve()
    const releaseUpdate = { id: releaseId }
    Object.entries(release.data || {}).forEach(([phId, ph]) => {
      const { content: contentId, property, lang, index } = ph
      if (
        contentId &&
        property && // contentId && property === placeholder is linked...
        content.id === contentId && // ...to this content
        (!propId || propId === property) // propId filter inactive or match
      ) {
        const valueRaw = rootGetters['contents/getValueFromData'](
          content.data,
          property,
          lang,
          index
        )
        releaseUpdate[`data.${phId}.valueRaw`] = valueRaw
        releaseUpdate[`data.${phId}.value`] = getters.transformData({
          ...ph,
          valueRaw,
        })
      }
    })
    if (Object.keys(releaseUpdate).length === 1) {
      debug('releases/updateFromContent release update empty', {
        content,
        release,
        propId,
      })
      return Promise.resolve()
    }
    return dispatch('update', releaseUpdate)
  },

  /**
   * create or update item in database
   * @param context
   * @param doc
   */
  setRaw: async ({ commit }, doc) => {
    const db = new Database(commit)
    const id = !isNil(doc.id) && doc.id ? doc.id : null
    if ('id' in doc) delete doc.id
    return db.create(doc, id)
  },

  /**
   * create or update release, append log entry
   * @param context
   * @param doc
   * @returns {Promise<*>}
   */
  set: async ({ getters, dispatch }, doc) => {
    if (!{}.hasOwnProperty.call(doc, 'log')) {
      doc.log = arrayUnion(getters.logEntry('create'))
    }
    return dispatch('setRaw', doc)
  },

  /**
   * Update item in database
   * @param context
   * @param doc
   * @returns {Promise<*>}
   */
  updateRaw: async ({ commit }, doc) => {
    if (isNil(doc.id)) throw Error('Document id is required')
    const db = new Database(commit)
    return db.update(doc)
  },

  /**
   * add collections.collectionID field
   * @param dispatch
   * @param id
   * @param collectionId
   * @returns {Promise<*>}
   */
  addCollection: async ({ dispatch }, { id, collectionId }) => {
    // check params
    if (isNil(id)) throw Error('Parameter id is required')
    if (isNil(collectionId)) throw Error('Parameter collectionId is required')

    // check if link exists
    const release = await dispatch('getOneOnce', id)
    if (get(release, `collection.${collectionId}`)) {
      // link exists
      return Promise.resolve(release)
    }

    // add collection to release
    const doc = { id }
    doc[`collections.${collectionId}`] = true
    return dispatch('updateRaw', doc)
  },

  /**
   * remove collections.collectionID field
   * @param dispatch
   * @param id
   * @param collectionId
   * @returns {Promise<*>}
   */
  removeCollection: async ({ dispatch }, { id, collectionId }) => {
    // check params
    if (isNil(id)) throw Error('Parameter id is required')
    if (isNil(collectionId)) throw Error('Parameter collectionId is required')

    // check if link exists
    const release = await dispatch('getOneOnce', id)
    if (
      !release.collections ||
      !{}.hasOwnProperty.call(release.collections, collectionId)
    ) {
      // link does not exist
      return Promise.resolve(release)
    }

    // remove collection from release
    const doc = { id }
    doc[`collections.${collectionId}`] = deleteField()
    return dispatch('updateRaw', doc)
  },

  /**
   * update release w/ log entry and releases in publication
   * @param context
   * @param doc
   * @returns {Promise<*>}
   */
  update: async ({ dispatch, getters }, doc) => {
    if (isNil(doc.id)) throw Error('doc.id is required')
    const logEntry = getters.logEntry('update')
    if (doc.log) {
      doc.log.push(logEntry)
    } else {
      doc.log = arrayUnion(logEntry)
    }
    return dispatch('updateRaw', doc)
  },

  /**
   * Copy release
   * @param context
   * @param id
   * @returns {Promise<*>}
   */
  createNewRelease: async ({ dispatch }, id) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'ReleaseCopy',
        variables: { id },
        query: `
mutation ReleaseCopy($id: ID!) {
  releaseCopy(id: $id) {
    id
    publicationId
  }
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.releaseCopy', null)
  },

  /**
   * Create a new property and reset property name input
   */
  commitEditItem: async ({ dispatch, state, commit }) => {
    if (state.editItem === null || state.updatePending) return false
    const update = state.editItem
    commit('editItem', null)
    commit('updatePending', true)
    return dispatch('set', update)
      .then((doc) => {
        if (doc && doc.id && doc.type === 'print')
          return dispatch('updateArtwork', { id: doc.id }).then(() => doc)
        return doc
      })
      .finally(() => commit('updatePending', false))
  },

  /**
   * Delete item from database
   * @param context
   * @param docId
   * @returns {Promise<*>}
   */
  delete: async ({ state, commit }, docId) => {
    if (state.listenerSingle[docId]) {
      // remove listener
      state.listenerSingle[docId]()
      commit('removeListenerSingle', docId)
    }
    const db = new Database(commit)
    return db.delete(docId)
  },

  fileUpload: async ({ getters }, { docId, upload }) => {
    const dir = getters.uploadDir(docId)
    if (!dir) throw new Error('Load document into store before adding files')
    const storage = new Storage()
    const path = `${dir}/${upload.file.name}`
    return storage.uploadTask(path, upload)
  },

  fileUploadAdd: async ({ dispatch }, { docId, upload, usage }) => {
    return dispatch('fileUpload', { docId, upload }).then((fileRef) => {
      const newFile = {
        filesize: fileRef.size,
        path: fileRef.fullPath,
        mimetype: fileRef.contentType,
        updateTimestamp: Timestamp.fromMillis(Date.parse(fileRef.updated)),
        usage,
      }
      const updateDoc = {
        id: docId,
        files: arrayUnion(newFile),
      }
      return dispatch('updateRaw', updateDoc)
    })
  },

  fileRemove: async ({ dispatch }, { docId, file }) => {
    const storage = new Storage()
    const storageRemove = storage
      .deleteFile(file.path)
      .then(() => true)
      .catch((err) => {
        if (err.code === 'storage/object-not-found') {
          return true
        }
        throw err
      })
    const releaseUpdate = new Promise((resolve, reject) => {
      const updateDoc = {
        id: docId,
        files: arrayRemove(file),
      }
      dispatch('updateRaw', updateDoc)
        .then((result) => resolve(result))
        .catch((err) => reject(err))
    })
    return Promise.all([storageRemove, releaseUpdate])
  },

  copyNewPublication: async ({ dispatch }, { id, options }) => {
    if (!id) throw Error('parameter id is required')
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const copyContents = get(options, 'copyContents', true)
    const excludeCollections = get(options, 'excludeCollections', [])
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'ReleaseCopyNewPublication',
        variables: { id, cc: copyContents, ec: excludeCollections },
        query: `
mutation ReleaseCopyNewPublication($id: ID!, $cc: Boolean, $ec: [ID!]) {
  releaseCopyNewPublication(id: $id, copyContents: $cc, excludeCollections: $ec) {
    id
    publicationId
  }
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.releaseCopyNewPublication', null)
  },

  changeStatus: async ({ dispatch }, { ids, status, message }) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'ReleaseChangeStatus',
        variables: { ids, status, message },
        query: `
mutation ReleaseChangeStatus($ids: [ID!]!, $status: ReleaseStatus!, $message: String) {
  releaseChangeStatus(ids: $ids, status: $status, message: $message)
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.releaseChangeStatus', null)
  },

  updateArtwork: async (
    { dispatch, commit },
    { id, forceRecreate = false }
  ) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return axios
      .post(
        config.api.graphQl,
        {
          operationName: 'ReleaseArtwork',
          variables: { id, forceRecreate },
          query: `
mutation ReleaseArtwork($id: ID!, $forceRecreate: Boolean) {
  releaseArtwork(id: $id, forceRecreate: $forceRecreate) {
    id
    files {
      filesize
      mimetype
      path
      updateTimestamp
      usage    
    }
  }
}
`,
        },
        {
          headers: { authorization: token ? `Bearer ${token}` : '' },
        }
      )
      .then((result) => {
        if (result.data.errors)
          throw Error(
            (result.data.errors[0] && result.data.errors[0].message) ||
              'generate artwork not successful'
          )
        get(result, 'data.data.releaseArtwork.files', []).forEach((file) =>
          commit('storageUrls/remove', file.path, { root: true })
        )
        return get(result, 'data.data.releaseArtwork', null)
      })
  },

  create: async (
    { commit, dispatch },
    { collectionId, label, data, previewDivide }
  ) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const release = { collectionId, label, data, previewDivide }
    return axios
      .post(
        config.api.graphQl,
        {
          operationName: 'ReleaseCreate',
          variables: { release },
          query: `
mutation ReleaseCreate($release: ReleaseCreateInput!) {
  releaseCreate(release: $release) {
    id
    ownerId
    supplierId
    number
    status
  }
}
`,
        },
        {
          headers: { authorization: token ? `Bearer ${token}` : '' },
        }
      )
      .then((result) => {
        if (result.data.errors)
          throw Error(
            (result.data.errors[0] && result.data.errors[0].message) ||
              'create release failed'
          )
        const doc = get(result, 'data.data.releaseCreate', null)
        if (doc) {
          doc.owner = doc.ownerId
          delete doc.ownerId
          doc.supplier = doc.supplierId
          delete doc.supplierId
          commit('update', doc)
        }
        return doc
      })
  },
}
