export default {
  // settings
  term: (state, val) => (state.term = val),
  scope: (state, val) => (state.scope = val),
  collectionId: (state, val) => (state.collectionId = val),
  contentType: (state, val) => (state.contentType = val),
  language: (state, val) => (state.language = val),

  // active search
  loading: (state, val) => (state.loading = !!val),
  active: (state, val) => (state.active = !!val),
  flush: (state) => {
    state.items = []
    state.total = 0
  },
  result: (state, val) => (state.result = val),
  total: (state, val) => (state.total = val),
  params: (state, val) => (state.params = val),
  cancel: (state, f) => (state.cancel = f),

  // selected item
  selected: (state, val) => (state.selected = val),
}
