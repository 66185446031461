import axios from 'axios'
import { get } from 'lodash'
import moment from 'moment'
import config from '@/config'
import Database from '../../deployments-db'
import Storage from '../../deployments-upload-storage'

export default {
  /**
   * Get all deployment items from server
   * @param context
   * @returns {Promise<void>}
   */
  getAll: async ({ state, commit, rootGetters }) => {
    if (
      !rootGetters['authentication/isAdmin'] &&
      !rootGetters['authentication/isSupplier'] &&
      !rootGetters['authentication/hasLicense']('digital')
    ) {
      return Promise.resolve()
    }
    const filter = rootGetters['authentication/constraints']
    if (filter && filter[0] && filter[0][0] === 'owner') {
      filter[0][0] = 'organization'
    }

    if (state.listenerAll !== null)
      return Promise.reject(Error('already listening to deployments'))
    commit('flush')
    const db = new Database(commit)
    await db
      .queryAndListen(filter, 101, 0, [['createTimestamp', 'desc']], '', 'list')
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
    return Promise.resolve()
  },

  initiateAdd: (
    { state, getters, commit, rootGetters },
    { collectionId = null, orderId = null, releaseIds = [] }
  ) => {
    if (!collectionId && !orderId)
      throw Error('one of parameters collectionId and orderId is required')
    const expiration = moment().add(4, 'weeks').toDate()
    const item = {
      collectionId,
      orderId,
      releaseIds,
      name: getters.addName(collectionId || orderId),
      nonPublishedReleases: state.addModel.nonPublishedReleases || false,
      disableCheckForNewerReleases:
        state.addModel.disableCheckForNewerReleases || false,
      expiration,
      fileNames: state.addModel.fileNames || null,
      print: {
        noCropMarks: state.addModel.print.noCropMarks || false,
        pageSettings: state.addModel.print.pageSettings || null,
        preview: state.addModel.print.preview || false,
      },
      digital: {
        imageSources: state.addModel.digital.imageSources || false,
      },
    }
    if (collectionId) {
      const collection = rootGetters['collections/get'](collectionId)
      if (collection && collection.releaseFileNames) {
        item.fileNames = collection.releaseFileNames
      }
    }
    commit('addModel', item)
    commit('dialogVisible', true)
  },

  add: async ({ state, dispatch }) => {
    const deployment = state.addModel
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'DeploymentAdd',
        variables: { deployment },
        query: `
mutation DeploymentAdd($deployment: DeploymentInput!) {
  deploymentAdd(deployment: $deployment)
}
`,
      },
    }).then((result) => {
      if (result.data.errors && result.data.errors[0]) {
        throw Error(result.data.errors[0].message)
      }
      const deploymentId = get(result, 'data.data.deploymentAdd', null)
      if (!deploymentId) {
        throw Error('Deployment ID empty')
      }
      return deploymentId
    })
  },

  download: async (context, id) => {
    return axios({
      url: config.api.graphQl,
      method: 'post',
      data: {
        operationName: 'DL',
        variables: { id },
        query: 'query DL($id:ID!){deploymentDownload(id:$id){name,link}}',
      },
    }).then((result) => {
      if (result.data.errors && result.data.errors[0]) {
        throw Error(result.data.errors[0].message)
      }
      const dl = get(result, 'data.data.deploymentDownload', {})
      if (!dl.link) throw Error('Download URL empty')
      return dl
    })
  },

  remove: async ({ commit, dispatch, getters }, id) => {
    if (getters.deletePending(id)) return getters.deletePending(id)
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const task = axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'Remove',
        variables: { id },
        query: 'mutation Remove($id:ID!){deploymentRemove(id:$id)}',
      },
    })
      .then((result) => {
        if (result.data.errors && result.data.errors[0]) {
          throw Error(result.data.errors[0].message)
        }
        if (!get(result, 'data.data.deploymentRemove', null)) {
          throw Error('Deployment was not removed')
        }
        return true
      })
      .finally(() => commit('removeDeletePending', id))
    commit('addDeletePending', { id, promise: task })
    return task
  },

  webhook: async ({ getters, dispatch }, { id, mode }) => {
    if (!id) throw Error('parameter id is required')
    const doc = getters.get(id)
    if (!doc) throw Error('deployment document not found')
    const { webhook } = doc
    if (!webhook) throw Error(`empty webhook for deployment ${id}`)
    const { link } =
      (webhook.includes('[link]') && (await dispatch('download', id))) || {}
    const replacer = (match, ph) => {
      if (ph === 'id') return id
      if (ph === 'link') return link || ''
      if (ph === 'mode') return mode || ''
      return ''
    }
    const parsed = webhook.replace(/\[(id|link|mode)]/g, replacer)
    const [url, params] = parsed.split('?', 2)
    const data = {}
    if (params) {
      params.split('&').forEach((param) => {
        const [key, value] = param.split('=', 2)
        data[key] = value
      })
    }
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return axios({
      url,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data,
    })
      .then((result) => result.data)
      .catch((error) => {
        if (error.response) {
          throw Error(`${error.response.status} ${error.response.data}`)
        }
        throw error
      })
  },

  uploadReleasePdf: async (_, { releaseId, upload }) => {
    if (!releaseId) throw new Error('Parameter releaseId is required')
    const storage = new Storage()
    const path = `${releaseId}.pdf`
    return storage.uploadTask(path, upload)
  },

  getSpecifiedReleasePdfs: async ({ state, commit }, forceRefresh) => {
    if (state.customReleasePdfs.length && !forceRefresh) {
      return state.customReleasePdfs
    }
    const storage = new Storage()
    return storage.listFiles().then((list) => {
      commit('customReleasePdfs', list)
      return list
    })
  },

  downloadReleasePdf: async (_, releaseId) => {
    if (!releaseId) throw new Error('Parameter releaseId is required')
    const storage = new Storage()
    return storage.downloadUrl(`${releaseId}.pdf`)
  },

  deleteReleasePdf: async (_, releaseId) => {
    if (!releaseId) throw new Error('Parameter releaseId is required')
    const storage = new Storage()
    return storage.deleteFile(`${releaseId}.pdf`)
  },
}
