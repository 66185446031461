import { isNaN } from 'lodash'

export default {
  /* get single item */
  get: (state) => (id) => (id && state.all[id]) || null,
  getByRelease: (state) => (id) => {
    const basket = Object.values(state.all)
    if (!basket.length) return null
    const item = basket.find((el) => el.releaseId === id)
    return item || null
  },
  getMin: (state, getters) => (id) => {
    const item = getters.get(id)
    return (item && item.min) || 1
  },
  getPrice: (state, getters) => (id) => {
    const item = getters.get(id)
    return item && item.price && !isNaN(parseFloat(item.price))
      ? parseFloat(item.price)
      : 0
  },
  getPriceReference: (state, getters) => (id) => {
    const item = getters.get(id)
    return (item && item.priceReference) || 1
  },
  getQuantity: (state, getters) => (id) => {
    const item = getters.get(id)
    return (item && item.quantity) || 0
  },
  getQuantityByRelease: (state, getters) => (id) => {
    const item = getters.getByRelease(id)
    return (item && item.quantity) || 0
  },
  getStep: (state, getters) => (id) => {
    const item = getters.get(id)
    return (item && item.step) || 1
  },
  getSum: (state, getters) => (id) => {
    return (
      (getters.getQuantity(id) * getters.getPrice(id)) /
      getters.getPriceReference(id)
    )
  },
  totalPositions: (state) => state.list.length,
  totalPrice: (state, getters) => {
    const prices = state.list.map((id) => getters.getSum(id))
    if (Math.min(...prices) <= 0) return 0
    return prices.reduce((sum, el) => sum + el, 0)
  },
  totalQuantity: (state, getters) => {
    const quantities = state.list.map((id) => getters.getQuantity(id))
    return quantities.reduce((sum, el) => sum + el, 0)
  },
  list: (state, getters) => state.list.map(getters.get),
  releaseToItemData:
    (state, getters, rootState, rootGetters) =>
    (release, increase = false) => {
      const basketItem = getters.getByRelease(release.id)
      const templateId = release.template || null
      const template = rootGetters['templates/get'](templateId)
      const min = (template && template.orderQuantityMin) || 1
      const step = (template && template.orderQuantityStep) || 1
      const price = (template && template.orderPrice) || 0
      const priceReference = (template && template.orderPriceReference) || 1
      let quantity = (basketItem && basketItem.quantity) || 0
      if (increase) quantity += 1
      quantity = Math.max(min, quantity)
      const overStep = quantity % step
      if (overStep) {
        quantity += step - overStep
      }
      return {
        quantity,
        min,
        step,
        price,
        priceReference,
        template: templateId,
      }
    },
}
