export default {
  all: {},
  list: [],
  listenerAll: null,
  loading: null,
  active: null,
  dialogVisible: false,
  addModel: {
    collectionId: null,
    releaseIds: [],
    name: '',
    nonPublishedReleases: false,
    disableCheckForNewerReleases: false,
    expiration: null,
    fileNames: null,
    print: {
      noCropMarks: false,
      pageSettings: null,
      preview: false,
    },
    digital: {
      imageSources: false,
    },
  },
  deletePending: {},
  customReleasePdfs: [],
}
