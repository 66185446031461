import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import members from './members'
import settings from './settings'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: { members, settings },
}
