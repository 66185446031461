export default [
  {
    path: '/search',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    children: [
      {
        path: '/search',
        component: () =>
          import(/* webpackChunkName: "client-chunk-search" */ './search.vue'),
        name: 'search',
        hidden: true,
        meta: {
          title: 'search.title.general',
          icon: 'el-icon-search',
          noCache: true,
        },
      },
    ],
  },
]
