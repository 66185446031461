import { error as logError } from 'Shared/utils/log'

export default {
  getOne: async (
    { getters, commit, state, dispatch },
    { templateId, name }
  ) => {
    const id = getters.getId({ templateId, name })
    if (getters.isLoading(id)) return state.loading[id]

    const request = dispatch(
      'artwork/_request',
      {
        url: `/templates/${templateId}/assets/${name}`,
      },
      { root: true }
    )
      .then((response) => {
        if (response.data.success && response.data.data) {
          const item = { id, ...response.data.data }
          commit('update', item)
          return item
        }
        return null
      })
      .catch((error) => {
        logError(error)
        return null
      })
      .finally(() => commit('unsetLoading', id))
    commit('setLoading', { id, request })
    return request
  },
  update: async ({ dispatch }, { templateId, name, newName }) => {
    if (!templateId) throw Error('Parameter templateId is required')
    if (!name) throw Error('Parameter name is required')
    if (!newName) throw Error('Parameter newName is required')
    return dispatch(
      'artwork/_request',
      {
        url: `/templates/${templateId}/assets/${name}`,
        method: 'patch',
        data: { name: newName },
      },
      { root: true }
    ).then((response) => {
      if (!response.data.success) {
        throw Error(response.data.error || 'Update not successful')
      }
      dispatch('artwork/getOne', templateId, { root: true })
      return Promise.resolve()
    })
  },
  delete: async ({ dispatch }, { templateId, name }) => {
    if (!templateId) throw Error('Parameter templateId is required')
    if (!name) throw Error('Parameter name is required')
    return dispatch(
      'artwork/_request',
      {
        url: `/templates/${templateId}/assets/${name}`,
        method: 'delete',
      },
      { root: true }
    ).then((response) => {
      if (!response.data.success) {
        throw Error(response.data.error || 'Delete not successful')
      }
      dispatch('artwork/getOne', templateId, { root: true })
      return Promise.resolve()
    })
  },
}
