const settings1000 = [
  { decimals: 0, prefix: '' },
  { decimals: 0, prefix: 'k' },
  { decimals: 1, prefix: 'M' },
  { decimals: 2, prefix: 'G' },
  { decimals: 3, prefix: 'T' },
  { decimals: 3, prefix: 'P' },
]

const settings1024 = [
  { decimals: 0, prefix: '' },
  { decimals: 0, prefix: 'Ki' },
  { decimals: 1, prefix: 'Mi' },
  { decimals: 2, prefix: 'Gi' },
  { decimals: 3, prefix: 'Ti' },
  { decimals: 3, prefix: 'Pi' },
]

module.exports = (bytes, decimalPowers = false, nonByte = false) => {
  // type check
  if (!Number.isInteger(bytes)) return '?'

  // get settings
  const settings = decimalPowers ? settings1000 : settings1024

  // empty check
  if (bytes < 1) return nonByte ? '0' : '0 B'

  const exp = decimalPowers ? 1000 : 1024

  // calc dimension
  let i = Math.floor(Math.log(bytes) / Math.log(exp))

  // check settings for this dim
  if (i >= settings.length) i = settings.length - 1

  const decimals =
    nonByte && i ? settings[i].decimals + 1 : settings[i].decimals
  const unit = nonByte ? settings[i].prefix : `${settings[i].prefix}B`

  // calc value, format as string
  const value = (bytes / exp ** i).toFixed(decimals)

  return `${value} ${unit}`.trim()
}
