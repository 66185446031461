export default [
  {
    path: '/login',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout2" */ '@/app/layout/horizontal.vue'
      ),
    children: [
      {
        path: '/login',
        name: 'login',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-auth" */ './authentication.vue'
          ),
        meta: {
          title: 'authentication.title.login',
          icon: 'las la-home',
          authNotRequired: true,
        },
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-auth" */ './password-reset/password-reset.vue'
          ),
        props: { form: 'password-reset' },
        hidden: true,
        meta: {
          title: 'authentication.title.passwordReset',
          authNotRequired: true,
        },
      },
      {
        path: '/register',
        name: 'register',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-auth" */ './register/register.vue'
          ),
        props: { form: 'register' },
        hidden: true,
        meta: {
          title: 'authentication.title.register',
          authNotRequired: true,
        },
      },
      {
        path: '/confirm',
        name: 'email-action',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-auth-email" */ './email-action/email-action.vue'
          ),
        hidden: true,
        meta: {
          authNotRequired: true,
        },
      },
    ],
  },
]
