import { filter, isNil, cloneDeep } from 'lodash'

export default {
  /* listening to all documents? */
  isListeningToAll: (state) => state.listenerAll !== null,

  /* Check if an item has deletion pending */
  isDeletionPending: (state) => (id) =>
    Object.prototype.hasOwnProperty.call(state.deletionPending, id),

  all: (state) => Object.values(state.all),

  rootEntries: (state, getters) => {
    return filter(getters.all, (e) => isNil(e.parentId))
  },

  children: (state, getters) => (parentId) => {
    return filter(getters.all, (e) => e.parentId === parentId)
  },

  tree: (state, getters) => {
    const tree = cloneDeep(getters.rootEntries)
    tree.forEach((e) => {
      const childIds = e.children ? Object.keys(e.children) : []
      e.children = childIds.map((c) => cloneDeep(state.all[c]))
    })
    return tree
  },

  get: (state) => (id) => {
    return state.all[id] || null
  },
}
