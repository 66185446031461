export default {
  all: {},
  list: [],
  filterStatuses: [],

  active: null,

  editItem: {},
  editIsNew: true,

  listenerSingle: {},
  listenerAll: null,
  listenerAllConstraints: null,
  loading: false,
}
