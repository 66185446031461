import Vue from 'vue'
import vueSetPath from 'Shared/store/_partials/vueSetPath'

export default {
  /* all */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  remove: (state, item) => Vue.delete(state.all, item.id),

  /* active */
  active: (state, active) => (state.active = active),

  /* editItem */
  editItem: (state, item) => (state.editItem = item),
  updateEditItem: (state, { path, value }) =>
    vueSetPath(state.editItem, path, value),

  /* editShow */
  editShow: (state, show) => (state.editShow = show),

  /* editIsNew */
  editIsNew: (state, val) => (state.editIsNew = val),

  /* search */
  search: (state, val) => (state.search = val),

  /* handle firestore listeners for changes */
  addListenerSingle: (state, { id, unsubscribe }) =>
    Vue.set(state.listenerSingle, id, unsubscribe),
  removeListenerSingle: (state, id) => Vue.delete(state.listenerSingle, id),
  listenerAll: (state, func) => (state.listenerAll = func),

  loading: (state, val) => (state.loading = !!val),
}
