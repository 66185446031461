export const log = (level, msg, vars) => {
  if (process.env.NODE_ENV === 'production') return

  const args = [msg]
  if (vars !== undefined) args.push(vars)

  switch (level) {
    case 'error':
      console.error(...args)
      break
    case 'warn':
      console.warn(...args)
      break
    case 'info':
      console.info(...args)
      break
    default:
      console.log(...args)
  }
}

export const error = (msg, vars) => log('error', msg, vars)
export const warn = (msg, vars) => log('warn', msg, vars)
export const info = (msg, vars) => log('info', msg, vars)
export const debug = (msg, vars) => log('debug', msg, vars)
