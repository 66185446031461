import { isNil } from 'lodash'
import Database from '../../notifications-db'

export default {
  /**
   * Get all basket items from server
   * @param context
   * @returns {Promise<void>}
   */
  getAll: async ({ state, commit }) => {
    if (state.listenerAll !== null)
      return Promise.reject(Error('already listening to notifications'))
    commit('flush')
    const db = new Database(commit)
    await db
      .queryAndListen([], 21, 0, [['createTimestamp', 'desc']], '', 'list')
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
    return Promise.resolve()
  },

  /**
   * Update item in database
   * @param context
   * @param doc
   * @returns {Promise<*>}
   */
  update: async ({ commit }, doc) => {
    if (isNil(doc.id)) return false
    const db = new Database(commit)
    return db.update(doc)
  },

  /**
   * Delete item from database
   * @param context
   * @param docId
   * @returns {Promise<*>}
   */
  delete: async ({ commit }, docId) => {
    const db = new Database(commit)
    return db.delete(docId)
  },
}
