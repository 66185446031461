<template>
  <div class="tw-h-1.5 tw-relative tw-bg-zinc-200 tw-rounded-full">
    <div
      class="tw-absolute tw-left-0 tw-inset-y-0 tw-rounded-full"
      :class="progressClass"
      :style="{ width: `${progress}%` }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'MfProgress',
  props: {
    progress: Number,
    progressClass: {
      type: [String, Array, Object],
      default: 'tw-bg-green-500',
    },
  },
}
</script>
