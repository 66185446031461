import Vue from 'vue'

export default {
  /* list */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  remove: (state, item) => Vue.delete(state.all, item.id),
  list: (state, list) => (state.list = list),
  loading: (state, val) => (state.loading = !!val),

  /* handle firestore listeners for changes */
  addListenerSingle: (state, { id, unsubscribe }) =>
    Vue.set(state.listenerSingle, id, unsubscribe),
  removeListenerSingle: (state, id) => Vue.delete(state.listenerSingle, id),
  listenerAll: (state, func) => (state.listenerAll = func),
}
