import Vue from 'vue'
import vueSetPath from 'Shared/store/_partials/vueSetPath'

export default {
  /* all */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  remove: (state, item) => Vue.delete(state.all, item.id),

  /* list items with filter */
  list: (state, list) => {
    state.list = list
    const constraints = state.listConstraints
    // check if list is filtered by type
    if (constraints.find(([prop]) => prop === 'type')) return
    // check if list is filtered by collection
    const filter = constraints.find(([prop]) => prop.startsWith('collections.'))
    if (!filter) return
    const collectionId = filter[0].substr(12)
    // also update listCollection[collectionId].list
    if (!state.listCollection[collectionId]) return
    Vue.set(state.listCollection[collectionId], 'lastUpdate', new Date())
    Vue.set(state.listCollection[collectionId], 'list', list)
  },
  listCollection: (state, { id, item }) =>
    Vue.set(state.listCollection, id, item),
  listConstraints: (state, value) => (state.listConstraints = value),
  loading: (state, value) => (state.loading = !!value),
  filterType: (state, value) => (state.filterType = value),
  filterOwner: (state, value) => (state.filterOwner = value),
  filterCollection: (state, value) => (state.filterCollection = value),
  filterShowVariants: (state, val) => (state.filterShowVariants = !!val),
  filterShowInactive: (state, val) => (state.filterShowInactive = !!val),
  filterLanguages: (state, val) => (state.filterLanguages = val),

  /* list order */
  listOrderProp: (state, val) => (state.listOrderProp = val),
  listOrderDir: (state, val) => (state.listOrderDir = val),

  /* list columns */
  columns: (state, value) => Vue.set(state, 'columns', value),
  showEditColumns: (state, value) => (state.showEditColumns = !!value),
  listScrollPosition: (state, { collectionId, position }) =>
    Vue.set(state.listScrollPosition, collectionId, position),

  /* editing */
  active: (state, active) => (state.active = active),
  editItem: (state, item) => (state.editItem = item),
  updateEditItem: (state, { path, value }) =>
    vueSetPath(state.editItem, path, value),
  editShow: (state, show) => (state.editShow = show),
  editIsNew: (state, val) => (state.editIsNew = val),
  editLanguagePrimary: (state, lang) => (state.editLanguagePrimary = lang),
  editLanguageSecondary: (state, lang) => (state.editLanguageSecondary = lang),
  editTranslations: (state, prop) => (state.editTranslations = prop),

  /* releases' contents list */
  relId: (state, id) => (state.relId = id),
  relList: (state, list) => (state.relList = list),

  /* firestore unsubscribe functions for active listeners */
  listenerAll: (state, unsubscribe) => (state.listenerAll = unsubscribe),
  addListenerSingle: (state, { id, unsubscribe }) =>
    Vue.set(state.listenerSingle, id, unsubscribe),
  removeListenerSingle: (state, id) => Vue.delete(state.listenerSingle, id),
  listenerRel: (state, f) => (state.listenerRel = f),

  /* deletionPending */
  addDeletionPending: (state, id) => Vue.set(state.deletionPending, id, true),
  removeDeletionPending: (state, id) => delete state.deletionPending[id],
}
