const roleValue = {
  admin: 4,
  owner: 3,
  manager: 2,
  member: 1,
}
const roleToInt = (role) => (role && roleValue[role]) || 0

export default {
  isDeletionPending: (state) => (id) =>
    Object.prototype.hasOwnProperty.call(state.deletionPending, id),

  get: (state) => (id) => state.all[id] || null,

  label: (state, getters) => (id) => {
    const user = getters.get(id)
    return user ? user.displayName || user.email : id
  },

  all: (state) => Object.values(state.all),

  byOrganization: (state, getters) => (organization) => {
    return getters.all
      .filter((user) => user.organization === organization)
      .sort((a, b) => {
        const roleDiff = roleToInt(b.role) - roleToInt(a.role)
        if (roleDiff === 0) {
          if (a.email > b.email) return 1
          if (a.email < b.email) return -1
        }
        return roleDiff
      })
  },
}
