import Vue from 'vue'
import Storage from 'Shared/firebase/generic-storage'

const storage = new Storage()

const mutations = {
  flush: (state) => (state.all = {}),
  update: (state, { path, url }) => Vue.set(state.all, path, url),
  remove: (state, path) => Vue.delete(state.all, path),

  addReading: (state, { path, promise }) =>
    Vue.set(state.reading, path, promise),
  removeReading: (state, path) => Vue.delete(state.reading, path),

  addError: (state, { path, error }) => Vue.set(state.error, path, error),
  removeError: (state, path) => Vue.delete(state.error, path),
}

const actions = {
  get: async ({ state, commit, getters }, path) => {
    if (state.all[path]) return state.all[path]
    if (state.reading[path]) return state.reading[path]
    if (getters.hasError(path)) commit('removeError', path)
    const getPath = storage
      .downloadUrl(path)
      .then((url) => {
        commit('update', { path, url })
        return url
      })
      .catch((error) => {
        commit('addError', { path, error })
        throw error
      })
      .finally(() => commit('removeReading', path))
    commit('addReading', { path, promise: getPath })
    return getPath
  },
}

export default {
  namespaced: true,
  state: {
    all: {},
    reading: {},
    error: {},
  },
  getters: {
    get: (state) => (path) => (path && state.all[path]) || null,
    getError: (state) => (path) => (path && state.error[path]) || null,
    hasError: (state, getters) => (path) => !!getters.getError(path),
    isReading: (state) => (path) => !!(path && state.reading[path]),
  },
  mutations,
  actions,
}
