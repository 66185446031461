import Vue from 'vue'

export default {
  /* all */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  remove: (state, item) => Vue.delete(state.all, item.id),

  list: (state, list) => (state.list = list),

  listenerAll: (state, value) => (state.listenerAll = value),
  loading: (state, value) => (state.loading = value),
}
