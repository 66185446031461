import newReleaseAllowed from 'Modules/releases/shared/permissions/new-release-allowed'
import orderAllowed from 'Modules/releases/shared/permissions/order-allowed'

export default {
  /* Check if an item has deletion pending */
  isDeletionPending: (state) => (id) =>
    Object.prototype.hasOwnProperty.call(state.deletionPending, id),

  /* get single item */
  get: (state) => (id) => {
    return id && state.all && state.all[id] ? state.all[id] : null
  },
  getReleases: (state, getters) => (pId) => {
    const publication = getters.get(pId)
    if (!publication || !publication.releases) return []
    return Object.entries(publication.releases)
      .map(([id, data]) => ({ id, ...data }))
      .sort((a, b) => a.number - b.number)
  },
  getPreviewPath: (state, getters) => (id) => {
    const publication = getters.get(id)
    if (!publication) return -1
    if (!publication.preview) return null
    return publication.preview
  },

  getLatestRelease: (state, getters) => (id) => {
    const releases = getters.getReleases(id)
    return releases.length ? releases[releases.length - 1] : null
  },
  getLatestPublishedRelease: (state, getters) => (id) => {
    const pub = getters.getReleases(id).filter((r) => r.status === 'published')
    return pub.length ? pub[pub.length - 1] : null
  },
  getSalableRelease: (state, getters) => (id) => {
    const { type } = getters.get(id)
    const sale = getters
      .getReleases(id)
      .filter((r) => orderAllowed({ type, ...r }))
    return sale.length ? sale[sale.length - 1] : null
  },
  getEditableRelease: (state, getters, rootState, rootGetters) => (id) => {
    const r = getters.getLatestRelease(id)
    if (!r) return null
    if (r.status === 'pending') return r
    if (
      (r.status === 'reviewAndPublish' || r.status === 'review') &&
      rootGetters['authentication/isAdminOrSupplier']
    )
      return r
    return null
  },
  isNewReleaseAllowed: (state, getters) => (id) => {
    const pub = getters.get(id)
    if (!pub) return false
    return newReleaseAllowed(pub)
  },
  listIds: (state, getters) => {
    const { listPage, listPerPage, filterType } = state
    const start = (listPage - 1) * listPerPage
    const end = start + listPerPage
    let { list } = state
    if (state.filterType) {
      list = list.filter((id) => {
        const item = getters.get(id)
        if (!item || !item.type) return false
        return filterType.includes(item.type)
      })
    }
    return list.slice(start, end)
  },
  list: (state, getters) => getters.listIds.map(getters.get),
  listConstraints: (state, getters, rootState, rootGetters) => {
    const constraints = []
    if (state.filterOwner === 'organization') {
      constraints.push([
        'owner',
        '==',
        rootGetters['authentication/organizationId'],
      ])
    } else if (state.filterOwner === 'supplier') {
      constraints.push([
        'supplier',
        '==',
        rootGetters['authentication/organizationId'],
      ])
    }
    return constraints
  },
}
