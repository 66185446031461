import Vue from 'vue'
import vueSetPath from '../../../../shared/store/_partials/vueSetPath'

export default {
  /* all */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  remove: (state, item) => Vue.delete(state.all, item.id),
  listenerAll: (state, func) => (state.listenerAll = func),

  addListenerSingle: (state, { id, unsubscribe }) =>
    Vue.set(state.listenerSingle, id, unsubscribe),
  removeListenerSingle: (state, id) => Vue.delete(state.listenerSingle, id),

  /* active */
  active: (state, active) => (state.active = active),

  /* editItem */
  editItem: (state, item) => (state.editItem = item),
  updateEditItem: (state, { path, value }) =>
    vueSetPath(state.editItem, path, value),
  removeGroupProperty: (state, { group, property = null }) => {
    if (
      !(state.editItem instanceof Object) ||
      !Object.prototype.hasOwnProperty.call(state.editItem, 'groups') ||
      !(state.editItem.groups instanceof Array)
    )
      return
    const grpIdx = state.editItem.groups.findIndex((g) => g.id === group)
    if (grpIdx === -1) return
    if (property === null) {
      Vue.delete(state.editItem.groups, grpIdx)
    } else {
      const groupEl = state.editItem.groups[grpIdx]
      if (
        !(groupEl instanceof Object) ||
        !Object.prototype.hasOwnProperty.call(groupEl, 'properties') ||
        !(groupEl.properties instanceof Array)
      )
        return
      const propIdx = groupEl.properties.findIndex((p) => p.id === property)
      if (propIdx === -1) return
      Vue.delete(groupEl.properties, propIdx)
    }
  },

  /* deletionPending */
  addDeletionPending: (state, id) => Vue.set(state.deletionPending, id, true),
  removeDeletionPending: (state, id) => Vue.delete(state.deletionPending, id),

  loading: (state, value) => (state.loading = !!value),
}
