const displayFilePath = /(-(\d{1,2})?([lrtb])?)?\.png$/

export const displayFiles = (release) => {
  if (!release) return undefined
  if (!release.files || !(release.files instanceof Array)) return null
  return release.files
    .filter(
      (file) => file.usage === 'display' && String(file.path).endsWith('.png')
    )
    .sort((a, b) => {
      const matchA = String(a.path || '').match(displayFilePath)
      const matchB = String(b.path || '').match(displayFilePath)
      if (matchA[1] && !matchB[1]) return 1
      if (!matchA[1] && matchB[1]) return -1
      const numA = matchA[2] ? parseInt(matchA[2], 10) : 0
      const numB = matchB[2] ? parseInt(matchB[2], 10) : 0
      if (numA === numB && matchA[3] !== matchB[3]) {
        return String(matchA[3]).localeCompare(String(matchB[3]))
      }
      return numA - numB
    })
}

export const defaultDisplayFile = (release) => {
  const file = displayFiles(release)
  return file && file[0]
}

export default defaultDisplayFile
