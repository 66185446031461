import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import assets from './assets'
import fonts from './fonts'
import hyphens from './hyphens'
import tests from './tests'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    assets,
    fonts,
    hyphens,
    tests,
  },
}
