<template>
  <div id="app">
    <router-view />
    <shared-app-update-notification />
  </div>
</template>

<script>
import SharedAppUpdateNotification from 'Shared/components/AppUpdateNotification.vue'

export default {
  name: 'App',
  components: { SharedAppUpdateNotification },
}
</script>
