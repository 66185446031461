export default [
  {
    path: '/home',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    children: [
      {
        path: '/home',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-collections" */ './collections-dashboard/collections-dashboard.vue'
          ),
        name: 'dashboard',
        props: { view: 'dashboard' },
        meta: {
          title: 'collections.title.dashboard',
          icon: 'las la-home',
          affix: true,
        },
      },
    ],
  },
  {
    path: '/collections',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    children: [
      {
        path: '/collections-table',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-collections" */ './collections-list/collections-list.vue'
          ),
        name: 'collections-table',
        hidden: true,
        meta: {
          title: 'collections.title.list',
          icon: 'las la-th-large',
        },
      },
      {
        path: '/collections',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-collections" */ './collections-tree/collections-tree.vue'
          ),
        name: 'collections',
        meta: {
          title: 'collections.title.list',
          icon: 'las la-th-large',
          affix: true,
        },
      },
      {
        path: '/collections/by-organization/:organization',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-collections" */ './collections-tree/collections-tree.vue'
          ),
        name: 'collections-org',
        props: true,
        hidden: true,
        meta: {
          title: 'collections.title.byOrganization',
          noCache: true,
          icon: 'las la-filter',
        },
      },
      {
        path: '/collections/by-organization/:organization/:collection',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-collections" */ './collections-tree/collections-tree.vue'
          ),
        name: 'collections-org-show',
        props: true,
        hidden: true,
        meta: {
          title: 'collections.title.list',
          noCache: true,
          icon: 'las la-filter',
        },
      },
      {
        path: '/collections/:collection/edit',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-collections" */ './collection.vue'
          ),
        name: 'collection-edit',
        props: { action: 'edit' },
        hidden: true,
        meta: {
          title: 'button.edit',
          noCache: true,
          icon: 'las la-folder-open',
        },
      },
      {
        path: '/collections/:collectionId/workflow',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-collections" */ './collections-workflow/collections-workflow.vue'
          ),
        name: 'collection-workflow',
        hidden: true,
        props: true,
        meta: {
          title: 'collections.title.workflowGeneral',
          noCache: true,
          icon: 'las la-upload',
        },
      },
      {
        path: '/collections/:collection',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-collections" */ './collection.vue'
          ),
        name: 'collection-show',
        props: { action: 'show' },
        hidden: true,
        meta: {
          title: 'collections.title.showGeneral',
          noCache: true,
          icon: 'las la-folder-open',
        },
      },
    ],
  },
]
