class BarcodeFont {
  /**
   * BarcodeFont constructor.
   */
  constructor(
    convertA,
    convertB,
    convertC,
    convert0 = '0123456789',
    patternL = '(',
    patternC = '*',
    patternR = '('
  ) {
    this.convertA = convertA
    this.convertB = convertB
    this.convertC = convertC
    this.convert0 = convert0
    this.patternL = patternL
    this.patternC = patternC
    this.patternR = patternR
  }

  /**
   * Convert single digit according to encoding
   */
  convertDigit(digit, encoding = null) {
    if (encoding === 'A') return this.convertA[digit]
    if (encoding === 'B') return this.convertB[digit]
    if (encoding === 'C') return this.convertC[digit]
    return this.convert0[digit]
  }

  /**
   * Convert EAN digits to barcode font chars
   */
  convert(content) {
    /** @var string $str return value */
    let str = ''

    // get first digit
    const first = parseInt(content[0], 10)

    // append letter for first digit to return string
    str += this.convertDigit(first)

    // set parity encoding according to first digit
    const encoding = BarcodeFont.encodings[first]

    // add left pattern
    str += this.patternL

    // handle digits 1-6
    for (let i = 0; i < 6; i += 1) {
      const digit = parseInt(content[i + 1], 10)
      str += this.convertDigit(digit, encoding[i])
    }

    // add center pattern
    str += this.patternC

    // handle digits 7-13
    for (let i = 6; i < 12; i += 1) {
      const digit = parseInt(content[i + 1], 10)
      str += this.convertDigit(digit, encoding[i])
    }

    // add right pattern
    str += this.patternR

    // done
    return str
  }
}

/** EAN-13 variable parity encoding */
BarcodeFont.encodings = {
  0: 'AAAAAACCCCCC',
  1: 'AABABBCCCCCC',
  2: 'AABBABCCCCCC',
  3: 'AABBBACCCCCC',
  4: 'ABAABBCCCCCC',
  5: 'ABBAABCCCCCC',
  6: 'ABBBAACCCCCC',
  7: 'ABABABCCCCCC',
  8: 'ABABBACCCCCC',
  9: 'ABBABACCCCCC',
}

export default (value, font = 'barcode') => {
  const valueNumbers = String(value).replaceAll(/\D/g, '')
  if (valueNumbers.length !== 13) return value
  let converter
  if (font === 'barcodeEanMac') {
    converter = new BarcodeFont(
      'ABCDEFGHIJ',
      'LMNOPQRSTU',
      'abcdefghij',
      '01234567890',
      '<',
      '=',
      '>'
    )
  } else {
    converter = new BarcodeFont(
      '01234567890',
      'ABCDEFGHIJ',
      'KLMNOPQRST',
      'UVWXYuvwxy'
    )
  }
  return converter.convert(valueNumbers)
}
