import Vue from 'vue'
/**
 * @TODO change element-ui locale with i18n locale
 */
import locale from 'element-ui/lib/locale/lang/de'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element from 'element-ui'
import '@/assets/css/app.scss' // global css

Vue.use(Element, { locale })
