import { clone } from 'lodash'
import i18n from '@/plugins/i18n'

const regexPathHasIndex = /\.(\d+)$/

export default {
  sheets: (state) => {
    if (!state.report || !state.report.sheets) return {}
    const sheets = {}
    state.report.sheets.forEach((sheet) => {
      sheets[sheet.name] = sheet
    })
    return sheets
  },
  sheet: (state, getters) =>
    (state.sheet && getters.sheets && getters.sheets[state.sheet]) || null,
  rows: (state, getters) => (getters.sheet && getters.sheet.rows) || null,
  captionsRow: (state, getters) =>
    (getters.sheet && getters.sheet.captionsRow) || null,
  captions: (state, getters) =>
    (getters.sheet && getters.sheet.captions) || null,
  columnsForMapping: (state, getters) =>
    getters.captions.map((caption, index) => ({
      id: `column_${index}`,
      caption,
      column: i18n.tc('collections.importData.title.column', index + 1),
      sample: (getters.sheet && getters.sheet.sample) || [],
      mapping: state.mapping[index],
      placeholders: getters.findPlaceholders(state.mapping[index]),
    })),
  mappingProposal: (state, getters) =>
    (getters.sheet && getters.sheet.mappingProposal) || null,
  sample:
    (state, getters) =>
    (index = 0) =>
      (getters.sheet && getters.sheet.sample && getters.sheet.sample[index]) ||
      null,
  findPlaceholders: (state, getters) => (path) => {
    if (!path) return null
    return getters.placeholders.filter((ph) => {
      if (ph.property === path) return true
      if (!regexPathHasIndex.test(ph.property)) {
        return false
      }
      const found = regexPathHasIndex.exec(ph.property)
      return ph.property === `${path}.${found[1]}`
    })
  },
  placeholders: (state, getters, rootState, rootGetters) => {
    const collectionId = state.id
    const collection = rootGetters['collections/get'](collectionId)
    const templateId = collection && collection.releaseDefaultTemplate
    const template = rootGetters['templates/get'](templateId)
    return (template && template.placeholders) || []
  },
  placeholdersWithMappingStatus: (state, getters) => {
    const mapping = {}
    Object.values(state.mapping).forEach((path) => (mapping[path] = true))
    return getters.placeholders.map((ph) => {
      ph = clone(ph)
      ph.isMapped = false
      if (mapping[ph.property]) {
        ph.isMapped = true
      } else if (regexPathHasIndex.test(ph.property)) {
        const match = regexPathHasIndex.exec(ph.property)
        const propName = ph.property.slice(0, -match[0].length)
        if (mapping[propName]) {
          ph.isMapped = true
        }
      }
      return ph
    })
  },
}
