import defaultSettings from '../../misc/settings'

const { showSettings, tagsView, fixedHeader, sidebarLogo } = defaultSettings

const STATE = {
  showSettings,
  tagsView,
  fixedHeader,
  sidebarLogo,
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value
    }
  },
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
}

const getters = {}

export default {
  namespaced: true,
  state: STATE,
  mutations,
  actions,
  getters,
}
