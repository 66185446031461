import { moduleLicenseValue } from 'Shared/misc/license-modules'

export default [
  {
    path: '/orders',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    name: 'orders',
    children: [
      {
        path: '/order/:step(basket|checkout|thank-you)',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-order-new" */ './order-new/order-new.vue'
          ),
        name: 'order-new',
        hidden: true,
        props: true,
        meta: {
          title: 'orders.title.general',
          icon: 'las la-shopping-cart',
          noCache: true,
          license: moduleLicenseValue('print'),
        },
      },
      {
        path: '/orders/:orderId',
        component: () =>
          import(/* webpackChunkName: "client-chunk-orders" */ './orders.vue'),
        name: 'order-show',
        props: true,
        hidden: true,
        meta: {
          title: 'orders.title.showId',
          icon: 'las la-shopping-cart',
          noCache: true,
          license: moduleLicenseValue('print'),
          activeMenu: '/orders',
        },
      },
      {
        path: '/orders',
        component: () =>
          import(/* webpackChunkName: "client-chunk-orders" */ './orders.vue'),
        name: 'orders-list',
        meta: {
          title: 'orders.title.list',
          icon: 'las la-clipboard-list',
          license: moduleLicenseValue('print'),
        },
      },
    ],
  },
]
