import Vue from 'vue'
import vueSetPath from 'Shared/store/_partials/vueSetPath'

export default {
  /* all */
  flush: (state) => (state.all = {}),
  update: (state, item) => Vue.set(state.all, item.id, item),
  remove: (state, item) => Vue.delete(state.all, item.id),

  /* list items */
  list: (state, list) => (state.list = list),
  listConstraints: (state, value) => (state.listConstraints = value),
  loading: (state, value) => (state.loading = value),

  /* list pagination */
  listPage: (state, value) => (state.listPage = value),
  listPerPage: (state, value) => (state.listPerPage = value),

  /* list filters */
  filterType: (state, value) => (state.filterType = value),
  filterOwner: (state, value) => (state.filterOwner = value),

  /* editing */
  active: (state, active) => (state.active = active),
  editItem: (state, item) => (state.editItem = item),
  updateEditItem: (state, { path, value }) =>
    vueSetPath(state.editItem, path, value),
  editIsNew: (state, val) => (state.editIsNew = val),

  /* deletionPending */
  addDeletionPending: (state, id) => Vue.set(state.deletionPending, id, true),
  removeDeletionPending: (state, id) => Vue.delete(state.deletionPending, id),

  /* firestore unsubscribe functions for active listeners */
  listenerAll: (state, unsubscribe) => (state.listenerAll = unsubscribe),
  addListenerSingle: (state, { id, unsubscribe }) =>
    Vue.set(state.listenerSingle, id, unsubscribe),
  removeListenerSingle: (state, id) => Vue.delete(state.listenerSingle, id),
}
