import { flatten, mapValues } from 'lodash'

export default {
  params: (state) => {
    const params = { q: state.term }
    if (state.scope) params.s = state.scope
    if (state.collectionId) params.c = state.collectionId
    if (state.contentType) params.t = state.contentType
    if (state.language) params.l = state.language
    return params
  },
  items: (state) => flatten(Object.values(state.result)),
  isValid: (state) => Object.keys(state.params).length > 0,
  hasMore: (state, getters) => state.total > getters.items.length,
  summary: (state) => mapValues(state.result, (v) => v.length),
}
