export default {
  /* list */
  all: {},
  list: [],
  loading: null,

  /* firestore listeners */
  listenerSingle: {},
  listenerAll: null,
}
