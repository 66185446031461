import { moduleLicenseValue } from '@/app/shared/misc/license-modules'

const dynamic = [
  {
    path: '/deployments',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    meta: {
      title: 'deployments.title.navigation',
      icon: 'las la-cloud-upload-alt',
      license: moduleLicenseValue('digital'),
      roles: ['supplier', 'owner', 'manager', 'member'],
    },
    children: [
      {
        path: '/deployments',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-deployments" */ './deployments.vue'
          ),
        name: 'deployments-list',
        meta: {
          title: 'deployments.title.list',
          icon: 'las la-cloud-upload-alt',
          license: moduleLicenseValue('digital'),
          roles: ['supplier', 'owner', 'manager', 'member'],
        },
      },
      {
        path: '/deployments/:deployment',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-deployments" */ './deployment-show/deployment-show.vue'
          ),
        name: 'deployments-show',
        props: true,
        hidden: true,
        meta: {
          title: 'deployments.title.show',
          icon: 'las la-cloud-upload-alt',
          roles: ['supplier'],
          activeMenu: '/deployments',
        },
      },
      {
        path: '/print-data',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-deployments" */ './print-data/print-data.vue'
          ),
        name: 'print-data',
        meta: {
          title: 'deployments.title.printData',
          icon: 'las la-print',
          roles: ['admin'],
        },
      },
    ],
  },
]

const constant = [
  {
    path: '/dpl',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout2" */ '@/app/layout/horizontal.vue'
      ),
    children: [
      {
        path: '/dpl/:deployment',
        name: 'deployments-redirect',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-deployments-redirect" */ './redirect/redirect.vue'
          ),
        hidden: true,
      },
    ],
  },
]

export default { constant, dynamic }
