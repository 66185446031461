import Vue from 'vue'
import updatePartial from 'Shared/store/_partials/mutationUpdatePartial'

export default {
  /* remove all items from store */
  flush: (state) => (state.all = {}),
  /* insert or update item in store */
  update: (state, item) => Vue.set(state.all, item.id, item),
  /* update item partially in store */
  updatePartial,
  /* remove item from store */
  remove: (state, item) => Vue.delete(state.all, item.id),

  loading: (state, val) => (state.loading = !!val),

  /* deletionPending */
  addDeletionPending: (state, id) => Vue.set(state.deletionPending, id, true),
  removeDeletionPending: (state, id) => Vue.delete(state.deletionPending, id),

  /* handle firestore listeners for changes */
  listenerAll: (state, unsubscribe) => (state.listenerAll = unsubscribe),
  addListenerSingle: (state, { id, unsubscribe }) =>
    Vue.set(state.listenerSingle, id, unsubscribe),
  removeListenerSingle: (state, id) => Vue.delete(state.listenerSingle, id),
}
