export default [
  {
    path: '/account',
    component: () =>
      import(
        /* webpackChunkName: "client-chunk-layout" */ '@/app/layout/index.vue'
      ),
    children: [
      {
        path: '/account',
        name: 'account-show',
        component: () =>
          import(
            /* webpackChunkName: "client-chunk-users-account-show" */ './account.vue'
          ),
        hidden: true,
        meta: {
          title: 'users.title.yourAccount',
        },
      },
    ],
  },
]
