const extensionMimeTypes = Object.freeze({
  ai: 'application/postscript',
  bmp: 'image/bmp',
  eps: 'application/postscript',
  gif: 'image/gif',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
  psd: 'image/vnd.adobe.photoshop',
  svg: 'image/svg+xml',
  tif: 'image/tiff',
  webp: 'image/webp',
})

export default Object.keys(extensionMimeTypes)
