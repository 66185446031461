export default {
  all: {},

  search: '',
  source: 'owner',

  sortBy: 'created_at',
  sortDesc: true,

  page: 1,
  perPage: 15,

  params: {},
  result: {
    data: [],
  },

  loading: false,
  cancel: null,

  uploads: [],
  uploadsShow: false,
}
