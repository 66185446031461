// skip the following status while searching for 'published' release
const skipStatus = ['rejected', 'disabled']

/**
 * Check if a new release could be added to given publication
 * @param publication
 * @returns {boolean}
 */
export default (publication) => {
  // publication exists?
  if (!publication) return false
  // publication without release?
  if (!publication.releases || Object.keys(publication.releases).length === 0)
    return true
  // get release number and status, latest release first
  const releases = Object.values(publication.releases)
    .map((release) => [release.number, release.status])
    .sort((a, b) => b[0] - a[0])
  for (let i = 0; i < releases.length; i += 1) {
    const status = releases[i][1]
    // skip rejected and disabled releases
    if (!skipStatus.includes(status)) {
      // allow if last release is published
      return status === 'published'
    }
  }
  // allow if no releases left (e.g. has rejected releases only)
  return true
}
