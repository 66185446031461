const firebaseConfig = {
  apiKey: 'AIzaSyDP88xnuoDq0n_26zswnvec528rwSUm35Y',
  authDomain: 'mediaflex-prd.firebaseapp.com',
  databaseURL: 'https://mediaflex-prd.firebaseio.com',
  projectId: 'mediaflex-prd',
  storageBucket: 'mediaflex-prd.appspot.com',
  messagingSenderId: '854230830885',
  appId: '1:854230830885:web:10c55632ede2e3743214cc',
}

const functionsBase = 'https://europe-west1-mediaflex-prd.cloudfunctions.net'

const api = {
  functionsBase,
  search: 'https://search.mediaflex.app/v2',
  graphQl: `${functionsBase}/graphql`,
  artwork: {
    url: 'https://artwork.mediaflex.app/api',
  },
  picture: {
    url: 'https://picture.mediaflex.app/api',
  },
}

export default {
  firebaseConfig,
  api,
}
