import Vue from 'vue'

const vueSetPath = (state, path, value) => {
  let current = state
  String(path)
    .split('.')
    .forEach((key, index, chain) => {
      if (current instanceof Object) {
        if (index === chain.length - 1) {
          if (value === undefined) {
            Vue.delete(current, key)
          } else {
            Vue.set(current, key, value)
          }
        } else {
          if (
            !Object.prototype.hasOwnProperty.call(current, key) ||
            current[key] === null
          )
            Vue.set(current, key, {})
          current = current[key]
        }
      }
    })
}

export default vueSetPath
