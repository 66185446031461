export const all = Object.freeze({
  print: {
    value: 1,
    icon: 'las la-print',
  },
  digital: {
    value: 2,
    icon: 'las la-desktop',
  },
  article: {
    value: 4,
    icon: 'las la-file',
  },
  pictures: {
    value: 8,
    icon: 'las la-image',
  },
})

export const modules = Object.keys(all)

export const moduleLicenseValue = (mod) => (all[mod] && all[mod].value) || null
