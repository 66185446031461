export default {
  id: null,
  report: null,
  sheet: null,
  mapping: {},
  owner: null,
  type: null,
  importedRows: undefined,
  uploadLoading: false,
  analysisLoading: false,
  importLoading: false,
  dragActive: false,
}
