import { isNil, get } from 'lodash'
import axios from 'axios'
import { arrayUnion } from 'firebase/firestore'
import config from '@/config'
import Database from '../../organizations-db'
import Storage from '../../organizations-storage'

const storage = new Storage()

export default {
  /**
   * Get default organization(s)
   * @param context
   * @returns {Promise<void>}
   */
  getDefaults: async ({ commit, state, rootState }) => {
    // already listening to all documents?
    if (state.listenerAll !== null)
      return Promise.reject(Error('already listening to all organizations'))

    // already loading defaults?
    if (state.loading)
      return Promise.reject(Error('organization defaults loading'))

    // clear vuex store
    commit('flush')

    // check claims
    const { claims } = rootState.authentication
    // claims required, claims.org required if not admin
    if (!claims || (!claims.org && claims.role !== 'admin'))
      return Promise.reject(Error('claims required to load organization(s)'))

    // get db
    const db = new Database(commit)

    if (claims.sup === true || claims.role === 'admin') {
      // get all organizations
      const constraints = []
      if (claims.role !== 'admin' && claims.org !== 'mcs') {
        constraints.push(['supplier', '==', claims.org])
      }
      await db
        .queryAndListen(
          constraints,
          100,
          0,
          [
            ['type', 'desc'],
            ['name', 'asc'],
          ],
          '',
          'list'
        )
        .then((unsubscribe) => commit('listenerAll', unsubscribe))
      return Promise.resolve()
    }

    // get user's organization
    if (state.listenerSingle[claims.org])
      return Promise.reject(
        Error(`already listening to organization ${claims.org}`)
      )
    commit('loading', true)
    await db.readAndListen(claims.org)
    commit('loading', false)
    return Promise.resolve()
  },

  /**
   * create or update item in database
   * @param context
   * @param item
   * @returns {Promise<void>}
   */
  set: async ({ commit }, item) => {
    const db = new Database(commit)
    const id = !isNil(item.id) && item.id ? item.id : null
    if ('id' in item) delete item.id
    return db.create(item, id)
  },

  /**
   * Update item in database
   * @param context
   * @param item
   * @returns {Promise<*>}
   */
  update: async ({ commit }, item) => {
    if (isNil(item.id)) throw Error(`Field item.id is required`)
    const db = new Database(commit)
    return db.update(item)
  },

  /**
   * Delete item from database
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  delete: async ({ commit }, id) => {
    const db = new Database(commit)
    return db.delete(id)
  },

  updateLicense: async ({ dispatch }, { id, license }) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return new Promise((resolve, reject) => {
      axios({
        url: config.api.graphQl,
        method: 'post',
        headers: { authorization: token ? `Bearer ${token}` : '' },
        data: {
          operationName: 'OrgLicUpd',
          variables: { id, license },
          query: `
mutation OrgLicUpd($id: ID!, $license: Int!) {
  organizationLicenseUpdate(id: $id, license: $license)
}
`,
        },
      })
        .then((result) => {
          if (result.data.errors && result.data.errors[0]) {
            reject(result.data.errors[0].message)
          }
          if (get(result, 'data.data.organizationLicenseUpdate', false)) {
            resolve(true)
          } else {
            reject(new Error('Organization License Update FAILED'))
          }
        })
        .catch(reject)
    })
  },
  addNumberRange: async (
    { dispatch },
    { id, prefix, digits = 1, counter = 0 }
  ) => {
    if (!id) throw Error('Parameter id is required')
    if (!prefix) throw Error('Parameter prefix is required')
    const numberRange = { prefix, digits, counter }
    const docUpdate = {
      id,
      numberRanges: arrayUnion(numberRange),
    }
    return dispatch('update', docUpdate)
  },

  getLogo: async ({ getters, dispatch }, id) => {
    const path = getters.logoFullPath(id)
    return dispatch('storageUrls/get', path, { root: true }).catch(() => '')
  },
  setLogo: async ({ getters, commit }, { id, upload }) => {
    if (!id) throw Error('parameter id is required')
    const pathRel = getters.logoPath(id)
    const pathFull = getters.logoFullPath(id)
    const onUpdated = (result) => {
      commit('storageUrls/remove', pathFull, { root: true })
      return result
    }
    if (!upload) {
      return storage.deleteFile(pathRel).then(onUpdated)
    }
    return storage.uploadTask(pathRel, upload).then(onUpdated)
  },
}
