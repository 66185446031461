/**
 * @param list {object[]}
 * @param list.*.id {string}
 * @param selected {string[]}
 * @param lastIsAdded {boolean}
 * @param idToAdd {string}
 * @param callback {function}
 */
const selectRange = (list, selected, lastIsAdded, idToAdd, callback) => {
  if (!idToAdd || !lastIsAdded || !selected.length) return
  const lastAddedId = selected[selected.length - 1]
  if (lastAddedId === idToAdd) return
  const lastAddedIndex = list.findIndex((item) => item.id === lastAddedId)
  if (lastAddedIndex === -1) return
  const addIdIndex = list.findIndex((item) => item.id === idToAdd)
  if (addIdIndex === -1) return
  const start = Math.min(lastAddedIndex, addIdIndex)
  const end = start + Math.abs(addIdIndex - lastAddedIndex) + 1
  list.slice(start, end).forEach((r) => {
    if (!selected.includes(r.id)) {
      callback(r.id)
    }
  })
}

export default selectRange
