import { cloneDeep, isNil } from 'lodash'
import { debug } from 'Shared/utils/log'
import Database from '../../content-types-db'

export default {
  /**
   * Fetch list and listen for changes
   * @returns {Promise<void>}
   */
  getAll: async ({ commit, state, rootGetters }) => {
    // already listening to all documents?
    if (state.listenerAll !== null)
      return Promise.reject(Error('already listening to all content types'))
    // clear vuex store
    commit('flush')
    // get constraints
    const constraints = []
    if (!rootGetters['authentication/isAdmin']) {
      const organizationId = rootGetters['authentication/organizationId']
      if (organizationId) {
        constraints.push([`loadDefault.${organizationId}`, '==', true])
      }
    }
    if (!constraints.length) {
      debug('get ALL contentTypes')
    }
    // set up listener
    const db = new Database(commit)
    await db
      .queryAndListen(constraints, 100)
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
    return Promise.resolve()
  },

  /**
   * Request one from database and listen for changes
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  getOne: async ({ state, getters, commit }, id) => {
    // already listening to all documents?
    if (state.listenerAll !== null) return getters.get(id)
    // already listening to this document?
    if (Object.prototype.hasOwnProperty.call(state.listenerSingle, id))
      return getters.get(id)
    const db = new Database(commit)
    return db.readAndListen(id)
  },

  /**
   * Fetch one and set as editItem
   * @param commit
   * @param state
   * @param id
   * @returns {Promise<void>}
   */
  loadEditItem: async ({ commit, state }, id) => {
    commit('active', id)
    commit('editItem', null)
    const cache = id in state.all
    const db = cache ? null : new Database(commit)
    const item = cache ? state.all[id] : await db.read(id)
    commit('editItem', cloneDeep(item))
  },

  /**
   * Set editItem to empty item template
   * @param commit
   * @returns {Promise<void>}
   */
  resetEditItem: async ({ commit }) => {
    commit('editItem', {})
  },

  /**
   * create or update item in database
   * @param context
   * @param item
   */
  set: async ({ dispatch, state, commit }, item) => {
    const db = new Database(commit)
    const id = !isNil(item.id) && item.id ? item.id : null
    if ('id' in item) delete item.id
    db.create(item, id).then((created) => {
      if (
        'parentId' in created &&
        created.parentId &&
        Object.prototype.hasOwnProperty.call(state.all, created.parentId)
      ) {
        const parentsChildren = state.all[created.parentId].children
        parentsChildren[created.id] = true
        dispatch('update', { id: created.parentId, children: parentsChildren })
      }
    })
  },

  /**
   * Update item in database
   * @param context
   * @param item
   */
  update: async ({ commit }, item) => {
    if (isNil(item.id)) return false
    const db = new Database(commit)
    return db.update(item)
  },

  /**
   * Create a new property and reset property name input
   */
  commitEditItem: ({ dispatch, state, commit }) => {
    if (state.editItem === null) return
    const item = state.editItem
    commit('editItem', null)
    commit('active', null)
    dispatch('set', item)
  },

  /**
   * Delete item from database
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   * @param id
   * @returns {Promise<void>}
   */
  delete: async ({ commit, getters, state, dispatch }, id) => {
    if (getters.isDeletionPending(id)) return
    // save parentId if available
    const parentId =
      Object.prototype.hasOwnProperty.call(state.all, id) &&
      Object.prototype.hasOwnProperty.call(state.all[id], 'parentId')
        ? state.all[id].parentId
        : null
    const db = new Database(commit)
    commit('addDeletionPending', id)
    db.delete(id).then(() => {
      commit('removeDeletionPending', id)
      if (
        parentId &&
        Object.prototype.hasOwnProperty.call(state.all, parentId) &&
        Object.prototype.hasOwnProperty.call(state.all[parentId], 'children') &&
        state.all[parentId].children instanceof Object
      ) {
        // update parent's children list
        const parentChilds = state.all[parentId].children
        if (Object.prototype.hasOwnProperty.call(parentChilds, id)) {
          delete parentChilds[id]
          dispatch('update', { id: parentId, children: parentChilds })
        }
      }
    })
  },
}
